import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { isNil } from 'ramda';

import { asyncLoadingWrapper } from '@utils/api.util';
import { useManagerMeStore, useAuthStore, pickState } from '@stores';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';

interface UseLogOutOptions {
  api: () => Promise<void>;
  navigateTo?: string;
  onError?: (error: unknown) => void;
}

const useLogOut = (options: UseLogOutOptions) => {
  const { api, navigateTo, onError } = options;
  const navigate = useEnhancedNavigate();
  const [loading, setLoading] = useState(false);
  const managerMeOrgsStore = useManagerMeStore(
    useShallow((state) => pickState(['clear'], state)),
  );

  const authStore = useAuthStore(
    useShallow((state) => pickState(['reset'], state)),
  );

  const trigger = async () => {
    await asyncLoadingWrapper(
      setLoading,
      async () => {
        await api();
        managerMeOrgsStore.clear();
        if (!isNil(navigateTo)) navigate(navigateTo, { replace: true });
        authStore.reset();
      },
      onError,
    );
  };

  return { loading, trigger };
};

export default useLogOut;
