import { useEffect, DependencyList } from 'react';

import useIsFirstRendered from './useIsFirstRendered';

type Destructor = () => void;

export type BeforeFirstRenderedCallback = () => void | Destructor;

const useBeforeFirstRendered = (
  beforeFirstRendered: BeforeFirstRenderedCallback,
  deps: DependencyList,
) => {
  const isFirstRendered = useIsFirstRendered();

  useEffect(() => {
    const currentIsFirstRendered = isFirstRendered.current;
    if (!currentIsFirstRendered) return;
    const destructor = beforeFirstRendered();
    return () => {
      if (!currentIsFirstRendered) return;
      'function' === typeof destructor && destructor();
    };
  }, deps);
};

export default useBeforeFirstRendered;
