import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Typography, Input, Button, Alert } from 'antd';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { omit } from 'ramda';
import {
  Options,
  defaultOptions,
  passwordStrength,
} from 'check-password-strength';

import { TEMPLATES_RESET_PASSWORD } from '@i18n/namespaces';
import { StrengthLevel } from '@enums/password.enum';
import CenterLayout from '@layouts/CenterLayout';
import StrengthPassword from '@components/StrengthPassword';
import FluidSpace from '@components/FluidSpace';
import LocaleButton from '@components/LocaleButton';
import { FormValues, ResetPasswordProps } from './interfaces';
import { Section, Header, SubTitle, InfoList } from './styleds';

const { Item: FormItem, useForm } = Form;
const { Text } = Typography;
const { Password } = Input;

const ValidPasswordLevel = [StrengthLevel.MEDIUM, StrengthLevel.STRONG];

const PasswordStrengthOptions: Options<string> = [
  defaultOptions[0],
  defaultOptions[1],
  {
    ...defaultOptions[2],
    minLength: 12,
  },
  { ...defaultOptions[3], minLength: 16 },
];

const ResetPassword = (props: ResetPasswordProps) => {
  const { loading = false, onReset } = props;
  const { t } = useTranslation(TEMPLATES_RESET_PASSWORD);
  const [form] = useForm<FormValues>();
  const [strengthLevel, setStrengthLevel] = useState(0);
  const [showPwdLengthInfo, setShowPwdLengthInfo] = useState(true);
  const [showPwdDiversityInfo, setShowPwdDiversityInfo] = useState(true);
  const showInfoAlert = showPwdLengthInfo || showPwdDiversityInfo;

  const passwordStrengthValidator: ValidatorRule['validator'] = (
    rule,
    password: string,
  ) => {
    const {
      id: strengthLevel,
      contains,
      length,
    } = passwordStrength(password, PasswordStrengthOptions);
    setStrengthLevel(strengthLevel);
    setShowPwdLengthInfo(length < 12);
    setShowPwdDiversityInfo(contains.length < 4);
    if (ValidPasswordLevel.includes(strengthLevel)) return Promise.resolve();
    return Promise.reject('');
  };

  const confirmPasswordValidator: ValidatorRule['validator'] = (
    rule,
    value: string,
  ) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(t('components.templates.reset-password:match-password')),
    );
  };

  const handleOnFinish = (value: FormValues) => {
    onReset?.(omit(['checked'], value));
  };

  return (
    <CenterLayout>
      <Spin spinning={loading}>
        <Section>
          <Header level={4}>
            {t('components.templates.reset-password:title')}
          </Header>
          <SubTitle type="secondary">
            {t('components.templates.reset-password:subtitle')}
          </SubTitle>
          <Form form={form} onFinish={handleOnFinish}>
            <FormItem
              name="password"
              validateFirst
              rules={[
                {
                  required: true,
                  message: t(
                    'components.templates.reset-password:required-password',
                  ),
                },
                {
                  validator: passwordStrengthValidator,
                },
              ]}
            >
              <StrengthPassword
                size="large"
                prefix={<LockOutlined />}
                placeholder={t(
                  'components.templates.reset-password:new-password',
                )}
                level={strengthLevel}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </FormItem>
            <FormItem
              name="checked"
              validateFirst
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t(
                    'components.templates.reset-password:required-confirm-password',
                  ),
                },
                { validator: confirmPasswordValidator },
              ]}
            >
              <Password
                size="large"
                prefix={<LockOutlined />}
                placeholder={t(
                  'components.templates.reset-password:confirm-password',
                )}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </FormItem>
            <FormItem noStyle>
              <FluidSpace direction="vertical" size="large">
                {showInfoAlert && (
                  <Alert
                    type="info"
                    message={
                      <Text>
                        <InfoList>
                          {showPwdLengthInfo && (
                            <li>
                              {t(
                                'components.templates.reset-password:password-length-info',
                              )}
                            </li>
                          )}
                          {showPwdDiversityInfo && (
                            <li>
                              {t(
                                'components.templates.reset-password:password-diversity-info',
                              )}
                            </li>
                          )}
                        </InfoList>
                      </Text>
                    }
                  />
                )}
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  block
                  icon={<FormOutlined />}
                >
                  {t('components.templates.reset-password:reset-button')}
                </Button>
              </FluidSpace>
            </FormItem>
          </Form>
        </Section>
      </Spin>
      <LocaleButton float />
    </CenterLayout>
  );
};

export default ResetPassword;
export type { ResetPasswordProps };
