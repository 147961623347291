import { ReactNode } from 'react';
import { notification } from 'antd';
import { curry } from 'ramda';

import { isNexusAxiosError } from '@utils/nexus-axios';

const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const successNotify = curry((key: string, message?: ReactNode) => {
    api.success({
      key,
      placement: 'bottomLeft',
      message,
    });
  });

  const warningNotify = curry((key: string, message?: ReactNode) => {
    api.warning({
      key,
      placement: 'bottomLeft',
      message,
    });
  });

  const errorNotify = curry((key: string, error?: unknown) => {
    if (typeof error === 'object' && isNexusAxiosError(error)) {
      api.error({
        key,
        placement: 'bottomLeft',
        message: error.response?.data.error || 'UNKNOWN_API_ERROR',
      });
    } else {
      if (error instanceof Error) {
        api.error({
          key,
          placement: 'bottomLeft',
          message: (error as Error).message,
        });
      } else if (typeof error === 'string') {
        api.error({
          key,
          placement: 'bottomLeft',
          message: error,
        });
      }
    }
  });

  return [successNotify, warningNotify, errorNotify, contextHolder] as const;
};

export default useNotification;
