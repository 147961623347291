import { createContext, ReactNode, useContext } from 'react';

import useNotification from '@hooks/useNotification';

interface GlobalNotificationProviderProps {
  children?: ReactNode;
}

type ContextValue = {
  successNotify: ReturnType<typeof useNotification>[0];
  warningNotify: ReturnType<typeof useNotification>[1];
  errorNotify: ReturnType<typeof useNotification>[2];
};

const NotificationContext = createContext<ContextValue | undefined>(undefined);

export const useGlobalNotification = () =>
  useContext(NotificationContext) as ContextValue;

const GlobalNotificationProvider = (
  props: GlobalNotificationProviderProps = {},
) => {
  const { children } = props;
  const [successNotify, warningNotify, errorNotify, contextHolder] =
    useNotification();

  return (
    <NotificationContext.Provider
      value={{ successNotify, warningNotify, errorNotify }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export default GlobalNotificationProvider;
