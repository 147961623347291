export enum OrgAcctRoleType {
  GLOBAL = 'GLOBAL',
  CUSTOMIZED = 'CUSTOMIZED',
}

export enum SearchScope {
  ALL = 'all',
  ORGANIZATION = 'org',
  CLUSTER = 'clstr',
}
