import { useState } from 'react';
import z from 'zod';

import useUrlQuery from '@hooks/useUrlQuery';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 10;
const DEFAULT_TOTAL = 0;

const schema = z.object({
  page: z.coerce.number().positive().optional(),
  size: z.coerce.number().positive().optional(),
});

export interface UsePaginationOptions {
  defaultPage?: number;
  defaultSize?: number;
  defaultTotal?: number;
  ignoreUrlQuery?: boolean;
}

const usePagination = (options: UsePaginationOptions = {}) => {
  const {
    defaultPage = DEFAULT_PAGE,
    defaultSize = DEFAULT_SIZE,
    defaultTotal = DEFAULT_TOTAL,
    ignoreUrlQuery = false,
  } = options;
  const urlQuery = useUrlQuery(schema);
  const [page, setPage] = useState(
    !ignoreUrlQuery ? urlQuery.get().page || defaultPage : defaultPage,
  );
  const [size, setSize] = useState(
    !ignoreUrlQuery ? urlQuery.get().size || defaultSize : defaultSize,
  );
  const [total, setTotal] = useState(defaultTotal);

  const setUrlQuery = (query: {
    page: number | undefined;
    size: number | undefined;
  }) => {
    if (!ignoreUrlQuery) urlQuery.set(query);
  };

  const changePage = (page: number) => {
    setPage(page);
    setUrlQuery({ page, size });
  };

  const changeSize = (size: number) => {
    setSize(size);
    setUrlQuery({ page, size });
  };

  const changePageAndSize = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    setUrlQuery({ page, size });
  };

  const changeTotal = (total: number) => {
    setTotal(total);
    const lastPage = total === 0 ? 1 : Math.ceil(total / size);
    if (page > lastPage) changePage(lastPage);
  };

  const reset = () => {
    changePageAndSize(defaultPage, defaultSize);
    setTotal(defaultTotal);
    return { page: defaultPage, size: defaultSize };
  };

  return {
    page,
    size,
    total,
    offset: (page - 1) * size,
    changePage,
    changeSize,
    changePageAndSize,
    changeTotal,
    reset,
  };
};

export default usePagination;
