import styled from 'styled-components';
import { Button, theme } from 'antd';

export const ContainerButton = styled(Button).attrs({ type: 'text' })`
  color: ${theme.getDesignToken().colorBgMask};
  border: none;
  padding: 0;
  width: 24px !important;
  height: 24px;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.getDesignToken().colorText};
  }

  &:hover {
    background-color: transparent !important;
  }
`;
