import { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Space, Button, Table, Spin } from 'antd';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';

import {
  SUPER_ADMIN_FETCH_MANY_GLOBAL_ACCT_ROLE_ERROR,
  SUPER_ADMIN_PUSH_GLOBAL_ACCT_ROLE_SUCCESS,
  SUPER_ADMIN_PUSH_GLOBAL_ACCT_ROLE_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ReloadButton from '@components/ReloadButton';
import PushDrawer from './components/PushDrawer';
import useManyGlobalAcctRole from './hooks/useManyGlobalAcctRole';
import usePushDrawer from './hooks/usePushDrawer';
import { createMainTableColumns, createSubTableColumns } from './utils';
import { MainTableRecordType } from './interfaces';
import { Container } from './styleds';

const SuperAdminGlobalAccountRole = () => {
  const navigate = useEnhancedNavigate();
  const { errorNotify, successNotify } = useGlobalNotification();
  const manyGlobalAcctRole = useManyGlobalAcctRole({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANY_GLOBAL_ACCT_ROLE_ERROR),
  });
  const pushDrawer = usePushDrawer({
    onSuccess: successNotify(SUPER_ADMIN_PUSH_GLOBAL_ACCT_ROLE_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_PUSH_GLOBAL_ACCT_ROLE_ERROR),
  });

  const handleAddClick = () => {
    navigate('editor');
  };

  const handleEditClick = (globalAcctRoleId: string) => {
    navigate(`editor/${globalAcctRoleId}`);
  };

  const handlePushClick = (globalAcctRoleId: string, name: string) => {
    pushDrawer.open(globalAcctRoleId, name);
  };

  const mainTableColumns = useMemo(
    () =>
      createMainTableColumns({
        onEditClick: handleEditClick,
        onPushClick: handlePushClick,
      }),
    [],
  );

  const subTableColumns = useMemo(() => createSubTableColumns(), []);

  const expandedRowRender = useCallback((record: MainTableRecordType) => {
    return (
      <Table
        rowKey="id"
        columns={subTableColumns}
        dataSource={record.permissions}
        pagination={false}
      />
    );
  }, []);

  useEffect(() => {
    manyGlobalAcctRole.fetch();
  }, []);

  return (
    <Spin spinning={manyGlobalAcctRole.loading}>
      <Container direction="vertical" size="large">
        <Row justify="space-between">
          <Col>
            <Space>
              <Button icon={<FilterOutlined />} />
              <ReloadButton onClick={manyGlobalAcctRole.reload} />
            </Space>
          </Col>
          <Col>
            <Space>
              <Button icon={<PlusOutlined />} onClick={handleAddClick}>
                Add Role
              </Button>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey="id"
          scroll={{ x: 'max-content' }}
          dataSource={manyGlobalAcctRole.data}
          columns={mainTableColumns}
          expandable={{ expandedRowRender }}
          pagination={{
            current: manyGlobalAcctRole.pagination.page,
            pageSize: manyGlobalAcctRole.pagination.size,
            total: manyGlobalAcctRole.pagination.total,
            onChange: manyGlobalAcctRole.changePage,
          }}
        />
        <PushDrawer
          open={pushDrawer.visible}
          loading={pushDrawer.loading}
          title={pushDrawer.title}
          options={pushDrawer.data}
          onClose={pushDrawer.close}
          onSave={pushDrawer.save}
        />
      </Container>
    </Spin>
  );
};

export default SuperAdminGlobalAccountRole;
