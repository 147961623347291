import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';
import { isNil, isNotNil } from 'ramda';

import { NexusRouteObject } from '@interfaces/routes.interface';
import { useAuthStore, useMenuStore } from '@stores';
import { isMenuItem, excludeNoPathAndFlatRoute } from '@utils/router.util';
import { isNotEmpty } from '@utils/ramda.util';
import useBeforeFirstRendered from '@hooks/useBeforeFirstRendered';
import { MenuItemMetaMap, MenuItemMeta } from './interface';
import {
  convertItems,
  getMenuItemRegexFromRoutePath,
  getMenuItemMeta,
  Excluder,
} from './utils';

type MenuInfo = Parameters<Required<MenuProps>['onClick']>[0];

export type { Excluder };

export interface UseMenuOptions {
  prefix: string;
  routes: NexusRouteObject[];
  excluder?: Excluder;
  onPageChange?: (options: { to?: string; authority?: string }) => void;
}

const useMenu = (options: UseMenuOptions) => {
  const { prefix, routes, onPageChange, excluder } = options;
  const location = useLocation();
  const { i18n } = useTranslation();
  const isMenuClicked = useRef(false);
  const flatRoutes = useMemo(() => excludeNoPathAndFlatRoute(routes), [routes]);
  const menuItems = useMemo(
    () => convertItems(routes, prefix, excluder),
    [routes, excluder],
  );

  const menuItemMetaMap = useMemo(
    () =>
      flatRoutes.reduce((result, route) => {
        if (isMenuItem(route) && !isNil(route.path)) {
          result.set(
            { regex: getMenuItemRegexFromRoutePath(route.path, prefix) },
            {
              key:
                route.excludeMenuItem && !isNil(route.parent)
                  ? route.parent[0]
                  : route.path,
              path: route.path,
              label: route.label,
              parent: route.parent,
              authority: route.authority,
              fallback:
                route.fallback === true ? route.parent?.[0] : route.fallback,
            },
          );
        }
        return result;
      }, new Map() as MenuItemMetaMap),
    [flatRoutes, prefix],
  );

  const defaultMeta = useMemo(() => {
    const meta = getMenuItemMeta(location.pathname, menuItemMetaMap);
    return {
      defaultSelectedKeys: meta?.key ? [meta?.key] : [],
      defaultOpenKeys: meta?.parent || [],
      label: meta?.label,
      authority: meta?.authority,
      fallback: meta?.fallback,
    };
  }, []);

  const {
    authority,
    fallback,
    init: initAuthStore,
    set: setAuthStore,
  } = useAuthStore();
  initAuthStore({
    authority: defaultMeta.authority,
    fallback: defaultMeta.fallback,
  });

  const { label, init: initMenuStore, set: setMenuStore } = useMenuStore();
  initMenuStore({
    label: defaultMeta.label || '',
    selectedKeys: defaultMeta.defaultSelectedKeys,
    openKeys: defaultMeta.defaultOpenKeys,
  });

  const updatePropertyByMeta = (
    meta: MenuItemMeta | undefined | null,
    info?: MenuInfo,
  ) => {
    if (isNotNil(meta)) {
      const { label, authority, fallback } = meta;
      setAuthStore({ authority, fallback });
      setMenuStore({ label });
    }

    const selectedKeys = isNotNil(meta)
      ? [meta.key]
      : isNotNil(info)
      ? [info.key]
      : [];
    const openKeys =
      isNotNil(meta) && isNotNil(meta.parent) && isNotEmpty(meta.parent)
        ? meta.parent[0] === selectedKeys[0]
          ? meta.parent.slice(1)
          : meta.parent
        : isNotNil(info)
        ? info.keyPath.slice(1)
        : [];
    setMenuStore({ selectedKeys, openKeys });
  };

  const onMenuItemClick: MenuProps['onClick'] = (info) => {
    isMenuClicked.current = true;
    const { key } = info;
    const meta = getMenuItemMeta(`/${prefix}/${key}`, menuItemMetaMap);
    updatePropertyByMeta(meta, info);
    onPageChange?.({ to: meta?.path || key, authority: meta?.authority });
    return meta;
  };

  useEffect(() => {
    const meta = getMenuItemMeta(location.pathname, menuItemMetaMap);
    if (isNil(meta)) return;
    const { label } = meta;
    setMenuStore({ label });
  }, [i18n.language]);

  useBeforeFirstRendered(() => {
    if (isMenuClicked.current) {
      isMenuClicked.current = false;
    } else {
      const meta = getMenuItemMeta(location.pathname, menuItemMetaMap);
      updatePropertyByMeta(meta);
      onPageChange?.({ authority: meta?.authority });
    }
  }, [location.pathname]);

  return {
    menuItems,
    label,
    authority,
    fallback,
    defaultSelectedKeys: defaultMeta.defaultSelectedKeys,
    defaultOpenKeys: defaultMeta.defaultOpenKeys,
    onMenuItemClick,
  };
};

export default useMenu;
