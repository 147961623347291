import { useTranslation } from 'react-i18next';
import { Badge } from 'antd';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import { and, isNotNil, gt } from 'ramda';

import { COMMON } from '@i18n/namespaces';
import TooltipButton from '../TooltipButton';
import { FilterButtonProps } from './interfaces';

const FilterButton = (props: FilterButtonProps = {}) => {
  const { t } = useTranslation(COMMON);
  const {
    title = t('common:filter', 'Filter'),
    count = 0,
    onClick,
    onReset,
  } = props;

  return and(gt(count, 0), isNotNil(onReset)) ? (
    <TooltipButton
      title={title}
      wrapper={<Badge count={count} />}
      icon={<FilterOutlined />}
      onClick={onClick}
      dropdown={{
        arrow: true,
        menu: {
          items: [
            {
              key: 'reset',
              icon: <RedoOutlined />,
              label: t('common:reset', 'Reset'),
              onClick: onReset,
            },
          ],
        },
      }}
    />
  ) : (
    <TooltipButton
      title={title}
      wrapper={<Badge count={count} />}
      icon={<FilterOutlined />}
      onClick={onClick}
    />
  );
};

export default FilterButton;
