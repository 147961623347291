import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNil } from 'ramda';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import { postSuperAdminResetPassword } from '@apis/auth';

interface UseResetPasswordOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useResetPassword = (options: UseResetPasswordOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const { t } = useTranslation(COMMON);
  const { key } = useParams<{ key?: string }>();
  const navigate = useEnhancedNavigate();
  const loading = useLoading();

  const submit = async (password: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        if (isNil(key)) throw new Error('RESET KEY NOT FOUND');
        await postSuperAdminResetPassword(key, password);
        onSuccess?.(t('common:success-message', { action: t('common:reset') }));
        navigate('/super-admins/organizations', { replace: true });
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useResetPassword;
