import { create } from 'zustand';

export interface AuthState {
  isInit: boolean;
  authority?: string;
  fallback?: string;
}

export interface AuthAction {
  init(state: Partial<Omit<AuthState, 'isInit'>>): void;
  set(state: Partial<Omit<AuthState, 'isInit'>>): void;
  reset(): void;
}

const DEFAULT_STATE = {
  authority: undefined,
  fallback: undefined,
};

export const useAuthStore = create<AuthState & AuthAction>((set, get) => ({
  isInit: false,
  ...DEFAULT_STATE,
  init(state) {
    if (get().isInit) return;
    set({ ...state, isInit: true });
  },
  set(state) {
    set((prev) => ({ ...prev, ...state }));
  },
  reset() {
    set((prev) => ({ ...prev, ...DEFAULT_STATE }));
  },
}));
