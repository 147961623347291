import { useState } from 'react';

import { WithValueAndLabel } from '@components/DrawerCheckbox';
import { GlobalAccountRoles } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import { getAllGlobalAccountRole } from '@apis/global-account-roles';

interface UseAccountRolesOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useAccountRoles = (props: UseAccountRolesOptions = {}) => {
  const { onError, throwable } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    WithValueAndLabel<GlobalAccountRoles.GlobalAccountRole>[]
  >([]);

  const fetch = async () => {
    await asyncLoadingWrapper(
      setLoading,
      async () => {
        const [data] = await getAllGlobalAccountRole();
        const withValueData = data.map((accountRole) => ({
          ...accountRole,
          value: accountRole.id,
          label: accountRole.name,
        }));
        setData(withValueData);
        return withValueData;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading, fetch, data };
};

export default useAccountRoles;
