import { useEffect, useCallback } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  List,
  Spin,
} from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { equals, isEmpty, isNil, isNotNil, not } from 'ramda';

import {
  SUPER_ADMIN_SEARCH_MANY_ORGANIZATION_ERROR,
  SUPER_ADMIN_FETCH_ONE_ORGANIZATION_CLUSTER_ERROR,
  SUPER_ADMIN_ADD_ORGANIZATION_CLUSTER_SUCCESS,
  SUPER_ADMIN_ADD_ORGANIZATION_CLUSTER_ERROR,
  SUPER_ADMIN_EDIT_ORGANIZATION_CLUSTER_SUCCESS,
  SUPER_ADMIN_EDIT_ORGANIZATION_CLUSTER_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import { formValidateFields } from '@utils/antd-form.util';
import useRouteParams from '@hooks/useRouteParams';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ContainerSkeleton from '@components/ContainerSkeleton';
import FluidSpace from '@components/FluidSpace';
import EditorHeader from '@components/EditorHeader';
import InputList from '@components/InputList';
import AffixFormFooter from '@components/AffixFormFooter';
import Link from '@components/Link';
import { RouteParamsSchema } from './schemas';
import { ProfileFormValues, MembersFormValues } from './interfaces';
import useSearchManyOrg, { SearchData } from './hooks/useSearchManyOrg';
import useFindOne from './hooks/useFindOne';
import useCreateOne from './hooks/useCreateOne';
import useUpdateOne from './hooks/useUpdateOne';

const { useForm, Item: FormItem } = Form;
const { Text } = Typography;
const { Item: ListItem } = List;
const { Meta: ListItemMeta } = ListItem;

const SuperAdminManagerClusterEditor = () => {
  const routeParams = useRouteParams(RouteParamsSchema);
  const navigate = useEnhancedNavigate();
  const { successNotify, errorNotify } = useGlobalNotification();
  const [profileForm] = useForm<ProfileFormValues>();
  const [membersForm] = useForm<MembersFormValues>();
  const clusterId = routeParams.get().clusterId;
  const searchOrganizations = useSearchManyOrg({
    onError: errorNotify(SUPER_ADMIN_SEARCH_MANY_ORGANIZATION_ERROR),
  });
  const findOne = useFindOne({
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_ORGANIZATION_CLUSTER_ERROR),
  });
  const createOne = useCreateOne({
    onSuccess: successNotify(SUPER_ADMIN_ADD_ORGANIZATION_CLUSTER_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_ADD_ORGANIZATION_CLUSTER_ERROR),
  });
  const updateOne = useUpdateOne({
    onSuccess: successNotify(SUPER_ADMIN_EDIT_ORGANIZATION_CLUSTER_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_EDIT_ORGANIZATION_CLUSTER_ERROR),
  });

  const handleGoBackClick = () => {
    navigate(-1, '/organizations/clusters');
  };

  const handleOnSearch = async (keyword: string, exclude?: SearchData[]) => {
    if (keyword.length < 1) {
      searchOrganizations.clear();
      return;
    }
    await searchOrganizations.fetch(
      keyword,
      exclude?.map((item) => item.id),
    );
  };

  const handleOnSubmit = async () => {
    const [[profileData, profileError], [membersData, membersError]] =
      await Promise.all([
        formValidateFields(profileForm),
        formValidateFields(membersForm),
      ]);
    if (isNotNil(profileError) || isNotNil(membersError)) return;
    if (isNil(profileData)) return;

    if (isNotNil(clusterId)) {
      const profile =
        profileForm.isFieldsTouched() &&
        not(
          equals(profileData, {
            name: findOne.data?.name,
            description: findOne.data?.description,
          }),
        )
          ? profileData
          : undefined;

      const members =
        membersForm.isFieldsTouched() &&
        isNotNil(membersData?.members) &&
        (not(isEmpty(membersData.members.add)) ||
          not(isEmpty(membersData.members.remove)))
          ? {
              addMembers: membersData.members.add.map((item) => item.value),
              removeMembers: membersData.members.remove.map(
                (item) => item.value,
              ),
            }
          : undefined;

      const result = await updateOne.submit(clusterId, {
        profile,
        members,
      });
      if (!result) return;

      if (isNotNil(profile) || isNotNil(members)) {
        await findOne.fetch(clusterId);
        if (isNotNil(profile)) profileForm.setFieldsValue(profile);
        if (isNotNil(members)) membersForm.resetFields();
      }
    } else {
      const id = await createOne.submit({
        name: profileData.name,
        description: profileData.description || null,
        members: isNotNil(membersData?.members)
          ? membersData.members.add.map((item) => item.value)
          : null,
      });
      if (typeof id === 'string') {
        profileForm.resetFields();
        membersForm.resetFields();
        navigate(`${id}`, { replace: true });
      }
    }
  };

  useEffect(() => {
    if (isNotNil(clusterId)) {
      findOne.fetch(clusterId);
    }
  }, [clusterId]);

  const memberOptionRender = useCallback(
    (option: SearchData) => (
      <Space direction="vertical" size={0}>
        <Text strong>{option.name}</Text>
      </Space>
    ),
    [],
  );

  const memberListItemRender = useCallback(
    (option: SearchData) => (
      <>
        <ListItemMeta
          title={
            <Space>
              <Link
                navigate={{
                  to: `../organizations/editor/${option.id}`,
                }}
              >
                <SelectOutlined />
              </Link>
              <Text>{option.name}</Text>
            </Space>
          }
        />
      </>
    ),
    [],
  );

  return (
    <Spin spinning={createOne.loading || updateOne.loading}>
      <FluidSpace direction="vertical" size="large">
        <EditorHeader
          title={`${isNotNil(clusterId) ? 'Edit' : 'Add'} Organization Cluster`}
          subTitle={isNotNil(clusterId) ? `ID: ${clusterId}` : undefined}
          onGoBack={handleGoBackClick}
        />
        <ContainerSkeleton loading={findOne.loading}>
          <Row gutter={[24, 24]}>
            <Col span={24} lg={12} xxl={8}>
              <Form<ProfileFormValues>
                form={profileForm}
                layout="vertical"
                initialValues={
                  isNotNil(findOne.data)
                    ? {
                        name: findOne.data?.name,
                        description: findOne.data?.description,
                      }
                    : undefined
                }
              >
                <FormItem label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </FormItem>
                <FormItem label="Description" name="description">
                  <Input.TextArea />
                </FormItem>
              </Form>
            </Col>
            <Col span={24} lg={12} xxl={8}>
              <Form<MembersFormValues> form={membersForm} layout="vertical">
                <FormItem label="Members" name="members">
                  <InputList
                    buttonText="Add Member"
                    tagKey="name"
                    loading={searchOrganizations.loading}
                    dataSource={findOne.data?.members ?? []}
                    options={searchOrganizations.data}
                    onSearch={handleOnSearch}
                    optionRender={memberOptionRender}
                    itemRender={memberListItemRender}
                  />
                </FormItem>
              </Form>
            </Col>
          </Row>
          <AffixFormFooter>
            <FormItem noStyle>
              <Space>
                <Button type="primary" onClick={handleOnSubmit}>
                  Submit
                </Button>
                <Button onClick={handleGoBackClick}>Cancel</Button>
              </Space>
            </FormItem>
          </AffixFormFooter>
        </ContainerSkeleton>
      </FluidSpace>
    </Spin>
  );
};

export default SuperAdminManagerClusterEditor;
