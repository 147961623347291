import { SUPER_ADMIN_SEND_RESET_PASSWORD_ERROR } from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import ForgetPassword from '@templates/ForgetPassword';
import useForgetPassword from './hooks/useForgetPassword';

const SuperAdminForgetPassword = () => {
  const { errorNotify } = useGlobalNotification();
  const forgetPassword = useForgetPassword({
    onError: errorNotify(SUPER_ADMIN_SEND_RESET_PASSWORD_ERROR),
  });

  return (
    <ForgetPassword
      loading={forgetPassword.loading}
      onSend={({ username }) => forgetPassword.submit(username)}
    />
  );
};

export default SuperAdminForgetPassword;
