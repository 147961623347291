import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { updateOneStatus_SuperAdmin } from '@apis/managers';
import { Managers } from '@interfaces/apis';

interface UseUpdateOneStatusOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useUpdateOneStatus = (options: UseUpdateOneStatusOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const loading = useLoading();
  const { t } = useTranslation(COMMON);

  const submit = async (
    id: string,
    data: Managers.UpdateOneStatusBody_SuperAdmin,
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        await updateOneStatus_SuperAdmin(id, data);
        onSuccess?.(
          t('common:success-message', 'Successfully updated.', {
            action: t('common:updated'),
          }),
        );
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useUpdateOneStatus;
