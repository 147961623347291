import {
  useMemo,
  useState,
  useReducer,
  useRef,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Drawer, Form, Button, Space, GetRef } from 'antd';
import { isNil, equals, isNotNil } from 'ramda';
import { TypeOf as ZodTypeOf, AnyZodObject } from 'zod';
import {
  FilterOutlined,
  SearchOutlined,
  RedoOutlined,
} from '@ant-design/icons';

import { COMMON } from '@i18n/namespaces';
import { setObjDeepNil, RecursivePartial } from '@utils/ramda.util';
import useLifecycle from '@hooks/useLifecycle';
import useUrlQuery from '@hooks/useUrlQuery';
import CloseButton from '@components/CloseButton';
import { pickDeepNotNil } from './utils';
import { DrawerFilterProps, DrawerFilterRef } from './interfaces';

type ButtonRef = GetRef<typeof Button>;

const { useBreakpoint } = Grid;
const { useForm, Item: FormItem } = Form;

const DrawerFilter = forwardRef(
  <
    FilterSchema extends AnyZodObject,
    UrlQuerySchema extends AnyZodObject = FilterSchema,
  >(
    props: DrawerFilterProps<FilterSchema, UrlQuerySchema>,
    ref: ForwardedRef<DrawerFilterRef<FilterSchema> | undefined>,
  ) => {
    const {
      children,
      form: extForm,
      value = {},
      schema,
      urlQuerySchema,
      transformFromUrlQuery,
      transformToUrlQuery,
      open,
      width = 440,
      breakpoint = 'md',
      searchOnMounted = true,
      onClose,
      onSearch,
      onReset,
    } = props;
    const { t } = useTranslation(COMMON);
    const isBreak = !useBreakpoint()[breakpoint];
    const urlQuery = useUrlQuery(urlQuerySchema ?? schema);
    const [form] = useForm<ZodTypeOf<FilterSchema>>(extForm);
    const [formValue, setFormValue] = useState(value);
    const [isForceUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
    const submitEl = useRef<ButtonRef>(null);
    const resetEl = useRef<ButtonRef>(null);

    const getUrlQuery = () =>
      isNil(transformFromUrlQuery)
        ? urlQuery.get()
        : transformFromUrlQuery(urlQuery.get());

    const setUrlQuery = (filter: ZodTypeOf<FilterSchema>) => {
      isNil(transformToUrlQuery)
        ? urlQuery.set(filter)
        : urlQuery.set(transformToUrlQuery(filter));
    };

    const storeFilter = (filter: ZodTypeOf<FilterSchema>) => {
      const formValue = schema.parse(pickDeepNotNil(filter));
      setFormValue(formValue);
      setUrlQuery(filter);
      return formValue;
    };

    const handleOnSearch = (filter: ZodTypeOf<FilterSchema>) => {
      const formValue = storeFilter(filter);
      onClose?.();
      onSearch?.(formValue);
    };

    const handleOnReset = (resetValue?: ZodTypeOf<FilterSchema>) => {
      const filter = resetValue || form.getFieldsValue();
      const formValue = storeFilter(filter);
      onClose?.();
      onReset?.(formValue);
    };

    const initialValues = useMemo(() => getUrlQuery(), [isForceUpdate]);

    useLifecycle(
      {
        onMounted() {
          if (searchOnMounted) {
            setFormValue(initialValues);
            onSearch?.(initialValues, true);
          }
        },
        onBeforeMounted() {
          if (equals(value, formValue)) return;
          form.setFieldsValue(
            value as RecursivePartial<ZodTypeOf<FilterSchema>>,
          );
          const nextFormValue = storeFilter(value);
          onSearch?.(nextFormValue);
        },
      },
      [value],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          form,
          submit: () => {
            submitEl.current?.click();
          },
          reset: () => {
            if (isNotNil(resetEl.current)) {
              resetEl.current?.click();
            } else {
              handleOnReset(setObjDeepNil(initialValues));
              forceUpdate();
            }
          },
        };
      },
      [],
    );

    return (
      <Drawer
        width={isBreak ? '100%' : width}
        open={open}
        closable={false}
        keyboard={false}
        onClose={onClose}
        title={
          <Space size="middle">
            <FilterOutlined />
            {t('common:filter', 'Filter')}
          </Space>
        }
        extra={<CloseButton onClick={onClose} />}
        footer={
          <Form<ZodTypeOf<FilterSchema>>
            form={form}
            onFinish={handleOnSearch}
            onReset={() => handleOnReset()}
          >
            <FormItem noStyle>
              <Space>
                <Button
                  ref={submitEl}
                  type="primary"
                  icon={<SearchOutlined />}
                  htmlType="submit"
                >
                  {t('common:search', 'Search')}
                </Button>
                <Button
                  ref={resetEl}
                  type="text"
                  icon={<RedoOutlined />}
                  htmlType="reset"
                >
                  {t('common:reset', 'Reset')}
                </Button>
              </Space>
            </FormItem>
          </Form>
        }
      >
        <Form<ZodTypeOf<FilterSchema>>
          form={form}
          layout="vertical"
          initialValues={initialValues}
        >
          {children}
        </Form>
      </Drawer>
    );
  },
);

export default DrawerFilter;
export type { DrawerFilterProps, DrawerFilterRef };
