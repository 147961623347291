export enum StrengthLevel {
  TOO_WEAK = 0,
  WEAK = 1,
  MEDIUM = 2,
  STRONG = 3,
}

export enum Diversity {
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
  SYMBOL = 'symbol',
  NUMBER = 'number',
}
