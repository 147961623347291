import { ReactNode, useRef, useState } from 'react';
import { isNil } from 'ramda';

import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { pushManyOrgGlobalAcctRole } from '@apis/global-account-roles';
import useOneGlobalAcctRole from './useOneGlobalAcctRole';

interface UsePushDrawerOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const usePushDrawer = (options: UsePushDrawerOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const loading = useLoading();
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState<string>();
  const oneGlobalAcctRole = useOneGlobalAcctRole();
  const globalAcctRoleId = useRef<string>();

  const open = async (id: string, title: string) => {
    setVisible(true);
    setTitle(title);
    globalAcctRoleId.current = id;
    await oneGlobalAcctRole.fetch(id);
  };

  const close = () => {
    setVisible(false);
    setTitle(undefined);
    globalAcctRoleId.current = undefined;
    oneGlobalAcctRole.clear();
  };

  const save = async (orgAcctRoleIds?: string[]) => {
    await asyncLoadingWrapper(
      loading,
      async () => {
        if (isNil(globalAcctRoleId.current)) return;
        if (isNil(orgAcctRoleIds)) return;
        const globalAcctRoleIds = await pushManyOrgGlobalAcctRole(
          globalAcctRoleId.current,
          orgAcctRoleIds,
        );
        onSuccess?.('Successfully pushed.');
        close();
        return globalAcctRoleIds;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return {
    visible,
    title,
    loading: oneGlobalAcctRole.loading || loading.loading,
    data: oneGlobalAcctRole.data?.organizations,
    open,
    close,
    save,
  };
};

export default usePushDrawer;
