import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Tooltip, MenuProps, FloatButton } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

import { COMPONENTS_LOCALE_BUTTON } from '@i18n/namespaces';
import { GlobalButton } from './styleds';
import { LocaleButtonProps } from './interfaces';

const LocaleButton = (props: LocaleButtonProps) => {
  const { float } = props;
  const { t, i18n } = useTranslation(COMPONENTS_LOCALE_BUTTON);
  const [openTooltip, setOpenTooltip] = useState(false);
  const isDropdownOpen = useRef(false);

  const handleTooltipOpenChange = (open: boolean) => {
    if (!isDropdownOpen.current) setOpenTooltip(open);
  };

  const handleDropdownOpenChange = (open: boolean) => {
    if (open) setOpenTooltip(false);
    isDropdownOpen.current = open;
  };

  const handleLocaleOnChange: MenuProps['onClick'] = (info) => {
    const { key } = info;
    if (key !== i18n.language) i18n.changeLanguage(key);
  };

  return (
    <Dropdown
      trigger={['click']}
      overlayStyle={{ minWidth: 240 }}
      onOpenChange={handleDropdownOpenChange}
      menu={{
        selectable: true,
        defaultSelectedKeys: [i18n.language],
        onClick: handleLocaleOnChange,
        items: [
          {
            key: 'en',
            label: t('components.locale-button:english'),
          },
          {
            key: 'zh-TW',
            label: t('components.locale-button:traditional-chinese'),
          },
        ],
      }}
    >
      <Tooltip
        title={t('components.locale-button:language')}
        open={openTooltip}
        onOpenChange={handleTooltipOpenChange}
      >
        {float ? <FloatButton icon={<GlobalOutlined />} /> : <GlobalButton />}
      </Tooltip>
    </Dropdown>
  );
};

export default LocaleButton;
