import styled from 'styled-components';
import { List, theme } from 'antd';

const { Item: ListItem } = List;

export const StyledListItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => !['active', 'clickable'].includes(prop),
})<{ active?: boolean; clickable?: boolean }>`
  cursor: ${({ clickable = true }) => (clickable ? 'pointer' : 'not-allowed')};
  background-color: ${(props) =>
    props.active ? theme.getDesignToken().colorPrimaryBg : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
