import { cloneElement, isValidElement, MouseEventHandler } from 'react';
import {
  useHref,
  useLinkClickHandler,
  useLocation,
  Location,
} from 'react-router-dom';
import {
  Tooltip,
  Button as NormalButton,
  Dropdown,
  Space,
  Badge as NormalBadge,
} from 'antd';
import { isNil, isNotNil } from 'ramda';
import { MoreOutlined } from '@ant-design/icons';

import { TooltipButtonProps } from './interfaces';
import { WithDropdownBadge } from './styleds';

interface LocationState {
  prev?: string[];
}

const { Compact } = Space;

const TooltipButton = (props: TooltipButtonProps = {}) => {
  const {
    children,
    wrapper,
    title,
    navigate,
    target,
    onClick,
    dropdown,
    badge,
    ...buttonProps
  } = props;
  const location: Location<LocationState> = useLocation();
  const { pathname, search } = location;
  const state = location.state || {};
  const { to, ...linkProps } = navigate || {};
  const href = useHref(to || '#');

  const handleRouterLinkOnClick = useLinkClickHandler<HTMLElement>(to || '#', {
    ...linkProps,
    target: target || navigate?.target,
    state: {
      ...state,
      ...navigate?.state,
      prev: [`${pathname}${search}`, ...(state.prev || [])],
    },
  });

  const handleOnClick: MouseEventHandler<HTMLElement> = (event) => {
    onClick?.(event);
    if (!event.defaultPrevented) {
      handleRouterLinkOnClick(event);
    }
  };

  const Button = isNotNil(dropdown) ? Dropdown.Button : NormalButton;

  const withNavigateOrNot = isNotNil(navigate) ? (
    <Button
      {...buttonProps}
      href={isNotNil(buttonProps.href) ? href : undefined}
      target={target}
      onClick={handleOnClick}
      {...(isNotNil(dropdown)
        ? { trigger: ['click'], icon: <MoreOutlined />, ...dropdown }
        : {})}
    >
      {children}
    </Button>
  ) : (
    <Button
      {...buttonProps}
      target={target}
      onClick={onClick}
      {...(isNotNil(dropdown)
        ? { trigger: ['click'], icon: <MoreOutlined />, ...dropdown }
        : {})}
    >
      {children}
    </Button>
  );

  const Badge = isNotNil(dropdown) ? WithDropdownBadge : NormalBadge;

  const withBadgeOrNot = isNotNil(badge) ? (
    <Badge offset={[-32, 0]} {...badge}>
      {withNavigateOrNot}
    </Badge>
  ) : (
    withNavigateOrNot
  );

  return (
    <Tooltip title={title}>
      {isNil(wrapper)
        ? withBadgeOrNot
        : isValidElement(wrapper)
        ? cloneElement(wrapper, {
            children: withBadgeOrNot,
          })
        : null}
    </Tooltip>
  );
};

export default TooltipButton;
export type { TooltipButtonProps };
