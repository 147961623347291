import { useState } from 'react';

import * as APIs from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import { getSuperAdminMeInfo } from '@apis/super-admins';

interface UseSuperAdminMeInfoOptions {
  onError?: (error: unknown) => void;
}

const useSuperAdminMeInfo = (options: UseSuperAdminMeInfoOptions = {}) => {
  const { onError } = options;
  const [loading, setLoading] = useState(false);
  const [me, setMe] = useState<APIs.SuperAdmins.SuperAdmin>();

  const get = async () => {
    const me = await asyncLoadingWrapper(
      setLoading,
      async () => {
        return await getSuperAdminMeInfo();
      },
      onError,
    );
    setMe(me);
  };

  return { loading, get, me };
};

export default useSuperAdminMeInfo;
