import { FormInstance } from 'antd';

export const formValidateFields = async <FormValues = any>(
  form: FormInstance<FormValues>,
): Promise<[data: FormValues, error: null] | [data: null, error: Error]> => {
  try {
    const data = await form.validateFields();
    return [data, null] as const;
  } catch (error) {
    return [null, error as Error] as const;
  }
};
