import { forwardRef, ForwardedRef, useEffect } from 'react';
import { Form, Input, Select } from 'antd';

import DrawerFilter from '@components/DrawerFilter';
import { FilterSchema } from './schemas';
import { FilterProps, FilterValue, FilterRef } from './interfaces';

const { Item: FormItem, useForm } = Form;

const TagsSelectTokenSeparators = [',', ', '];
const TagsSelectMaxTagCount = 20;

const Filter = forwardRef(
  (props: FilterProps, ref: ForwardedRef<FilterRef>) => {
    const {
      open,
      form: extForm,
      value,
      breakpoint,
      width,
      searchOnMounted,
      onSearch,
      onReset,
      onClose,
    } = props;
    const [form] = useForm(extForm);

    return (
      <DrawerFilter
        ref={ref}
        open={open}
        form={form}
        value={value}
        schema={FilterSchema}
        breakpoint={breakpoint}
        width={width}
        searchOnMounted={searchOnMounted}
        onSearch={onSearch}
        onReset={onReset}
        onClose={onClose}
      >
        <FormItem name="id" label="ID">
          <Select
            mode="tags"
            placeholder="XXXX,YYYY ..."
            tokenSeparators={TagsSelectTokenSeparators}
            maxTagCount={TagsSelectMaxTagCount}
            open={false}
            suffixIcon={null}
          />
        </FormItem>
        <FormItem name="name" label="Name" rules={[{ min: 3 }]}>
          <Input />
        </FormItem>
        <FormItem name="memberId" label="Member ID">
          <Select
            mode="tags"
            placeholder="XXXX,YYYY ..."
            tokenSeparators={TagsSelectTokenSeparators}
            maxTagCount={TagsSelectMaxTagCount}
            open={false}
            suffixIcon={null}
          />
        </FormItem>
        <FormItem name="memberName" label="Member Name" rules={[{ min: 3 }]}>
          <Input />
        </FormItem>
      </DrawerFilter>
    );
  },
);

export default Filter;
export type { FilterValue };
