import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';

import { COMMON } from '@i18n/namespaces';
import TooltipButton from '../TooltipButton';
import { ReloadButtonProps } from './interfaces';

const ReloadButton = (props: ReloadButtonProps = {}) => {
  const { t } = useTranslation(COMMON);
  const { title = t('common:reload', 'Reload'), onClick } = props;

  return (
    <TooltipButton title={title} icon={<ReloadOutlined />} onClick={onClick} />
  );
};

export default ReloadButton;
