import z from 'zod';

import { SearchScope } from '@enums/organizations.enum';
import { PermissionType } from '@enums/permissions.enum';
import { IdQuerySchema, TextQuerySchema } from './utils.schema';

export const SearchManyQuerySchema_SuperAdmin = z.object({
  keyword: z.string().min(1),
  scope: z.nativeEnum(SearchScope).optional().default(SearchScope.ALL),
  exclude: z
    .object({
      org: z.string().array().optional(),
      clstr: z.string().array().optional(),
    })
    .optional(),
});

export const SearchManyRespSchema_SuperAdmin = z.object({
  id: z.string(),
  name: z.string(),
  type: z.union([
    z.literal(SearchScope.CLUSTER),
    z.literal(SearchScope.ORGANIZATION),
  ]),
});

export const FindOneRolesPermissionsQuerySchema_SuperAdmin = z.object({
  id: IdQuerySchema.optional(),
  name: TextQuerySchema.optional(),
});

export const FindOneRolesPermissionsRespSchema_SuperAdmin = z.object({
  id: z.string(),
  name: z.string(),
  permissions: z
    .object({
      id: z.string(),
      name: z.string(),
      type: z.nativeEnum(PermissionType),
    })
    .array(),
});
