import { ColumnsType } from 'antd/es/table';
import { Button, Space, Divider, Typography } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import { ManagerStatus, ManagerType } from '@enums/managers.enum';
import Link from '@components/Link';
import { MainTableRecordType } from './interfaces';

const { Text } = Typography;

interface CreateTableColumnsOptions {
  onReinviteClick: (mgrId: string) => void;
  onStatusChange: (mgrId: string, activated: boolean) => void;
}

const ManagerStatusNaming = {
  [ManagerStatus.INVITED]: 'Invited',
  [ManagerStatus.INVITATION_FAILED]: 'Invitation Failed',
  [ManagerStatus.ACTIVATED]: 'Activated',
  [ManagerStatus.DEACTIVATED]: 'Deactivated',
  [ManagerStatus.DELETED]: 'Delete',
  [ManagerStatus.FLUSHED_TOKEN]: 'Flushed Token',
};

const renderManagerStatus = (status: ManagerStatus) => {
  return ManagerStatusNaming[status];
};

export const createMainTableColumns = (
  options: CreateTableColumnsOptions,
): ColumnsType<MainTableRecordType> => {
  const { onReinviteClick, onStatusChange } = options;
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      render(id) {
        return (
          <Space>
            <Link navigate={{ to: `editor/${id}` }}>
              <SelectOutlined />
            </Link>
            <Text>{id}</Text>
          </Space>
        );
      },
    },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' },
    { title: 'Phone', dataIndex: 'phone' },
    {
      title: 'Type',
      dataIndex: 'type',
      render(type) {
        return type === ManagerType.BOILERPLATE ? 'Boilerplate' : 'Reality';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render(status) {
        return renderManagerStatus(status);
      },
    },
    {
      title: 'Default Organization',
      dataIndex: ['defaultManagerOrganization', 'organization', 'name'],
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, { id, status }) => (
        <Space split={<Divider type="vertical" />}>
          <Button
            type="link"
            disabled={
              ![
                ManagerStatus.INVITED,
                ManagerStatus.INVITATION_FAILED,
              ].includes(status)
            }
            onClick={() => onReinviteClick(id)}
          >
            Reinvite
          </Button>
          <Button
            type="link"
            danger={status === ManagerStatus.ACTIVATED}
            disabled={
              ![ManagerStatus.ACTIVATED, ManagerStatus.DEACTIVATED].includes(
                status,
              )
            }
            onClick={() =>
              onStatusChange(id, !(status === ManagerStatus.ACTIVATED))
            }
          >
            {status === ManagerStatus.ACTIVATED ? 'Deactivate' : 'Activate'}
          </Button>
        </Space>
      ),
    },
  ];
};
