import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { isNil, remove } from 'ramda';
import z from 'zod';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import {
  SearchScope,
  SearchResultType,
  ManagerType,
  ManagerEditMode,
} from '@enums/managers.enum';
import useLoading from '@hooks/useLoading';
import { createOne_SuperAdmin } from '@apis/managers';
import { FormValues } from '../interfaces';

interface UseCreateOneOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const authzSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal(ManagerEditMode.MANUAL),
    items: z
      .object({
        add: z
          .object({
            orgId: z.string(),
            roleIds: z.string().array().nullable(),
          })
          .array()
          .nullable(),
        remove: z
          .object({
            orgId: z.string(),
            roleIds: z.string().array().nullable(),
          })
          .array()
          .nullable(),
      })
      .optional()
      .transform((items) => items?.add || null),
  }),
  z.object({
    mode: z.literal(ManagerEditMode.SYNC),
    syncId: z.string(),
  }),
]);

const boilerplateSchema = z.object({
  type: z.literal(ManagerType.BOILERPLATE),
  name: z.string(),
  authz: authzSchema.optional(),
});

const realitySchema = z.object({
  type: z.literal(ManagerType.REALITY),
  name: z.string(),
  email: z.string(),
  phone: z.string().nullish(),
  authz: authzSchema.optional(),
});

const schema = z.discriminatedUnion('type', [boilerplateSchema, realitySchema]);

const useCreateOne = (options: UseCreateOneOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const { t } = useTranslation(COMMON);
  const loading = useLoading();

  const submit = async (form: FormInstance<FormValues>) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return;
        const mgrId = await createOne_SuperAdmin(schema.parse(data));
        onSuccess?.(t('common:success-message', { action: t('common:added') }));
        return mgrId;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useCreateOne;
