import { TFunction } from 'i18next';

import { COMMON, PAGES_COMMON_ERROR_BOUNDARY } from '@i18n/namespaces';
import { RouteParamsError } from '@hooks/useRouteParams';
import { ErrorWording } from './interfaces';

type ErrorBoundaryTFunction = TFunction<
  [typeof COMMON, typeof PAGES_COMMON_ERROR_BOUNDARY]
>;

const getRouteParamsErrorWording = (
  t: ErrorBoundaryTFunction,
): ErrorWording => {
  return {
    title: t(
      'pages.common.error-boundary:route-params-error-title',
      'Route Parameters Error',
    ),
    subTitle: t(
      'pages.common.error-boundary:route-params-error-sub-title',
      'Please verify if the page link is correct.',
    ),
  };
};

export const getErrorWording = (
  t: ErrorBoundaryTFunction,
  error: unknown,
): ErrorWording => {
  if (error instanceof RouteParamsError) return getRouteParamsErrorWording(t);

  return {
    title: t(
      'pages.common.error-boundary:unknown-error-title',
      'Unknown Error',
    ),
    subTitle: t(
      'pages.common.error-boundary:unknown-error-sub-title',
      'Please contact the customer service for assistance in resolving the issue.',
    ),
  };
};
