import { forwardRef, ForwardedRef } from 'react';
import { Form, Select, Input } from 'antd';

import DrawerFilter, { DrawerFilterRef } from '@components/DrawerFilter';
import { filterSchema } from './schemas';
import { FilterProps, FilterValue, FilterRef } from './interfaces';
import { ManagerStatusValues, ManagerStatusNaming } from './variables';

const { Item: FormItem, useForm } = Form;

const Filter = forwardRef(
  (props: FilterProps, ref: ForwardedRef<FilterRef>) => {
    const {
      open,
      form: extForm,
      value,
      breakpoint,
      width,
      searchOnMounted,
      onSearch,
      onReset,
      onClose,
    } = props;
    const [form] = useForm(extForm);

    return (
      <DrawerFilter
        ref={ref}
        open={open}
        form={form}
        value={value}
        schema={filterSchema}
        breakpoint={breakpoint}
        width={width}
        searchOnMounted={searchOnMounted}
        onSearch={onSearch}
        onReset={onReset}
        onClose={onClose}
      >
        <FormItem name="nameLike" label="Name" rules={[{ min: 3 }]}>
          <Input />
        </FormItem>
        <FormItem name="emailLike" label="Email" rules={[{ min: 3 }]}>
          <Input />
        </FormItem>
        <FormItem name="statusIn" label="Status">
          <Select
            mode="multiple"
            allowClear
            options={ManagerStatusValues.map((status) => ({
              value: status,
              label: ManagerStatusNaming[status],
            }))}
          />
        </FormItem>
      </DrawerFilter>
    );
  },
);

export default Filter;
export type { FilterValue, DrawerFilterRef as FilterRef };
