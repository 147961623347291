import { DependencyList } from 'react';

import useMounted, { MountedCallback } from './useMounted';
import useBeforeMounted, { BeforeMountedCallback } from './useBeforeMounted';
import useBeforeFirstRendered, {
  BeforeFirstRenderedCallback,
} from './useBeforeFirstRendered';

interface UseLifecycleOptions {
  onMounted?: MountedCallback;
  onBeforeMounted?: BeforeMountedCallback;
  onBeforeFirstRendered?: BeforeFirstRenderedCallback;
}

const useLifecycle = (options: UseLifecycleOptions, deps: DependencyList) => {
  const {
    onMounted = () => {},
    onBeforeMounted = () => {},
    onBeforeFirstRendered = () => {},
  } = options;

  useMounted(onMounted);

  useBeforeMounted(onBeforeMounted, deps);

  useBeforeFirstRendered(onBeforeFirstRendered, deps);
};

export default useLifecycle;
