import { useState, useRef, useEffect } from 'react';

const useIsFirstRendered = () => {
  const [signal, setSignal] = useState<'mount' | 'unmount'>('unmount');
  const isFirstRendered = useRef(false);

  useEffect(() => {
    isFirstRendered.current = signal === 'mount';
  }, [signal]);

  useEffect(() => {
    setSignal('mount');
    return () => {
      setSignal('unmount');
    };
  }, []);

  return isFirstRendered;
};

export default useIsFirstRendered;
