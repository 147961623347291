import { Col } from 'antd';

import { CenterLayoutProps } from './interfaces';
import { FullSizeContent, FullSizeRow } from './styleds';

const CenterLayout = (props: CenterLayoutProps) => {
  const { children } = props;
  return (
    <FullSizeContent>
      <FullSizeRow align="middle" justify="center" wrap={false}>
        <Col>{children}</Col>
      </FullSizeRow>
    </FullSizeContent>
  );
};

export default CenterLayout;
