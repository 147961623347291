import { useState } from 'react';
import { Affix, GetProps, Form } from 'antd';

import { Container } from './styleds';

type FormProps = GetProps<typeof Form>;

const AffixFormFooter = ({ children, ...props }: FormProps = {}) => {
  const [affixed, setAffixed] = useState<boolean>();

  return (
    <Affix offsetBottom={40} onChange={setAffixed}>
      <Container affixed={affixed}>
        <Form {...props} children={children} />
      </Container>
    </Affix>
  );
};

export default AffixFormFooter;
