import { useState, useEffect } from 'react';
import { Select, List, Typography, Skeleton, Button, Flex } from 'antd';
import { isEmpty, isNil, isNotNil, not } from 'ramda';
import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';

import {
  SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR,
  SUPER_ADMIN_FETCH_ONE_MANAGER_CLUSTER_ERROR,
} from '@constants/notifications';
import { SearchResultType } from '@enums/managers.enum';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import FluidSpace from '@components/FluidSpace';
import Link from '@components/Link';
import useSearchManyManager from './hooks/useSearchManyManager';
import useFindOneCluster from './hooks/useFindOneCluster';
import { MemberSelectProps, MemberValue } from './interfaces';

const { Option: SelectOption } = Select;
const { Text } = Typography;
const { Item: ListItem } = List;
const { Meta: ListItemMeta } = ListItem;

const MemberSelect = (props: MemberSelectProps = {}) => {
  const { value, onChange } = props;
  const { errorNotify } = useGlobalNotification();
  const search = useSearchManyManager({
    onError: errorNotify(SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR),
  });
  const cluster = useFindOneCluster({
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_MANAGER_CLUSTER_ERROR),
  });
  const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const valueMap =
    value?.reduce<Record<string, number>>((acc, item, index) => {
      acc[item.id] = index;
      return acc;
    }, {}) || {};

  const handleOnSearch = (text: string) => {
    setSearchText(text);
    if (text.length < 1) return;
    const regexp = new RegExp(`^${text}`, 'i');
    const excludes = value?.filter((item) => regexp.test(`${item.name}`));
    search.fetch(
      text,
      excludes?.map((item) => item.id),
    );
  };

  const handleSelectChange = async (id: string) => {
    try {
      const result = search.data.find((item) => id === item.id);
      if (isNil(result)) return;
      if (result.type === SearchResultType.CLUSTER) {
        const data = await cluster.fetch(result.id);
        if (isNil(data)) return;
        const members = data.members.filter((member) =>
          isNil(valueMap[member.id]),
        );
        if (not(isEmpty(members))) {
          onChange?.(isNotNil(value) ? [...members, ...value] : [...members]);
        }
      } else {
        onChange?.(
          isNotNil(value) ? [{ ...result }, ...value] : [{ ...result }],
        );
      }
    } finally {
      setSearchText('');
      search.clear();
    }
  };

  const handleRemoveClick = (_: MemberValue, index: number) => {
    onChange?.(
      isNotNil(value)
        ? [...value.slice(0, index), ...value.slice(index + 1)]
        : value,
    );
  };

  const handleRemoveAllClick = () => {
    onChange?.([]);
  };

  useEffect(() => {
    setPage(1);
  }, [value]);

  return (
    <FluidSpace direction="vertical">
      <Flex gap={16}>
        <Select
          style={{ width: '100%' }}
          loading={search.loading || cluster.loading}
          showSearch
          suffixIcon={null}
          filterOption={false}
          notFoundContent={null}
          searchValue={searchText}
          onSearch={handleOnSearch}
          value={null}
          onChange={handleSelectChange}
          onBlur={search.clear}
          onDropdownVisibleChange={search.clear}
        >
          {search.data?.map(({ id, name, type }) => (
            <SelectOption key={id} value={id}>
              <FluidSpace size="small">
                <Text>{name}</Text>
                {type === SearchResultType.CLUSTER && (
                  <Text type="secondary">[Cluster]</Text>
                )}
              </FluidSpace>
            </SelectOption>
          ))}
        </Select>
      </Flex>

      <List
        itemLayout="horizontal"
        dataSource={value}
        pagination={{
          align: 'end',
          total: value?.length,
          current: page,
          onChange: (page) => setPage(page),
          showTotal() {
            return <Button onClick={handleRemoveAllClick}>Remove All</Button>;
          },
        }}
        renderItem={({ id, name }, index) => {
          const realIndex = index + (page - 1) * 10;
          return (
            <ListItem
              actions={
                !cluster.loading
                  ? [
                      <FluidSpace size="large">
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            handleRemoveClick({ id, name }, realIndex);
                          }}
                        />
                      </FluidSpace>,
                    ]
                  : undefined
              }
            >
              <Skeleton
                loading={cluster.loading}
                active
                title={false}
                paragraph={{ rows: 2 }}
              >
                <ListItemMeta
                  title={
                    <FluidSpace>
                      <Link
                        navigate={{
                          to: `../managers/editor/${id}`,
                        }}
                      >
                        <SelectOutlined />
                      </Link>
                      <Text>{name}</Text>
                    </FluidSpace>
                  }
                />
              </Skeleton>
            </ListItem>
          );
        }}
      />
    </FluidSpace>
  );
};

export default MemberSelect;
