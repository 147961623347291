import { isNil } from 'ramda';

type Callback = () => void;

const nextTick = (callback: Callback) => {
  let tickId: NodeJS.Timeout | undefined = undefined;
  tickId = setTimeout(callback, 0);

  const clear = () => {
    if (isNil(tickId)) return;
    clearTimeout(tickId);
    tickId = undefined;
  };

  return { clear };
};

export default nextTick;
