import { ColumnsType } from 'antd/es/table';
import { Button } from 'antd';

import { TableRecordType } from './interfaces';

interface CreateTableColumnsOptions {
  onEditClick: (orgId: string) => void;
}

export const createTableColumns = (
  options: CreateTableColumnsOptions,
): ColumnsType<TableRecordType> => {
  const { onEditClick } = options;
  return [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Head',
      dataIndex: 'head',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Tax ID',
      dataIndex: 'taxId',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Button type="link" onClick={() => onEditClick(record.id)}>
          Edit
        </Button>
      ),
    },
  ];
};
