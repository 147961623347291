import { Fragment } from 'react';
import { Skeleton, SkeletonProps } from 'antd';
import styled from 'styled-components';

const StyledSkeleton = styled(Skeleton.Avatar)`
  display: flex !important;
  margin: 0 24px;
`;

const AvatarSkeleton = (props: Pick<SkeletonProps, 'loading' | 'children'>) => {
  const { children, loading } = props;
  return <Fragment>{loading ? <StyledSkeleton active /> : children}</Fragment>;
};

export default AvatarSkeleton;
