import styled from 'styled-components';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export const Section = styled.div`
  width: 320px;
  padding: 12px;
`;

export const Header = styled(Title).attrs({ level: 4 })`
  margin-bottom: 0 !important;
`;

export const SubTitle = styled(Paragraph)`
  margin-bottom: 24px !important;
`;

export const InfoList = styled.ul`
  margin-block: 0 !important;
  list-style-type: disc !important;
`;
