export const PAGES_COMMON_ERROR_BOUNDARY =
  'pages.common.error-boundary' as const;

export const PAGES_MANAGERS_LOGIN = 'pages.managers.login' as const;

export const PAGES_MANAGERS_ACCOUNT_LIST =
  'pages.managers.account-list' as const;

export const PAGES_MANAGERS_ACCOUNT_Editor =
  'pages.managers.account-editor' as const;

export const PAGES_MANAGERS_ACCOUNT_ROLE =
  'pages.managers.account-role' as const;

export const PAGES_MANAGERS_ACCOUNT_ROLE_Editor =
  'pages.managers.account-role-editor' as const;

/**
 * Fleet Tracker / Components
 */

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_BIKE_TABLE =
  'pages.managers.fleet-tracker.components.bike-table' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_FILTER =
  'pages.managers.fleet-tracker.components.filter' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_STATUS_TAGS =
  'pages.managers.fleet-tracker.components.status-tags' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_VEHICLE_STATISTICS =
  'pages.managers.fleet-tracker.components.vehicle-statistics' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_MANY_BIKE_MAP =
  'pages.managers.fleet-tracker.components.many-bike-map' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_VEHICLE_ROUTE =
  'pages.managers.fleet-tracker.components.vehicle-route' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_COMPONENTS_VEHICLE_ODO =
  'pages.managers.fleet-tracker.components.vehicle-odo' as const;

/**
 * Fleet Tracker / Pages
 */

export const PAGES_MANAGERS_FLEET_TRACKER_OVERVIEW =
  'pages.managers.fleet-tracker.overview' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_VEHICLE_DETAIL =
  'pages.managers.fleet-tracker.vehicle-detail' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_VEHICLE_BUILDER =
  'pages.managers.fleet-tracker.vehicle-builder' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_VEHICLE_EDITOR =
  'pages.managers.fleet-tracker.vehicle-editor' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_LOCATION_HISTORY =
  'pages.managers.fleet-tracker.location-history' as const;

export const PAGES_MANAGERS_FLEET_TRACKER_TRACKING =
  'pages.managers.fleet-tracker.tracking' as const;

export const PAGES_MANAGERS_RENTOUR_BILLING =
  'pages.managers.rentour.billing' as const;

export const PAGES_MANAGERS_RENTOUR_RESERVATION_DASHBOARD =
  'pages.managers.rentour.reservation-dashboard' as const;

export const PAGES_MANAGERS_RENTOUR_RESERVATION_MANAGEMENT =
  'pages.managers.rentour.reservation-management' as const;

export const PAGES_MANAGERS_RENTOUR_RESERVATION_EDITOR =
  'pages.managers.rentour.reservation-editor' as const;

export const PAGES_MANAGERS_RENTOUR_RESERVATION_BUILDER =
  'pages.managers.rentour.reservation-builder' as const;

export const PAGES_MANAGERS_RENTOUR_VEHICLE_MANAGEMENT =
  'pages.managers.rentour.vehicle-management' as const;

export const PAGES_MANAGERS_RENTOUR_VEHICLE_CONTROL =
  'pages.managers.rentour.vehicle-control' as const;

export const PAGES_SUPER_ADMIN_LOGIN = 'pages.super-admins.login' as const;
