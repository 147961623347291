import z from 'zod';

import { ManagerStatus } from '@enums/managers.enum';

export const filterSchema = z.object({
  nameLike: z.string().optional(),
  emailLike: z.string().optional(),
  statusIn: z
    .array(z.coerce.number().pipe(z.nativeEnum(ManagerStatus)))
    .optional(),
});
