import { MouseEventHandler } from 'react';
import { Typography } from 'antd';
import {
  useHref,
  useLinkClickHandler,
  useLocation,
  Location,
} from 'react-router-dom';
import { isNotNil } from 'ramda';

import { LinkProps } from './interfaces';
const { Link: AntdLink } = Typography;

interface LocationState {
  prev?: string[];
}

const Link = (props: LinkProps = {}) => {
  const { children, navigate, target, onClick, ...otherProps } = props;
  const location: Location<LocationState> = useLocation();
  const { pathname, search } = location;
  const state = location.state || {};
  const { to, ...linkProps } = navigate || {};
  const href = useHref(to || '#');

  const handleRouterLinkOnClick = useLinkClickHandler<HTMLElement>(to || '#', {
    ...linkProps,
    target: target || navigate?.target,
    state: {
      ...state,
      ...navigate?.state,
      prev: [`${pathname}${search}`, ...(state.prev || [])],
    },
  });

  const handleOnClick: MouseEventHandler<HTMLElement> = (event) => {
    onClick?.(event);
    if (!event.defaultPrevented) {
      handleRouterLinkOnClick(event);
    }
  };

  return isNotNil(navigate) ? (
    <AntdLink
      {...otherProps}
      href={href}
      target={target}
      onClick={handleOnClick}
    >
      {children}
    </AntdLink>
  ) : (
    <AntdLink {...otherProps} target={target} onClick={onClick}>
      {children}
    </AntdLink>
  );
};

export default Link;
