import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { isNil } from 'ramda';

interface UseLoadingOptions {
  defaultLoading?: boolean;
  defaultDuration?: number;
}

const useLoading = (options: UseLoadingOptions = {}) => {
  const { defaultLoading = false, defaultDuration = 300 } = options;
  const [loading, setLoading] = useState(defaultLoading);
  const endAt = useRef(0);
  const timer = useRef<NodeJS.Timeout>();

  const start = (duration: number = defaultDuration) => {
    setLoading(true);
    endAt.current = dayjs().valueOf() + duration;
  };

  const end = (immediate: boolean = false) => {
    const now = dayjs().valueOf();
    if (now >= endAt.current || immediate) setLoading(false);
    else {
      const diff = endAt.current - now;
      timer.current = setTimeout(() => setLoading(false), diff);
    }
  };

  useEffect(
    () => () => {
      if (isNil(timer.current)) return;
      clearTimeout(timer.current);
      timer.current = undefined;
    },
    [],
  );

  return { loading, start, end };
};

export default useLoading;
