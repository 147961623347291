import { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { Locale } from 'antd/lib/locale';

import enUS from 'antd/locale/en_US';
import zhTW from 'antd/locale/zh_TW';

import { ROUTERS_MANAGER, ROUTERS_SUPER_ADMIN } from '@i18n/namespaces';
import GlobalNotificationProvider from '@contexts/GlobalNotificationContext';
import useBeforeMounted from '@hooks/useBeforeMounted';
import { createRouter } from './routers';

const AntdLocale: Record<string, Locale> = {
  en: enUS,
  'zh-TW': zhTW,
};

const DayjsLocale: Record<string, string> = {
  en: 'en',
  'zh-TW': 'zh-tw',
};

const getAntdLocale = (lang: string) => AntdLocale[lang] || enUS;

const getDayjsLocale = (lang: string) => DayjsLocale[lang] || 'en';

const App = () => {
  const { t, i18n } = useTranslation([ROUTERS_MANAGER, ROUTERS_SUPER_ADMIN]);
  const [antdLocale, setAntdLocale] = useState(getAntdLocale(i18n.language));

  useBeforeMounted(() => {
    const handleLanguageChanged = (lang: string) => {
      setAntdLocale(getAntdLocale(lang));
      dayjs.locale(getDayjsLocale(lang));
    };

    dayjs.locale(getDayjsLocale(i18n.language));
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  return (
    <ConfigProvider locale={antdLocale}>
      <GlobalNotificationProvider>
        <RouterProvider router={createRouter(t, i18n)} />
      </GlobalNotificationProvider>
    </ConfigProvider>
  );
};

export default App;
