import { compose, isEmpty, not } from 'ramda';

export const isNotEmpty = compose(not, isEmpty);

export type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

export const setObjDeepNil = <O extends object>(obj: O) => {
  const result: Record<string, unknown> = {};
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        const value = obj[key] as any[];
        result[key] = value.map(setObjDeepNil);
      } else {
        result[key] = setObjDeepNil(obj[key] as object);
      }
    } else {
      result[key] = undefined;
    }
  }
  return result as RecursivePartial<O>;
};
