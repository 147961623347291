import { parse } from 'regexparam';

import { MenuItemMetaMap, MenuItemMeta } from '../interface';

export const getMenuItemRegexFromRoutePath = (path: string, prefix: string) => {
  const url = `${prefix === '' ? '' : `/${prefix}`}/${path}`;
  const { pattern } = parse(url);
  return pattern;
};

export const getMenuItemMeta = (
  path: string,
  menuItemMetaMap: MenuItemMetaMap,
) => {
  const matches: MenuItemMeta[] = [];
  for (let [{ regex }, meta] of Array.from(menuItemMetaMap.entries())) {
    if (regex.test(path)) matches.push(meta);
  }
  if (matches.length === 0) return null;

  return matches.find((meta, index) => {
    if (index + 1 === matches.length) return true;

    const next = matches[index + 1];
    if (meta.key.length > next.key.length) return true;
    if ((meta.key.match(/:/g) || 0) < (next.key.match(/:/g) || 0)) return true;

    return false;
  });
};
