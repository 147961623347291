import { ReactNode } from 'react';
import { FormInstance } from 'antd';
import { isNil, equals } from 'ramda';

import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import useLoading from '@hooks/useLoading';
import { patchOneGlobalAccountRole } from '@apis/global-account-roles';
import { FormValues } from '../interfaces';

interface UseEditOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useEdit = (props: UseEditOptions = {}) => {
  const { onSuccess, onError, throwable } = props;
  const loading = useLoading();

  const submit = async (
    globalAccountRoleId: string,
    form: FormInstance<FormValues>,
    initialValues?: FormValues,
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return false;
        const { name, permissionIds } = data;
        const isSamePermIds = equals(
          permissionIds,
          initialValues?.permissionIds,
        );

        if (!isNil(initialValues)) {
          if (name === initialValues.name && isSamePermIds) return false;
        }

        await patchOneGlobalAccountRole(
          globalAccountRoleId,
          isSamePermIds ? { name } : data,
        );
        onSuccess?.('Successfully updated.');
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useEdit;
