import { isNil } from 'ramda';
import { useParams } from 'react-router-dom';

import Invitation, { InvitationProps } from '@components/templates/Invitation';
import {
  SUPER_ADMIN_ACTIVATE_ERROR,
  SUPER_ADMIN_ACTIVATE_SUCCESS,
  SUPER_ADMIN_ACTIVATION_KEY_NOT_FOUND_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useActivate from './hooks/useActivate';

const SuperAdminInvitation = () => {
  const { key } = useParams<{ key: string }>();
  const { errorNotify, successNotify } = useGlobalNotification();
  const activate = useActivate({
    onSuccess: successNotify(SUPER_ADMIN_ACTIVATE_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_ACTIVATE_ERROR),
  });

  const handleOnSubmit: InvitationProps['onSubmit'] = async (data) => {
    if (isNil(key)) {
      errorNotify(
        SUPER_ADMIN_ACTIVATION_KEY_NOT_FOUND_ERROR,
        'Activate Key Not Found',
      );
      return;
    }
    await activate.submit({ ...data, key });
  };

  return <Invitation loading={activate.loading} onSubmit={handleOnSubmit} />;
};

export default SuperAdminInvitation;
