import { useState, useRef, useMemo } from 'react';
import {
  isNil,
  isNotNil,
  mergeDeepWith,
  omit,
  prop,
  uniq,
  uniqBy,
} from 'ramda';

import { Managers } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import {
  findOneAuthzs_SuperAdmin,
  findManyAuthz_SuperAdmin,
} from '@apis/managers';

interface Authz {
  org: {
    id: string;
    name: string;
  };
  roleIds: string[] | null;
}

interface UseAuthzsOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useAuthzs = (props: UseAuthzsOptions = {}) => {
  const { onError, throwable } = props;
  const loading = useLoading();
  const mgrsCountByOrg = useRef<Record<string, number>>({});
  const orgGroupByMgr = useRef<Record<string, string[]>>({});
  const [authzs, setAuthzs] = useState<Authz[]>([]);
  const authzsMapByOrgId = useMemo(
    () =>
      authzs.reduce<Record<string, number>>((acc, cur, index) => {
        acc[cur.org.id] = index;
        return acc;
      }, {}),
    [authzs],
  );

  const fetchMany = async (ids: string[]) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data] = await findManyAuthz_SuperAdmin({ ids, all: true });
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const fetchOne = async (id: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data] = await findOneAuthzs_SuperAdmin(id, { all: true });
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const extractMgrCountAndOrgGroupByAndAuthzs = (
    data: Managers.FindManyAuthzResp_SuperAdmin[],
  ) => {
    const [mgrCountByOrg, orgGroupByMgr, groupByOrg] = data.reduce<
      [Record<string, number>, Record<string, string[]>, Record<string, Authz>]
    >(
      (acc, curr) => {
        curr.authzs.forEach(({ org, roles }) => {
          acc[0][org.id] = (acc[0][org.id] ?? 0) + 1;

          acc[1][curr.id] = [...(acc[1][curr.id] ?? []), org.id];

          const current = acc[2][org.id];
          let next: Authz;
          if (isNotNil(current)) {
            next = {
              ...current,
              roleIds: [...(current.roleIds ?? []), ...roles.map(prop('id'))],
            };
          } else {
            next = { org, roleIds: roles.map(prop('id')) };
          }
          acc[2][org.id] = next;
        });
        return acc;
      },
      [{}, {}, {}],
    );

    const authzs = Object.values(groupByOrg).map((authz) => ({
      org: authz.org,
      roleIds: uniq(authz.roleIds ?? []),
    }));

    return [mgrCountByOrg, orgGroupByMgr, authzs] as const;
  };

  const add = async (idOrIds: string | string[]) => {
    let data: Managers.FindManyAuthzResp_SuperAdmin[] | undefined = undefined;
    if (Array.isArray(idOrIds)) {
      data = await fetchMany(idOrIds);
    } else {
      const authzs = await fetchOne(idOrIds);
      if (isNotNil(authzs)) {
        data = [{ id: idOrIds, authzs }];
      }
    }
    if (isNil(data)) return;

    const [count, groupBy, authzs] =
      extractMgrCountAndOrgGroupByAndAuthzs(data);
    mgrsCountByOrg.current = mergeDeepWith(
      (left, right) => {
        return (left ?? 0) + (right ?? 0);
      },
      mgrsCountByOrg.current,
      count,
    );
    orgGroupByMgr.current = { ...orgGroupByMgr.current, ...groupBy };
    setAuthzs((prev) => {
      authzs.forEach((authz) => {
        const index = authzsMapByOrgId[authz.org.id];
        if (isNotNil(index)) {
          const current = prev[index];
          const next = {
            org: current.org,
            roleIds: uniqBy(prop('id'), [
              ...(current.roleIds ?? []),
              ...authz.roleIds,
            ]),
          };
          prev = [...prev.slice(0, index), next, ...prev.slice(index + 1)];
        } else {
          prev = [...prev, authz];
        }
      });
      return prev;
    });
  };

  const remove = async (id: string) => {
    const orgIds = orgGroupByMgr.current[id];
    if (isNotNil(orgIds)) {
      orgGroupByMgr.current = omit([id], orgGroupByMgr.current);

      const nextAuthzs: (Authz | null)[] = authzs;
      orgIds.forEach((orgId) => {
        const count = mgrsCountByOrg.current[orgId];
        if (count > 1) {
          mgrsCountByOrg.current[orgId] = count - 1;
        } else if (count === 1) {
          mgrsCountByOrg.current = omit([orgId], mgrsCountByOrg.current);
          const index = authzsMapByOrgId[orgId];
          if (isNotNil(index)) nextAuthzs[index] = null;
        }
      });
      setAuthzs(nextAuthzs.filter(isNotNil));
    }
  };

  const fetch = async (ids: string[]) => {
    const data = await fetchMany(ids);
    if (isNil(data)) return;

    const [count, groupBy, authzs] =
      extractMgrCountAndOrgGroupByAndAuthzs(data);
    mgrsCountByOrg.current = count;
    orgGroupByMgr.current = groupBy;
    setAuthzs(authzs);
    return authzs;
  };

  const clear = () => {
    mgrsCountByOrg.current = {};
    orgGroupByMgr.current = {};
    setAuthzs([]);
  };

  return {
    loading: loading.loading,
    data: authzs,
    add,
    remove,
    fetch,
    clear,
  };
};

export default useAuthzs;
