import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import {
  COMMON,
  ROUTERS_MANAGER,
  ROUTERS_SUPER_ADMIN,
  COMPONENTS_LOCALE_BUTTON,
} from './namespaces';

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    backend: {
      loadPath(lngs: string[], namespaces: string[]) {
        const lng = lngs[0];
        const ns = namespaces[0].replace(/\./g, '/');
        const path = `/locales/${ns}/${lng}.json`;
        return path;
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh-TW'],
    ns: [
      COMMON,
      ROUTERS_MANAGER,
      ROUTERS_SUPER_ADMIN,
      COMPONENTS_LOCALE_BUTTON,
    ],
    keySeparator: false,
    interpolation: {
      skipOnVariables: false,
      escapeValue: false,
    },
  });

export default i18n;
