import { isNil } from 'ramda';

import { COLLAPSED_MAIN_MARGIN_LEFT } from './constants';
import MenuItemSkeleton from './components/MenuItemSkeleton';

export const getAvatarContent = (name: string) => {
  const [first, second, three] = name.split(' ');
  return [first, three || second]
    .reduce((result, text) => {
      if (!isNil(text)) {
        result.push(text.toLocaleUpperCase().charAt(0));
      }
      return result;
    }, [] as string[])
    .join('.');
};

export const isScreenGteBreakpoint = (screen?: boolean) =>
  isNil(screen) ? false : screen;

export const getCollapsedWidth = (isScreenGteBreakpoint: boolean) =>
  isScreenGteBreakpoint ? COLLAPSED_MAIN_MARGIN_LEFT : 0;

export const createLoadingMenuItems = (num: number) =>
  Array(num)
    .fill(null)
    .map((_, index) => ({
      key: `loading_menu_${index}`,
      label: <MenuItemSkeleton />,
      disabled: true,
    }));
