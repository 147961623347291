import { ColumnsType } from 'antd/es/table';
import { Space, Typography } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import { FindManyResp_SuperAdmin } from '@interfaces/apis/manager-clusters.interface';
import Link from '@components/Link';

const { Text } = Typography;

interface CreateTableColumnsOptions {}

export const createMainTableColumns = (
  options: CreateTableColumnsOptions = {},
): ColumnsType<FindManyResp_SuperAdmin> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      render(id) {
        return (
          <Space>
            <Link navigate={{ to: `editor/${id}` }}>
              <SelectOutlined />
            </Link>
            <Text>{id}</Text>
          </Space>
        );
      },
    },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Description', dataIndex: 'description' },
  ];
};
