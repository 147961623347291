import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { ManagerEditMode } from '@enums/managers.enum';
import {
  updateManyAuthz_SuperAdmin,
  syncManyAuthz_SuperAdmin,
} from '@apis/managers';
import { Managers } from '@interfaces/apis';

interface UseUpdateManyAuthzOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useUpdateManyAuthz = (options: UseUpdateManyAuthzOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const loading = useLoading();
  const { t } = useTranslation(COMMON);

  const submit = async (
    data:
      | (Managers.UpdateManyAuthzBody_SuperAdmin & {
          mode: ManagerEditMode.MANUAL;
        })
      | (Managers.SyncManyAuthzBody_SuperAdmin & {
          mode: ManagerEditMode.SYNC;
        }),
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        if (data.mode === ManagerEditMode.MANUAL) {
          const { mode, ...reqData } = data;
          await updateManyAuthz_SuperAdmin(reqData);
        } else {
          const { mode, ...reqData } = data;
          await syncManyAuthz_SuperAdmin(reqData);
        }
        onSuccess?.(
          t('common:success-message', 'Successfully updated.', {
            action: t('common:updated'),
          }),
        );
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useUpdateManyAuthz;
