import z from 'zod';

import { IdQuerySchema, TextQuerySchema } from './utils.schema';

export const FindManyQuerySchema_SuperAdmin = z.object({
  id: IdQuerySchema.optional(),
  name: TextQuerySchema.optional(),
  memberId: IdQuerySchema.optional(),
  memberName: TextQuerySchema.optional(),
});

export const FindManyRespSchema_SuperAdmin = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
});

export const CreateOneBodySchema_SuperAdmin = z.object({
  name: z.string(),
  description: z.string().nullable(),
  members: z.string().array().nullable(),
});

export const CreateOneRespSchema_SuperAdmin = z.string();

export const UpdateOneProfileBodySchema_SuperAdmin = z.object({
  name: z.string().optional(),
  description: z.string().nullable().optional(),
});

export const UpdateOneMembersBodySchema_SuperAdmin = z.object({
  addMembers: z.string().array().nullable(),
  removeMembers: z.string().array().nullable(),
});

export const FindOneRespSchema_SuperAdmin = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  members: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .array(),
});

export const FindOneMembersRolesRespSchema_SuperAdmin = z
  .object({
    id: z.string(),
    name: z.string(),
    roles: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .array(),
  })
  .array();
