import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Input, Button, Result, Typography, Space } from 'antd';
import { UserOutlined, SendOutlined } from '@ant-design/icons';
import Countdown, { zeroPad } from 'react-countdown';
import dayjs from 'dayjs';
import { isNil } from 'ramda';

import { TEMPLATES_FORGET_PASSWORD } from '@i18n/namespaces';
import useBeforeFirstRendered from '@hooks/useBeforeFirstRendered';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import { ForgetPasswordMode } from '@enums/forget-password.enum';
import CenterLayout from '@layouts/CenterLayout';
import LocaleButton from '@components/LocaleButton';
import { FormValues, ForgetPasswordProps } from './interfaces';
import { Section, Header, SubTitle } from './styleds';

const { Item: FormItem, useForm } = Form;
const { Text } = Typography;

const COUNTDOWN_SEC = 120;

const ForgetPassword = (props: ForgetPasswordProps = {}) => {
  const {
    loading = false,
    mode: extMode = ForgetPasswordMode.SEND_EMAIL,
    onSend,
    onFinish,
  } = props;
  const { t } = useTranslation(TEMPLATES_FORGET_PASSWORD);
  const navigate = useEnhancedNavigate();
  const [form] = useForm<FormValues>();
  const [mode, setMode] = useState(extMode);
  const [startCountdown, setStartCountdown] = useState(false);
  const formValues = useRef<FormValues>();

  const handleOnSend = async (values: FormValues) => {
    formValues.current = values;
    setStartCountdown(true);
    const result = await onSend?.(values);
    if (result) setMode(ForgetPasswordMode.COMPLETED);
  };

  const handleOnResend = () => {
    setStartCountdown(true);
    if (!isNil(formValues.current)) onSend?.(formValues.current);
  };

  const onCountdownComplete = () => {
    setStartCountdown(false);
  };

  const handleOnFinish = () => {
    if ('function' === typeof onFinish) onFinish();
    else navigate(-1);
  };

  useBeforeFirstRendered(() => {
    if (extMode !== mode) setMode(extMode);
  }, [extMode]);

  return (
    <CenterLayout>
      <Spin spinning={loading}>
        {mode === ForgetPasswordMode.SEND_EMAIL ? (
          <Section>
            <Header>{t('components.templates.forget-password:title')}</Header>
            <SubTitle type="secondary">
              {t('components.templates.forget-password:subtitle')}
            </SubTitle>
            <Form form={form} onFinish={handleOnSend}>
              <FormItem
                name="username"
                rules={[
                  {
                    required: true,
                    message: t(
                      'components.templates.forget-password:required-username',
                    ),
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder={t(
                    'components.templates.forget-password:username',
                  )}
                />
              </FormItem>
              <FormItem noStyle>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  block
                  icon={<SendOutlined />}
                >
                  {t('components.templates.forget-password:send')}
                </Button>
              </FormItem>
            </Form>
          </Section>
        ) : (
          <Result
            status="success"
            title={t('components.templates.forget-password:result-title')}
            subTitle={
              <Space direction="vertical">
                <Text type="secondary">
                  {t('components.templates.forget-password:result-para-1')}
                </Text>
                <Text type="secondary">
                  {t('components.templates.forget-password:result-para-2')}
                </Text>
                <Text type="secondary">
                  {t('components.templates.forget-password:result-para-3')}
                </Text>
              </Space>
            }
            extra={
              <Space>
                <Button
                  type="primary"
                  size="large"
                  disabled={startCountdown}
                  icon={!startCountdown ? <SendOutlined /> : undefined}
                  onClick={handleOnResend}
                >
                  {!startCountdown ? (
                    t('components.templates.forget-password:send')
                  ) : (
                    <Countdown
                      date={dayjs().add(COUNTDOWN_SEC, 'second').toDate()}
                      onComplete={onCountdownComplete}
                      renderer={({ seconds, minutes }) =>
                        t('components.templates.forget-password:countdown', {
                          sec: zeroPad(minutes * 60 + seconds),
                        })
                      }
                    />
                  )}
                </Button>
                <Button size="large" onClick={handleOnFinish}>
                  {t('components.templates.forget-password:finish')}
                </Button>
              </Space>
            }
          />
        )}
      </Spin>
      <LocaleButton float />
    </CenterLayout>
  );
};

export default ForgetPassword;
export type { ForgetPasswordProps };
