import { ColumnsType } from 'antd/es/table';
import { Button, Table, Space } from 'antd';

import { MainTableRecordType, SubTableRecordType } from './interfaces';

interface CreateTableColumnsOptions {
  onEditClick: (globalAcctRoleId: string) => void;
  onPushClick: (globalAcctRoleId: string, name: string) => void;
}

const { EXPAND_COLUMN } = Table;

export const createMainTableColumns = (
  options: CreateTableColumnsOptions,
): ColumnsType<MainTableRecordType> => {
  const { onEditClick, onPushClick } = options;
  return [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    EXPAND_COLUMN,
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      render(permissions) {
        return permissions.length;
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => onEditClick(record.id)}>
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => onPushClick(record.id, record.name)}
          >
            Push
          </Button>
        </Space>
      ),
    },
  ];
};

export const createSubTableColumns = (): ColumnsType<SubTableRecordType> => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
];
