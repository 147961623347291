import { useLocation } from 'react-router-dom';
import { stringify, parse } from 'qs';
import { dissocPath, isNil } from 'ramda';
import { TypeOf as ZodTypeOf, AnyZodObject } from 'zod';

interface UseUrlQueryOptions {
  allowDots?: boolean;
}

interface GetRawOption {
  allowDots?: boolean;
}

interface GetOptions {
  passthrough?: boolean;
  allowDots?: boolean;
}

interface SetOptions {
  allowDots?: boolean;
}

const QsOptions = { encodeValuesOnly: true, skipNulls: true, allowDots: true };

const useUrlQuery = <Schema extends AnyZodObject>(
  schema: Schema,
  globalOptions: UseUrlQueryOptions = {},
) => {
  const location = useLocation();

  const getRaw = (options: GetRawOption = {}) => {
    const {
      allowDots = isNil(globalOptions.allowDots)
        ? QsOptions.allowDots
        : globalOptions.allowDots,
    } = options;

    const queryString = window.location.search.slice(1);
    const query = parse(queryString, { ...QsOptions, allowDots });
    return query;
  };

  const get = (options: GetOptions = {}): ZodTypeOf<Schema> => {
    const { passthrough = false, allowDots } = options;
    const raw = getRaw({ allowDots });
    const result = (passthrough ? schema.passthrough() : schema)
      .catch(({ error, input }) => {
        error.issues.forEach((issue) => {
          input = dissocPath(issue.path, input);
        });
        return (passthrough ? schema.passthrough() : schema).parse(input);
      })
      .safeParse(raw);
    if (result.success) {
      return result.data;
    } else {
      return {} as ZodTypeOf<Schema>;
    }
  };

  const set = (query: ZodTypeOf<Schema>, options: SetOptions = {}) => {
    const {
      allowDots = isNil(globalOptions.allowDots)
        ? QsOptions.allowDots
        : globalOptions.allowDots,
    } = options;
    const raw = getRaw({ allowDots });
    const queryString = stringify(
      { ...raw, ...schema.parse(query) },
      { ...QsOptions, allowDots },
    );
    window.history.replaceState({}, '', `?${queryString}`);
    location.search = `?${queryString}`;
  };

  return { get, set };
};

export default useUrlQuery;
