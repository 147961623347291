import { useParams } from 'react-router-dom';
import { TypeOf as ZodTypeOf, ZodError, AnyZodObject } from 'zod';

export class RouteParamsError extends Error {
  constructor(readonly error?: ZodError) {
    super('ROUTE_PARAMS_ERROR');
  }
}

const useRouteParams = <Schema extends AnyZodObject>(schema: Schema) => {
  const originParams = useParams();

  const get = (): ZodTypeOf<Schema> => {
    try {
      return schema.parse(originParams);
    } catch (error) {
      throw new RouteParamsError(error as ZodError);
    }
  };

  return { get };
};

export default useRouteParams;
