import { ManagerStatus } from '@enums/managers.enum';

export const ManagerStatusValues = [
  ManagerStatus.INVITED,
  ManagerStatus.INVITATION_FAILED,
  ManagerStatus.ACTIVATED,
  ManagerStatus.DEACTIVATED,
  ManagerStatus.DELETED,
  ManagerStatus.FLUSHED_TOKEN,
];

export const ManagerStatusNaming = {
  [ManagerStatus.INVITED]: 'Invited',
  [ManagerStatus.INVITATION_FAILED]: 'Invitation Failed',
  [ManagerStatus.ACTIVATED]: 'Activated',
  [ManagerStatus.DEACTIVATED]: 'Deactivated',
  [ManagerStatus.DELETED]: 'Delete',
  [ManagerStatus.FLUSHED_TOKEN]: 'Flushed Token',
};
