import { useState } from 'react';

import { Organizations } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getOneOrganization } from '@apis/organizations';

interface UseOneOrgOptions {
  defaultLoading?: boolean;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useOneOrg = (options: UseOneOrgOptions = {}) => {
  const { defaultLoading = false, onError, throwable } = options;
  const loading = useLoading({ defaultLoading });
  const [data, setData] = useState<Organizations.Organization>();

  const fetch = async (orgId: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const data = await getOneOrganization(orgId);
        setData(data);
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, fetch, data };
};

export default useOneOrg;
