import { create } from 'zustand';
import { RibbonProps } from 'antd/es/badge/Ribbon';

const DEFAULT_STATE = {
  text: undefined,
  color: undefined,
  placement: 'start',
} as const;

export interface LayoutRibbonState
  extends Pick<RibbonProps, 'text' | 'color' | 'placement'> {}

export interface LayoutRibbonAction {
  set(state: LayoutRibbonState): void;
  reset(): void;
  has(): boolean;
}

export const useLayoutRibbonStore = create<
  LayoutRibbonState & LayoutRibbonAction
>((set, get) => ({
  ...DEFAULT_STATE,
  set(state) {
    const { placement = 'start' } = state;
    set((prev) => ({ ...prev, ...state, placement }));
  },
  reset() {
    set({ ...DEFAULT_STATE });
  },
  has() {
    return !!get().text;
  },
}));
