import { useEffect } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { PAGES_SUPER_ADMIN_LOGIN } from '@i18n/namespaces';
import Login from '@templates/Login';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useMeIsActivated from '@hooks/apis/super-admins/useMeIsActivated';
import { LOG_IN_ERROR } from '@constants/notifications';
import useLogin from './hooks/useLogin';

const SuperAdminLogin = () => {
  const { t } = useTranslation(PAGES_SUPER_ADMIN_LOGIN);
  const { errorNotify } = useGlobalNotification();
  const login = useLogin({
    onError: errorNotify(LOG_IN_ERROR),
  });
  const meIsActivated = useMeIsActivated({
    // defaultLoading: true,
  });

  useEffect(() => {
    // TODO: Need to check token is existed
    // meIsActivated.check({ to: '/super-admins/organizations', replace: true });
  }, []);

  return (
    <Spin spinning={meIsActivated.loading}>
      {!meIsActivated.loading && (
        <Login
          loading={login.loading}
          role={t('pages.super-admins.login:role')}
          color="danger"
          forgetPasswordLink="/super-admins/forget-password"
          onSubmit={login.submit}
        />
      )}
    </Spin>
  );
};

export default SuperAdminLogin;
