export const LOG_IN_ERROR = 'log-in-error';
export const LOG_OUT_ERROR = 'log-out-error';
export const CHECK_ME_IS_ACTIVATED_ERROR = 'check-me-is-activated-error';
export const GET_SUPER_ADMIN_ME_INFO_ERROR = 'get-super-admin-me-info-error';
export const MANAGER_INIT_ERROR = 'manager-init-error';
export const PAGE_AUTH_ERROR = 'page-auth-error';
export const SUPER_ADMIN_FETCH_MANAGERS_ERROR =
  'super-admin-fetch-managers-error';
export const SUPER_ADMIN_FETCH_ALL_GLOBAL_ACCT_ROLES_ERROR =
  'super-admin-fetch-all-global-acct-roles-error';
export const SUPER_ADMIN_ADD_ORG_SUCCESS = 'super-admin-add-org-success';
export const SUPER_ADMIN_ADD_ORG_ERROR = 'super-admin-add-org-error';
export const SUPER_ADMIN_FETCH_MANY_ORG_ERROR =
  'super-admin-fetch-many-org-error';
export const SUPER_ADMIN_FETCH_ONE_ORG_ERROR =
  'super-admin-fetch-one-org-error';
export const SUPER_ADMIN_EDIT_ORG_SUCCESS = 'super-admin-edit-org-success';
export const SUPER_ADMIN_EDIT_ORG_ERROR = 'super-admin-edit-org-error';
export const SUPER_ADMIN_FETCH_MANY_GLOBAL_ACCT_ROLE_ERROR =
  'super-admin-fetch-many-global-acct-role-error';
export const SUPER_ADMIN_FETCH_ALL_PERMISSION_ERROR =
  'super-admin-fetch-all-permission-error';
export const SUPER_ADMIN_ADD_GLOBAL_ACCT_ROLE_SUCCESS =
  'super-admin-add-global-acct-role-success';
export const SUPER_ADMIN_ADD_GLOBAL_ACCT_ROLE_ERROR =
  'super-admin-add-global-acct-role-error';
export const SUPER_ADMIN_EDIT_GLOBAL_ACCT_ROLE_SUCCESS =
  'super-admin-edit-global-acct-role-success';
export const SUPER_ADMIN_EDIT_GLOBAL_ACCT_ROLE_ERROR =
  'super-admin-edit-global-acct-role-error';
export const SUPER_ADMIN_FETCH_ONE_GLOBAL_ACCT_ROLE_ERROR =
  'super-admin-fetch-one-global-acct-role-error';
export const SUPER_ADMIN_PUSH_GLOBAL_ACCT_ROLE_SUCCESS =
  'super-admin-push-global-acct-role-success';
export const SUPER_ADMIN_PUSH_GLOBAL_ACCT_ROLE_ERROR =
  'super-admin-push-global-acct-role-error';
export const SUPER_ADMIN_FETCH_ONE_MANAGER_ERROR =
  'super-admin-fetch-one-manager-error';
export const SUPER_ADMIN_ADD_MGR_SUCCESS = 'super-admin-add-mgr-success';
export const SUPER_ADMIN_ADD_MGR_ERROR = 'super-admin-add-mgr-error';
export const SUPER_ADMIN_EDIT_MGR_SUCCESS = 'super-admin-edit-mgr-success';
export const SUPER_ADMIN_EDIT_MGR_ERROR = 'super-admin-edit-mgr-error';
export const MANAGER_ACTIVATE_SUCCESS = 'manager-activate-success';
export const MANAGER_ACTIVATE_ERROR = 'manager-activate-error';
export const MANAGER_ACTIVATION_KEY_NOT_FOUND_ERROR =
  'manager-activation-key-not-found-error';
export const MANAGER_FETCH_MANAGERS_ERROR = 'manager-fetch-managers-error';
export const MANAGER_FETCH_ONE_MANAGER_ERROR =
  'manager-fetch-one-manager-error';
export const MANAGER_FETCH_ONE_ORG_ERROR = 'manager-fetch-one-mgr-org-error';
export const MANAGER_EDIT_MGR_SUCCESS = 'manager-edit-mgr-success';
export const MANAGER_EDIT_MGR_ERROR = 'manager-edit-mgr-error';
export const MANAGER_UPGRADE_ORG_ACCT_ROLE_SUCCESS =
  'manager-upgrade-org-acct-role-success';
export const MANAGER_UPGRADE_ORG_ACCT_ROLE_ERROR =
  'manager-upgrade-org-acct-role-error';
export const MANAGER_FETCH_MANY_PERMISSION_BY_ORG_ERROR =
  'manager-fetch-many-permission-by-org-error';
export const MANAGER_ADD_ORG_ACCT_ROLE_SUCCESS =
  'manager-add-org-acct-role-success';
export const MANAGER_ADD_ORG_ACCT_ROLE_ERROR =
  'manager-add-org-acct-role-error';
export const MANAGER_EDIT_ORG_ACCT_ROLE_SUCCESS =
  'manager-edit-org-acct-role-success';
export const MANAGER_EDIT_ORG_ACCT_ROLE_ERROR =
  'manager-edit-org-acct-role-error';
export const MANAGER_FETCH_ONE_ORG_ACCT_ROLE_ERROR =
  'manager-fetch-one-org-acct-role-error';
export const FLEET_TRACKER_UPDATE_VEHICLE_SUCCESS =
  'fleet-tracker-vehicle-update-success';
export const FLEET_TRACKER_UPDATE_VEHICLE_ERROR =
  'fleet-tracker-vehicle-update-error';
export const FLEET_TRACKER_TRACING_ERROR = 'fleet-tracker-tracing-error';
export const FLEET_TRACKER_FETCH_ONE_VEHICLE_ERROR =
  'fleet-tracker-fetch-one-vehicle-error';
export const FLEET_TRACKER_FETCH_ONE_ORG_STATISTICS_ERROR =
  'fleet-tracker-fetch-one-org-statistics-error';
export const FLEET_TRACKER_FETCH_MANY_VEHICLE_ERROR =
  'fleet-tracker-fetch-many-vehicle-error';
export const FLEET_TRACKER_FETCH_MANY_REGION_ERROR =
  'fleet-tracker-fetch-many-region-error';
export const FLEET_TRACKER_CREATE_VEHICLE_SUCCESS =
  'fleet-tracker-vehicle-create-success';
export const FLEET_TRACKER_CREATE_VEHICLE_ERROR =
  'fleet-tracker-vehicle-create-error';
export const FLEET_TRACKER_FETCH_MANY_VEHICLE_NAME_ERROR =
  'fleet-tracker-fetch-many-vehicle-name-error';
export const FLEET_TRACKER_DOWNLOAD_EXAMPLE_CSV_ERROR =
  'fleet-tracker-download-example-csv-error';
export const SUPER_ADMIN_FETCH_ALL_ORG_ACCT_ROLE_ERROR =
  'super-admin-fetch-all-org-acct-role-error';
export const TOUR_FETCH_ONE_ORG_BILLING_REVENUES_ERROR =
  'tour-fetch-one-org-billing-revenues-error';
export const TOUR_FETCH_ONE_ORG_CONFIG_ERROR =
  'tour-fetch-one-org-config-error';
export const TOUR_FETCH_ONE_ORG_RESERVATION_DASHBOARD_ERROR =
  'tour-fetch-one-org-reservation-dashboard-error';
export const TOUR_FETCH_MANY_LOCATION_ERROR = 'tour-fetch-many-location-error';
export const TOUR_FETCH_MANY_VEHICLE_TYPE_ERROR =
  'tour-fetch-many-vehicle-type-error';
export const TOUR_FETCH_MANY_VEHICLE_ERROR = 'tour-fetch-many-vehicle-error';
export const TOUR_FETCH_MANY_VEHICLE_FOR_MAP_ERROR =
  'tour-fetch-many-vehicle-for-map-error';
export const TOUR_FETCH_MANY_RESERVATION_ERROR =
  'tour-fetch-many-reservation-error';
export const TOUR_FETCH_ONE_RESERVATION_ERROR =
  'tour-fetch-one-reservation-error';
export const TOUR_FETCH_EDIT_RESERVATION_SUCCESS =
  'tour-edit-reservation-success';
export const TOUR_FETCH_EDIT_RESERVATION_ERROR = 'tour-edit-reservation-error';
export const TOUR_EDIT_ORDER_SUCCESS = 'tour-edit-order-success';
export const TOUR_EDIT_ORDER_ERROR = 'tour-edit-order-error';
export const TOUR_ADD_RESERVATION_SUCCESS = 'tour-add-reservation-success';
export const TOUR_ADD_RESERVATION_ERROR = 'tour-add-reservation-error';
export const TOUR_FORCE_RETURN_RESERVATION_SUCCESS =
  'tour-force-return-reservation-success';
export const TOUR_FORCE_RETURN_RESERVATION_ERROR =
  'tour-force-return-reservation-error';
export const TOUR_FETCH_MANY_LOCATION_VEHICLE_COUNT_ERROR =
  'tour-fetch-many-location-vehicle-count-error';
export const TOUR_DISPATCH_MANY_VEHICLE_SUCCESS =
  'tour-dispatch-many-vehicle-success';
export const TOUR_DISPATCH_MANY_VEHICLE_ERROR =
  'tour-dispatch-many-vehicle-error';
export const TOUR_FETCH_ONE_VEHICLE_ERROR = 'tour-fetch-one-vehicle-error';
export const TOUR_FETCH_ONE_VEHICLE_REPORT_ERROR =
  'tour-fetch-one-vehicle-report-error';
export const TOUR_CONTROL_ONE_VEHICLE_SUCCESS =
  'tour-control-one-vehicle-success';
export const TOUR_CONTROL_ONE_VEHICLE_ERROR = 'tour-control-one-vehicle-error';
export const TOUR_FETCH_ONE_VEHICLE_MANY_CONTROL_LOG_ERROR =
  'tour-fetch-one-vehicle-many-control-log-error';
export const SUPER_ADMIN_ACTIVATE_SUCCESS = 'super-admin-activate-success';
export const SUPER_ADMIN_ACTIVATE_ERROR = 'super-admin-activate-error';
export const SUPER_ADMIN_ACTIVATION_KEY_NOT_FOUND_ERROR =
  'super-admin-activation-key-not-found-error';
export const MANAGER_SEND_RESET_PASSWORD_ERROR =
  'manager-send-reset-email-error';
export const SUPER_ADMIN_SEND_RESET_PASSWORD_ERROR =
  'super-admin-send-reset-email-error';
export const MANAGER_RESET_PASSWORD_SUCCESS = 'manager-reset-password-success';
export const MANAGER_RESET_PASSWORD_ERROR = 'manager-reset-password-error';
export const SUPER_ADMIN_RESET_PASSWORD_SUCCESS =
  'super-admin-reset-password-success';
export const SUPER_ADMIN_RESET_PASSWORD_ERROR =
  'super-admin-reset-password-error';
export const TOUR_FETCH_MANY_AVAILABLE_CHARGING_ITEM =
  'tour-fetch-many-available-charging-item';
export const TOUR_CANCEL_ONE_RESERVATION_SUCCESS =
  'tour-cancel-one-reservation-success';
export const TOUR_CANCEL_ONE_RESERVATION_ERROR =
  'tour-cancel-one-reservation-error';
export const REINVITE_ONE_MANAGER_SUCCESS = 'reinvite-one-manager-success';
export const REINVITE_ONE_MANAGER_ERROR = 'reinvite-one-manager-error';
export const SUPER_ADMIN_FIND_MANY_MANAGER_CLUSTER_ERROR =
  'super-admin-find-many-manager-cluster-error';
export const SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR =
  'super-admin-search-many-manager-error';
export const SUPER_ADMIN_FETCH_ONE_MANAGER_CLUSTER_ERROR =
  'super-admin-fetch-one-manager-cluster-error';
export const SUPER_ADMIN_ADD_MANAGER_CLUSTER_SUCCESS =
  'super-admin-add-manager-cluster-success';
export const SUPER_ADMIN_ADD_MANAGER_CLUSTER_ERROR =
  'super-admin-add-manager-cluster-error';
export const SUPER_ADMIN_EDIT_MANAGER_CLUSTER_SUCCESS =
  'super-admin-edit-manager-cluster-success';
export const SUPER_ADMIN_EDIT_MANAGER_CLUSTER_ERROR =
  'super-admin-edit-manager-cluster-error';
export const SUPER_ADMIN_FETCH_MANY_ORGANIZATION_CLUSTER_ERROR =
  'super-admin-fetch-many-organization-cluster-error';
export const SUPER_ADMIN_SEARCH_MANY_ORGANIZATION_ERROR =
  'super-admin-search-many-organization-error';
export const SUPER_ADMIN_FETCH_ONE_ORGANIZATION_CLUSTER_ERROR =
  'super-admin-fetch-one-organization-cluster-error';
export const SUPER_ADMIN_ADD_ORGANIZATION_CLUSTER_SUCCESS =
  'super-admin-add-organization-cluster-success';
export const SUPER_ADMIN_ADD_ORGANIZATION_CLUSTER_ERROR =
  'super-admin-add-organization-cluster-error';
export const SUPER_ADMIN_EDIT_ORGANIZATION_CLUSTER_SUCCESS =
  'super-admin-edit-organization-cluster-success';
export const SUPER_ADMIN_EDIT_ORGANIZATION_CLUSTER_ERROR =
  'super-admin-edit-organization-cluster-error';
export const SUPER_ADMIN_FETCH_ONE_ROLES_PERMISSIONS_ERROR =
  'super-admin-fetch-one-roles-permissions-error';
export const SUPER_ADMIN_CREATE_ONE_MANAGER_SUCCESS =
  'super-admin-create-one-manager-success';
export const SUPER_ADMIN_CREATE_ONE_MANAGER_ERROR =
  'super-admin-create-one-manager-error';
export const SUPER_ADMIN_FIND_ONE_MANAGER_ERROR =
  'super-admin-find-one-manager-error';
export const SUPER_ADMIN_UPDATE_ONE_MANAGER_SUCCESS =
  'super-admin-update-one-manager-success';
export const SUPER_ADMIN_UPDATE_ONE_MANAGER_ERROR =
  'super-admin-update-one-manager-error';
export const SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_SUCCESS =
  'super-admin-update-one-manager-status-success';
export const SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_ERROR =
  'super-admin-update-one-manager-status-error';
export const SUPER_ADMIN_UPDATE_MANY_MANAGER_AUTHZ_SUCCESS =
  'super-admin-update-many-manager-authz-success';
export const SUPER_ADMIN_UPDATE_MANY_MANAGER_AUTHZ_ERROR =
  'super-admin-update-many-manager-authz-error';
export const SUPER_ADMIN_FETCH_MANY_MANAGER_AUTHZS_ERROR =
  'super-admin-fetch-many-manager-authzs-error';
