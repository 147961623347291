import { subscribeWithSelector } from 'zustand/middleware';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { useShallow } from 'zustand/react/shallow';

import { AuthIdentity } from '@enums/auth-identity.enum';

export interface TokenState {
  manager: { accessToken?: string; refreshToken?: string };
  superAdmin: { accessToken?: string; refreshToken?: string };
}

export interface TokenAction {
  setToken(
    identity: AuthIdentity,
    accessToken: string,
    refreshToken: string,
  ): void;
  clearToken(identity: AuthIdentity): void;
}

const MANAGER_ACCESS_TOKEN_KEY = 'NEXUS_MANAGER_ACCESS_TOKEN';
const MANAGER_REFRESH_TOKEN_KEY = 'NEXUS_MANAGER_REFRESH_TOKEN';
const SUPER_ADMIN_ACCESS_TOKEN_KEY = 'NEXUS_SUPER_ADMIN_ACCESS_TOKEN';
const SUPER_ADMIN_REFRESH_TOKEN_KEY = 'NEXUS_SUPER_ADMIN_REFRESH_TOKEN';

const getDefaultTokenState = (): TokenState => {
  const state = {
    manager: {
      accessToken: localStorage.getItem(MANAGER_ACCESS_TOKEN_KEY) || undefined,
      refreshToken:
        localStorage.getItem(MANAGER_REFRESH_TOKEN_KEY) || undefined,
    },
    superAdmin: {
      accessToken:
        localStorage.getItem(SUPER_ADMIN_ACCESS_TOKEN_KEY) || undefined,
      refreshToken:
        localStorage.getItem(SUPER_ADMIN_REFRESH_TOKEN_KEY) || undefined,
    },
  };

  return state;
};

export const tokenStore = createStore(
  subscribeWithSelector<TokenState & TokenAction>((set) => ({
    ...getDefaultTokenState(),
    setToken(identity, accessToken, refreshToken) {
      if (identity === AuthIdentity.MANAGER) {
        set((state) => ({
          ...state,
          manager: {
            accessToken,
            refreshToken,
          },
        }));
        localStorage.setItem(MANAGER_ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(MANAGER_REFRESH_TOKEN_KEY, refreshToken);
      } else if (identity === AuthIdentity.SUPER_ADMIN) {
        set((state) => ({
          ...state,
          superAdmin: {
            accessToken,
            refreshToken,
          },
        }));
        localStorage.setItem(SUPER_ADMIN_ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(SUPER_ADMIN_REFRESH_TOKEN_KEY, refreshToken);
      }
    },
    clearToken(identity) {
      if (identity === AuthIdentity.MANAGER) {
        set((state) => ({
          ...state,
          manager: {
            accessToken: undefined,
            refreshToken: undefined,
          },
        }));
        localStorage.removeItem(MANAGER_ACCESS_TOKEN_KEY);
        localStorage.removeItem(MANAGER_REFRESH_TOKEN_KEY);
      } else if (identity === AuthIdentity.SUPER_ADMIN) {
        set((state) => ({
          ...state,
          superAdmin: {
            accessToken: undefined,
            refreshToken: undefined,
          },
        }));
        localStorage.removeItem(SUPER_ADMIN_ACCESS_TOKEN_KEY);
        localStorage.removeItem(SUPER_ADMIN_REFRESH_TOKEN_KEY);
      }
    },
  })),
);

export const useTokenStore = <T>(
  selector: (state: TokenState & TokenAction) => T,
) => useStore(tokenStore, useShallow(selector));
