import styled from 'styled-components';
import { Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

export const GlobalButton = styled(Button).attrs({
  type: 'text',
  icon: <GlobalOutlined />,
})`
  padding: 0;

  > .ant-btn-icon > .anticon {
    font-size: 20px;
  }
`;
