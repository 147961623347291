import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { isEmpty, isNil, isNotNil, not } from 'ramda';
import z from 'zod';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import { formValidateFields } from '@utils/antd-form.util';
import { ManagerType, ManagerEditMode } from '@enums/managers.enum';
import useLoading from '@hooks/useLoading';
import { updateOne_SuperAdmin } from '@apis/managers';
import { FormValues } from '../interfaces';

interface UseUpdateOneOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const authzSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal(ManagerEditMode.MANUAL),
    items: z
      .object({
        add: z
          .object({
            orgId: z.string(),
            roleIds: z.string().array().nullable(),
          })
          .array()
          .nullable(),
        remove: z
          .object({
            orgId: z.string(),
            roleIds: z.string().array().nullable(),
          })
          .array()
          .nullable(),
      })
      .nullish()
      .transform((value) => (isNil(value) || isEmpty(value) ? null : value)),
  }),
  z.object({
    mode: z.literal(ManagerEditMode.SYNC),
    syncId: z.string(),
  }),
]);

const boilerplateSchema = z.object({
  type: z.literal(ManagerType.BOILERPLATE),
  name: z.string(),
  authz: authzSchema.nullish(),
});

const realitySchema = z.object({
  type: z.literal(ManagerType.REALITY),
  name: z.string(),
  email: z.string(),
  phone: z.string().nullish(),
  authz: authzSchema.nullish(),
});

const schema = z.discriminatedUnion('type', [boilerplateSchema, realitySchema]);

const useUpdateOne = (options: UseUpdateOneOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const { t } = useTranslation(COMMON);
  const loading = useLoading();

  const submit = async (id: string, form: FormInstance<FormValues>) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, error] = await formValidateFields(form);
        if (!isNil(error) || isNil(data)) return;
        await updateOne_SuperAdmin(id, schema.parse(data));
        onSuccess?.(t('common:success-message', { action: t('common:added') }));
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useUpdateOne;
