import { useState } from 'react';

import { Organizations } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { SearchScope } from '@enums/organizations.enum';
import { searchMany_SuperAdmin } from '@apis/organizations';

interface UseSearchManyManagerOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

export interface SearchData
  extends Omit<Organizations.SearchManyResp_SuperAdmin, 'type'> {
  value: Organizations.SearchManyResp_SuperAdmin['id'];
}

const useSearchManyOrg = (props: UseSearchManyManagerOptions = {}) => {
  const { onError, throwable } = props;
  const loading = useLoading();
  const [data, setData] = useState<SearchData[]>([]);

  const fetch = async (
    keyword: Organizations.SearchManyQuery_SuperAdmin['keyword'],
    excludeId?: Organizations.SearchManyResp_SuperAdmin['id'][],
  ) => {
    return asyncLoadingWrapper(
      loading,
      async () => {
        const [data] = await searchMany_SuperAdmin({
          keyword,
          scope: SearchScope.ORGANIZATION,
          exclude: {
            org: excludeId,
          },
          page: 1,
          size: 10,
        });
        setData(
          data.map(({ type, ...manager }) => ({
            ...manager,
            value: manager.id,
          })),
        );
        return data;
      },
      onError,
      throwable,
    );
  };

  const clear = () => {
    setData([]);
  };

  return {
    loading: loading.loading,
    fetch,
    clear,
    data,
  };
};

export default useSearchManyOrg;
