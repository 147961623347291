import { isAxiosError } from 'axios';
import { isNil, isNotNil } from 'ramda';

import { NexusAxiosError } from '@interfaces/nexus-axios.interface';
import { NexusTokenNeedToRefreshErrors, ForceLogoutError } from './variables';

export const isNexusAxiosError = (error: any): error is NexusAxiosError => {
  if (isAxiosError(error)) {
    if (isNil(error.response)) return false;
    else return 'error' in error.response.data;
  } else return false;
};

export const isTokenNeedToRefreshNexusAxiosError = (error: NexusAxiosError) => {
  const errorType = error?.response?.data?.error;
  return (
    isNotNil(errorType) && NexusTokenNeedToRefreshErrors.includes(errorType)
  );
};

export const isForceLogoutNexusAxiosError = (error: NexusAxiosError) => {
  const errorType = error?.response?.data?.error;
  return isNotNil(errorType) && ForceLogoutError.includes(errorType);
};
