import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { reinviteOne } from '@apis/managers';

interface UseReinviteOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useReinvite = (options: UseReinviteOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const { t } = useTranslation(COMMON);
  const loading = useLoading();

  const submit = async (mgrId: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        await reinviteOne(mgrId);
        onSuccess?.(
          t('common:success-message', { action: t('common:reinvited') }),
        );
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useReinvite;
