import { useState } from 'react';
import { isNotNil } from 'ramda';

import { asyncLoadingWrapper } from '@utils/api.util';
import { postSuperAdminLogin } from '@apis/auth';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';

interface UseLoginOptions {
  onError?: (error: unknown) => void;
}

const useLogin = (options: UseLoginOptions = {}) => {
  const { onError } = options;
  const navigate = useEnhancedNavigate();
  const [loading, setLoading] = useState(false);

  const submit = async (
    data: { username: string; password: string },
    redirectTo?: string,
  ) => {
    const { username, password } = data;
    await asyncLoadingWrapper(
      setLoading,
      async () => {
        await postSuperAdminLogin(username, password);
        const url =
          isNotNil(redirectTo) && redirectTo !== '/super-admins/log-in'
            ? redirectTo
            : '/super-admins/organizations';
        navigate(url, {
          replace: true,
        });
      },
      onError,
    );
  };

  return { loading, submit };
};

export default useLogin;
