import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { Location } from 'react-router-dom';

export interface HistoryState extends Partial<Location> {}

export interface HistoryAction {
  set(state: HistoryState): void;
}

const DEFAULT_STATE: HistoryState = {
  pathname: undefined,
  search: undefined,
  hash: undefined,
  state: undefined,
  key: undefined,
};

export const useHistoryStore = create(
  subscribeWithSelector<HistoryState & HistoryAction>((set, get) => ({
    ...DEFAULT_STATE,
    set(next: Location) {
      set((prev) => ({ ...prev, ...next }));
    },
    reset() {
      set({ ...DEFAULT_STATE });
    },
  })),
);
