import { ChangeEventHandler, Fragment } from 'react';
import { Input, Progress, theme } from 'antd';

import useBeforeMounted from '@/hooks/useBeforeMounted';
import { StrengthPasswordProps } from './interfaces';
import { useState } from 'react';
import { isEmpty, isNil } from 'ramda';

const { Password } = Input;
const { useToken } = theme;

const StrengthPassword = (props: StrengthPasswordProps = {}) => {
  const { level = 0, value, onChange, ...inputProps } = props;
  const { token } = useToken();
  const [extValue, setExtValue] = useState(value);
  const nonValue = isEmpty(extValue) || isNil(extValue);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setExtValue(event.target.value);
    onChange?.(event);
  };

  useBeforeMounted(() => {
    if (value !== extValue) setExtValue(value);
  }, [value]);

  return (
    <Fragment>
      <Password {...inputProps} value={value} onChange={handleOnChange} />
      <Progress
        percent={nonValue ? 0 : (level + 1) * 25}
        steps={4}
        strokeColor={[
          token.colorError,
          token.colorError,
          token.colorWarning,
          token.colorSuccess,
        ]}
        showInfo={false}
        size={[72, 8]}
      />
    </Fragment>
  );
};

export default StrengthPassword;
