import { useRef } from 'react';

const useAxiosCancelable = () => {
  const controller = useRef<AbortController>();

  const create = () => {
    controller.current = new AbortController();
  };

  const cancel = () => {
    controller.current?.abort();
  };

  const clear = () => {
    controller.current = undefined;
  };

  const get = () => {
    return controller.current?.signal;
  };

  return { create, cancel, clear, get };
};

export default useAxiosCancelable;
