import { useMemo } from 'react';
import { Row, Col, Space, Button, Table, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useShallow } from 'zustand/react/shallow';
import { isEmpty } from 'ramda';

import {
  SUPER_ADMIN_FETCH_MANAGERS_ERROR,
  REINVITE_ONE_MANAGER_SUCCESS,
  REINVITE_ONE_MANAGER_ERROR,
  SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_SUCCESS,
  SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import ReloadButton from '@components/ReloadButton';
import FilterButton from '@components/FilterButton';
import TooltipButton from '@components/TooltipButton';
import FluidSpace from '@components/FluidSpace';
import { pickState } from '@stores';
import { useAuthzEditorListStore } from '../stores/authz-editor-list.store';
import Filter from './components/Filter';
import useManagers from './hooks/useManagers';
import useReinvite from './hooks/useReinvite';
import useUpdateOneStatus from './hooks/useUpdateOneStatus';
import { createMainTableColumns } from './utils';
import { MainTableRecordType } from './interfaces';

const SuperAdminManager = () => {
  const authzEditorList = useAuthzEditorListStore(
    useShallow((state) => pickState(['list', 'set', 'reset'], state)),
  );
  const navigate = useEnhancedNavigate();
  const { successNotify, errorNotify } = useGlobalNotification();
  const managers = useManagers({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANAGERS_ERROR),
  });
  const reinvite = useReinvite({
    onSuccess: successNotify(REINVITE_ONE_MANAGER_SUCCESS),
    onError: errorNotify(REINVITE_ONE_MANAGER_ERROR),
  });
  const updateOneStatus = useUpdateOneStatus({
    onSuccess: successNotify(SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_UPDATE_ONE_MANAGER_STATUS_ERROR),
  });

  const handleAddClick = () => {
    navigate('editor');
  };

  const handleClusterClick = () => {
    navigate('clusters');
  };

  const handleEditAuthzClick = () => {
    navigate('authz/editor');
  };

  const handleReinviteClick = async (mgrId: string) => {
    await reinvite.submit(mgrId);
    await managers.reload();
  };

  const handleStatusChange = async (mgrId: string, activated: boolean) => {
    const result = await updateOneStatus.submit(mgrId, { activated });
    if (result) await managers.reload();
  };

  const mainTableColumns = useMemo(
    () =>
      createMainTableColumns({
        onReinviteClick: handleReinviteClick,
        onStatusChange: handleStatusChange,
      }),
    [],
  );

  return (
    <Spin
      spinning={managers.loading || reinvite.loading || updateOneStatus.loading}
    >
      <Filter
        value={managers.filterValue}
        open={managers.isOpen}
        onSearch={managers.fetch}
        onReset={managers.reset}
        onClose={managers.close}
      />
      <FluidSpace direction="vertical" size="large">
        <Row justify="space-between">
          <Col>
            <Space>
              <FilterButton onClick={managers.open} />
              <ReloadButton onClick={managers.reload} />
              <Button onClick={handleClusterClick}>Clusters</Button>
            </Space>
          </Col>
          <Col>
            <Space>
              <TooltipButton
                badge={{ count: authzEditorList.list.length }}
                dropdown={{
                  menu: {
                    items: [
                      {
                        key: '1',
                        label: 'Remove All',
                        disabled: isEmpty(authzEditorList.list),
                        onClick: authzEditorList.reset,
                      },
                    ],
                  },
                }}
                onClick={handleEditAuthzClick}
              >
                Edit Authz
              </TooltipButton>
              <Button icon={<PlusOutlined />} onClick={handleAddClick}>
                Add Manager
              </Button>
            </Space>
          </Col>
        </Row>
        <Table<MainTableRecordType>
          rowKey="id"
          scroll={{ x: 'max-content' }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: authzEditorList.list.map(({ id }) => id),
            onChange(_, selectedRows) {
              authzEditorList.set(
                selectedRows.map(({ id, name }) => ({ id, name })),
              );
            },
          }}
          columns={mainTableColumns}
          dataSource={managers.data}
          pagination={{
            current: managers.pagination.page,
            pageSize: managers.pagination.size,
            total: managers.pagination.total,
            onChange: managers.changePage,
          }}
        />
      </FluidSpace>
    </Spin>
  );
};

export default SuperAdminManager;
