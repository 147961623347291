import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { createOne_SuperAdmin } from '@apis/manager-clusters';
import { ManagerClusters } from '@interfaces/apis';

interface UseCreateOneOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useCreateOne = (options: UseCreateOneOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const loading = useLoading();
  const { t } = useTranslation(COMMON);

  const submit = async (data: ManagerClusters.CreateOneBody_SuperAdmin) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const id = await createOne_SuperAdmin(data);
        onSuccess?.(
          t('common:success-message', 'Successfully created.', {
            action: t('common:added'),
          }),
        );
        return id;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useCreateOne;
