import {
  SUPER_ADMIN_RESET_PASSWORD_SUCCESS,
  SUPER_ADMIN_RESET_PASSWORD_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import ResetPassword from '@templates/ResetPassword';
import useResetPassword from './hooks/useResetPassword';

const SuperAdminResetPassword = () => {
  const { errorNotify, successNotify } = useGlobalNotification();
  const resetPassword = useResetPassword({
    onSuccess: successNotify(SUPER_ADMIN_RESET_PASSWORD_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_RESET_PASSWORD_ERROR),
  });

  return (
    <ResetPassword
      loading={resetPassword.loading}
      onReset={({ password }) => resetPassword.submit(password)}
    />
  );
};

export default SuperAdminResetPassword;
