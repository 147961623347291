import { isNil } from 'ramda';

import {
  NexusRouteObject,
  NexusFlatRouteObject,
  NexusMenuItemType,
  NexusSubMenuType,
  NexusMenuItemGroupType,
  NexusMenuDividerType,
} from '@interfaces/routes.interface';

export const getMenuItemGroupParentPath = (key: `G_${string}`) => {
  return key.replace('G_', '');
};

export const isMenuItem = (
  route: NexusRouteObject,
): route is NexusMenuItemType => {
  if (!('path' in route) || 'children' in route) return false;
  return true;
};

export const isMenuItemWithInvisibleChildren = (
  route: NexusRouteObject,
): route is NexusMenuItemType => {
  if (isMenuItem(route)) return true;
  if (
    'children' in route &&
    Array.isArray(route.children) &&
    route.children.some((child) => !child.excludeMenuItem)
  )
    return false;
  return true;
};

export const isSubMenu = (
  route: NexusRouteObject,
): route is NexusSubMenuType => {
  if (!('path' in route) || !('children' in route)) return false;
  return true;
};

export const isMenuItemGroup = (
  route: NexusRouteObject,
): route is NexusMenuItemGroupType => {
  if (
    'path' in route ||
    !('children' in route) ||
    !('type' in route) ||
    route.type !== 'group'
  )
    return false;
  return true;
};

export const isMenuDivider = (
  route: NexusRouteObject,
): route is NexusMenuDividerType => {
  if (
    'path' in route ||
    'children' in route ||
    !('type' in route) ||
    route.type !== 'divider'
  )
    return false;
  return true;
};

const getRouteParent = (
  parentPath: string | undefined,
  grandparents: string[] | undefined,
) => {
  if (isNil(parentPath)) {
    if (isNil(grandparents)) return [];
    else return [...grandparents];
  } else {
    if (isNil(grandparents)) return [parentPath];
    else return [parentPath, ...grandparents];
  }
};

const formatChildrenByParent = (
  parent: { path?: string; parent?: string[] },
  children?: NexusRouteObject[],
) =>
  (children || []).map((child) => {
    if (!('path' in child)) return child;
    return {
      ...child,
      path: isNil(parent.path) ? child.path : `${parent.path}/${child.path}`,
      parent: getRouteParent(parent.path, parent.parent),
    };
  });

export const excludeNoPathAndFlatRoute = (routes: NexusFlatRouteObject[]) => {
  return routes.reduce((result, route) => {
    if (isMenuItem(route)) {
      result = [...result, route];
    } else if (isMenuItemWithInvisibleChildren(route) || isSubMenu(route)) {
      const { children, ...withoutChildrenRoute } = route;
      result = [
        ...result,
        withoutChildrenRoute as NexusFlatRouteObject,
        ...excludeNoPathAndFlatRoute(
          formatChildrenByParent(
            { path: route.path, parent: route.parent },
            children,
          ),
        ),
      ];
    } else if (isMenuItemGroup(route) && Array.isArray(route.children)) {
      const { children, key } = route;
      const path = getMenuItemGroupParentPath(key);
      result = [
        ...result,
        ...excludeNoPathAndFlatRoute(
          formatChildrenByParent(
            { path: path, parent: route.parent },
            children,
          ),
        ),
      ];
    }

    return result;
  }, [] as NexusFlatRouteObject[]);
};
