import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { COMMON } from '@i18n/namespaces';
import TooltipButton from '@components/TooltipButton';

export interface GoBackHeaderProps {
  children?: ReactNode;

  onGoBack?: () => void;
}

const GoBackHeader = (props: GoBackHeaderProps) => {
  const { children, onGoBack } = props;
  const { t } = useTranslation(COMMON);

  return (
    <Fragment>
      <Flex wrap={false} gap={8}>
        <TooltipButton
          title={t('common:go-back', 'Go Back')}
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={onGoBack}
        />

        {children}
      </Flex>
    </Fragment>
  );
};

export default GoBackHeader;
