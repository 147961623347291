import { ButtonProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { ContainerButton } from './styleds';

interface CloseButtonProps extends Omit<ButtonProps, 'type' | 'icon'> {}

const CloseButton = (props: CloseButtonProps = {}) => {
  return <ContainerButton {...props} icon={<CloseOutlined />} />;
};

export default CloseButton;
