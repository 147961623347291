import z from 'zod';

import { ManagerEditMode } from '@enums/managers.enum';

const ManualAuthzFormValues = z
  .object({
    mode: z.literal(ManagerEditMode.MANUAL),
    items: z
      .object({
        add: z
          .array(
            z.object({
              orgId: z.string(),
              roleIds: z.array(z.string()).nullable(),
            }),
          )
          .nullable(),
        remove: z
          .array(
            z.object({
              orgId: z.string(),
              roleIds: z.array(z.string()).nullable(),
            }),
          )
          .nullable(),
      })
      .transform(({ add, remove }) => ({
        addAuthzs: add,
        removeAuthzs: remove,
      })),
  })
  .transform(({ mode, items }) => ({
    mode,
    ...items,
  }));

const SyncAuthzFormValues = z.object({
  mode: z.literal(ManagerEditMode.SYNC),
  syncId: z.string(),
});

export const FormDataSchema = z
  .object({
    members: z.array(
      z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .transform(({ id }) => id),
    ),
    authz: z.union([ManualAuthzFormValues, SyncAuthzFormValues]),
  })
  .transform(({ members, authz }) => ({
    ids: members,
    clusterIds: [],
    ...authz,
  }));
