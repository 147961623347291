import { useState } from 'react';

import { Organizations } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { findOneRolesPermissions_SuperAdmin } from '@apis/organizations';

interface UseRolesPermissionsOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useRolesPermissions = (props: UseRolesPermissionsOptions = {}) => {
  const { onError, throwable } = props;
  const loading = useLoading();
  const [data, setData] = useState<
    Organizations.FindOneRolesPermissionsResp_SuperAdmin[]
  >([]);

  const fetch = async (id: string) => {
    return asyncLoadingWrapper(
      loading,
      async () => {
        const [data] = await findOneRolesPermissions_SuperAdmin(id);
        setData(data);
        return data;
      },
      onError,
      throwable,
    );
  };

  const clear = () => {
    setData([]);
  };

  return {
    loading: loading.loading,
    fetch,
    clear,
    data,
  };
};

export default useRolesPermissions;
