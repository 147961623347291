import { Fragment, useState } from 'react';
import { isNil } from 'ramda';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  Drawer,
  Checkbox,
  Skeleton,
  Button,
  Space,
  Empty,
  GetProp,
} from 'antd';

import FluidSpace from '@components/FluidSpace';
import { PushDrawerProps } from './interfaces';

type CheckboxValueType = GetProp<typeof Checkbox, 'value'>;

const { Group: CheckboxGroup } = Checkbox;

const PushDrawer = (props: PushDrawerProps = {}) => {
  const { open, title, loading, options = [], onClose, onSave } = props;
  const [value, setValue] = useState<string[]>();
  const [indeterminate, setIndeterminate] = useState(
    isNil(value) ? false : value.length < options.length,
  );
  const [checkAll, setCheckAll] = useState(
    isNil(value) ? false : value.length === options.length,
  );

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    setValue(
      e.target.checked ? options.map((option) => option.orgAcctRoleId) : [],
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleCheckboxChange = (list: CheckboxValueType[]) => {
    setValue(list as string[]);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const handleSave = async () => {
    onSave?.(value);
  };

  return (
    <Drawer
      width={500}
      title={title}
      open={open}
      onClose={onClose}
      footer={
        <Space>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button type="text" onClick={onClose}>
            Cancel
          </Button>
        </Space>
      }
    >
      <Skeleton loading={loading} paragraph={{ rows: 6 }} active>
        <FluidSpace direction="vertical">
          {options.length === 0 ? (
            <Empty />
          ) : (
            <Fragment>
              <Checkbox
                indeterminate={indeterminate}
                onChange={handleCheckAllChange}
                checked={checkAll}
              >
                All
              </Checkbox>
              <CheckboxGroup value={value} onChange={handleCheckboxChange}>
                <Space direction="vertical">
                  {options.map((option) => (
                    <Checkbox
                      key={option.orgAcctRoleId}
                      value={option.orgAcctRoleId}
                    >
                      {option.name}
                    </Checkbox>
                  ))}
                </Space>
              </CheckboxGroup>
            </Fragment>
          )}
        </FluidSpace>
      </Skeleton>
    </Drawer>
  );
};

export default PushDrawer;
