import { useState } from 'react';
import { isNil } from 'ramda';

import { asyncLoadingWrapper } from '@utils/api.util';
import { getSuperAdminMeIsActivated } from '@apis/auth';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';

interface UseMeIsActivated {
  defaultLoading?: boolean;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useMeIsActivated = (options: UseMeIsActivated = {}) => {
  const { defaultLoading = false, onError, throwable } = options;
  const [loading, setLoading] = useState(defaultLoading);
  const navigate = useEnhancedNavigate();

  const check = async (
    options: { to?: string; replace?: boolean; throwable?: true } = {},
  ) => {
    await asyncLoadingWrapper(
      setLoading,
      async () => {
        await getSuperAdminMeIsActivated();
        if (!isNil(options.to))
          navigate(options.to, { replace: options.replace });
      },
      (error) => {
        onError?.(error);
        navigate('/super-admins/log-in', { replace: true });
      },
      !isNil(options.throwable) ? options.throwable : throwable,
    );
  };

  return { loading, check };
};

export default useMeIsActivated;
