import { theme, ConfigProvider, Skeleton } from 'antd';
import styled from 'styled-components';

const StyledSkeleton = styled(Skeleton.Input)`
  > .ant-skeleton-input {
    margin: 4px 0;
  }
`;

const MenuItemSkeleton = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <StyledSkeleton block active />
    </ConfigProvider>
  );
};

export default MenuItemSkeleton;
