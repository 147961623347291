import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';

import { COMMON, PAGES_COMMON_ERROR_BOUNDARY } from '@i18n/namespaces';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import { getErrorWording } from './utils';

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useEnhancedNavigate();
  const { t } = useTranslation([COMMON, PAGES_COMMON_ERROR_BOUNDARY]);
  const { title, subTitle } = getErrorWording(t, error);

  const handleOnGoBack = () => {
    navigate(-1);
  };

  return (
    <Result
      status="error"
      title={title}
      subTitle={subTitle}
      extra={
        <Button type="primary" onClick={handleOnGoBack}>
          {t('common:go-back', 'Go Back')}
        </Button>
      }
    ></Result>
  );
};

export default ErrorBoundary;
