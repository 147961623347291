import { Skeleton, SkeletonProps } from 'antd';
import styled from 'styled-components';

const StyledSkeleton = styled(Skeleton)`
  display: inline-flex;
  width: 200px;
  align-items: center;
  height: 28px;

  > .ant-skeleton-content {
    > .ant-skeleton-title {
      margin: 0;
    }
  }
`;

const TitleSkeleton = (props: Pick<SkeletonProps, 'children' | 'loading'>) => {
  const { children, loading = true } = props;
  return (
    <StyledSkeleton paragraph={false} active loading={loading}>
      {children}
    </StyledSkeleton>
  );
};

export default TitleSkeleton;
