import { useState } from 'react';

import { FindGlobalAccountRolesResource } from '@enums/global-account-roles.enum';
import { GlobalAccountRoles } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getOneGlobalAcctRole } from '@apis/global-account-roles';

interface UseOneGlobalAcctRoleOptions {
  defaultLoading?: boolean;
  onError?: (error: unknown) => void;
  throwable?: true;
}

export type DataType =
  GlobalAccountRoles.FindGlobalAcctRoleReply<FindGlobalAccountRolesResource.PERMISSIONS>;

const useOneGlobalAcctRole = (options: UseOneGlobalAcctRoleOptions = {}) => {
  const { defaultLoading = false, onError, throwable } = options;
  const loading = useLoading({ defaultLoading });
  const [data, setData] = useState<DataType>();

  const fetch = async (globalAcctRoleId: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const data = await getOneGlobalAcctRole(globalAcctRoleId, {
          resource: FindGlobalAccountRolesResource.PERMISSIONS,
        });
        setData(data);
        return data;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, fetch, data };
};

export default useOneGlobalAcctRole;
