import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON } from '@i18n/namespaces';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import {
  updateOneProfile_SuperAdmin,
  updateOneMembers_SuperAdmin,
} from '@apis/organization-clusters';
import { OrganizationClusters } from '@interfaces/apis';
import { isNotNil, isNil } from 'ramda';

interface UseUpdateOneOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useUpdateOne = (options: UseUpdateOneOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const loading = useLoading();
  const { t } = useTranslation(COMMON);

  const submit = async (
    id: string,
    data: {
      profile?: OrganizationClusters.UpdateOneProfileBody_SuperAdmin;
      members?: OrganizationClusters.UpdateOneMembersBody_SuperAdmin;
    },
  ) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const { profile, members } = data;
        if (isNil(profile) && isNil(members)) return;
        if (isNotNil(profile)) await updateOneProfile_SuperAdmin(id, profile);
        if (isNotNil(members)) await updateOneMembers_SuperAdmin(id, members);
        onSuccess?.(
          t('common:success-message', 'Successfully updated.', {
            action: t('common:updated'),
          }),
        );
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useUpdateOne;
