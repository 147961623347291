import { useState } from 'react';

import { Managers } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import {
  findOneProfile_SuperAdmin,
  findOneAuthzs_SuperAdmin,
} from '@apis/managers';

interface UseFindOneOptions {
  defaultLoading?: boolean;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useFindOne = (options: UseFindOneOptions = {}) => {
  const { defaultLoading = false, onError, throwable } = options;
  const loading = useLoading({ defaultLoading });
  const [profile, setProfile] =
    useState<Managers.FindOneProfileResp_SuperAdmin>();
  const [authzs, setAuthzs] =
    useState<Managers.FindOneAuthzsResp_SuperAdmin[]>();

  const fetch = async (id: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        const [profile, [authzs]] = await Promise.all([
          findOneProfile_SuperAdmin(id),
          findOneAuthzs_SuperAdmin(id, { all: true }),
        ]);
        setProfile(profile);
        setAuthzs(authzs);
        return {
          ...profile,
          authzs,
        };
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return {
    loading: loading.loading,
    fetch,
    data: {
      ...profile,
      authzs,
    },
  };
};

export default useFindOne;
