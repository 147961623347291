import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  RocketOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Spin, Typography } from 'antd';
import {
  Options,
  defaultOptions,
  passwordStrength,
} from 'check-password-strength';
import { ValidatorRule } from 'rc-field-form/lib/interface';

import { TEMPLATES_INVITATION } from '@i18n/namespaces';
import { StrengthLevel } from '@enums/password.enum';
import CenterLayout from '@layouts/CenterLayout';
import FluidSpace from '@components/FluidSpace';
import StrengthPassword from '@components/StrengthPassword';
import LocaleButton from '@components/LocaleButton';
import { FormValues, InvitationProps } from './interfaces';
import {
  Container,
  HeadSpace,
  InfoList,
  NexusSubTitle,
  NexusTitle,
  RtlSpace,
  Section,
} from './styleds';

const { Text } = Typography;
const { Item: FormItem, useForm } = Form;
const { Password } = Input;

const ValidPasswordLevel = [StrengthLevel.MEDIUM, StrengthLevel.STRONG];

const PasswordStrengthOptions: Options<string> = [
  defaultOptions[0],
  defaultOptions[1],
  {
    ...defaultOptions[2],
    minLength: 12,
  },
  { ...defaultOptions[3], minLength: 16 },
];

const Invitation = (props: InvitationProps = {}) => {
  const { loading, onSubmit } = props;
  const { t } = useTranslation(TEMPLATES_INVITATION);
  const [form] = useForm<FormValues>();
  const [strengthLevel, setStrengthLevel] = useState(0);
  const [showPwdLengthInfo, setShowPwdLengthInfo] = useState(true);
  const [showPwdDiversityInfo, setShowPwdDiversityInfo] = useState(true);
  const showInfoAlert = showPwdLengthInfo || showPwdDiversityInfo;

  const passwordStrengthValidator: ValidatorRule['validator'] = (
    rule,
    password: string,
  ) => {
    const {
      id: strengthLevel,
      contains,
      length,
    } = passwordStrength(password, PasswordStrengthOptions);
    setStrengthLevel(strengthLevel);
    setShowPwdLengthInfo(length < 12);
    setShowPwdDiversityInfo(contains.length < 4);
    if (ValidPasswordLevel.includes(strengthLevel)) return Promise.resolve();
    return Promise.reject('');
  };

  const confirmPasswordValidator: ValidatorRule['validator'] = (
    rule,
    value: string,
  ) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(t('components.templates.invitation:match-password')),
    );
  };

  return (
    <CenterLayout>
      <Spin spinning={loading}>
        <Container direction="vertical">
          <Section>
            <Row justify="center">
              <Col>
                <HeadSpace direction="vertical">
                  <NexusTitle>WeMo Nexus</NexusTitle>
                  <RtlSpace align="baseline" size={5}>
                    <Text type="secondary">for</Text>
                    <NexusSubTitle level={4} type="secondary">
                      {t('components.templates.invitation:activate-account')}
                    </NexusSubTitle>
                  </RtlSpace>
                </HeadSpace>
              </Col>
            </Row>

            <Form<FormValues> form={form} onFinish={onSubmit}>
              <FormItem
                name="username"
                rules={[
                  {
                    required: true,
                    message: t(
                      'components.templates.invitation:required-username',
                    ),
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder={t('components.templates.invitation:username')}
                />
              </FormItem>
              <FormItem
                name="password"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: t(
                      'components.templates.invitation:required-password',
                    ),
                  },
                  {
                    validator: passwordStrengthValidator,
                  },
                ]}
              >
                <StrengthPassword
                  size="large"
                  prefix={<LockOutlined />}
                  placeholder={t('components.templates.invitation:password')}
                  level={strengthLevel}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </FormItem>
              <FormItem
                name="checked"
                validateFirst
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t(
                      'components.templates.invitation:required-confirm-password',
                    ),
                  },
                  { validator: confirmPasswordValidator },
                ]}
              >
                <Password
                  size="large"
                  prefix={<LockOutlined />}
                  placeholder={t(
                    'components.templates.invitation:confirm-password',
                  )}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </FormItem>
              <FormItem noStyle>
                <FluidSpace direction="vertical" size="large">
                  {showInfoAlert && (
                    <Alert
                      type="info"
                      message={
                        <Text>
                          <InfoList>
                            {showPwdLengthInfo && (
                              <li>
                                {t(
                                  'components.templates.invitation:password-length-info',
                                )}
                              </li>
                            )}
                            {showPwdDiversityInfo && (
                              <li>
                                {t(
                                  'components.templates.invitation:password-diversity-info',
                                )}
                              </li>
                            )}
                          </InfoList>
                        </Text>
                      }
                    />
                  )}
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    block
                    icon={<RocketOutlined />}
                  >
                    {t('components.templates.invitation:join-us')}
                  </Button>
                </FluidSpace>
              </FormItem>
            </Form>
          </Section>
        </Container>
      </Spin>
      <LocaleButton float />
    </CenterLayout>
  );
};

export default Invitation;
export type { InvitationProps };
