export class RefreshTokenNotFound extends Error {
  constructor() {
    super('REFRESH_TOKEN_NOT_FOUND');
  }
}

export class RequestConfigNotFound extends Error {
  constructor() {
    super('REQUEST_CONFIG_NOT_FOUND');
  }
}

export class RequestRetryUrlNotFound extends Error {
  constructor() {
    super('REQUEST_RETRY_URL_NOT_FOUND');
  }
}

export class BaseUrlNotFound extends Error {
  constructor() {
    super('BASE_URL_NOT_FOUND');
  }
}
