export const COMPONENTS_LOCALE_BUTTON = 'components.locale-button' as const;

export const COMPONENTS_TABLE_COLUMN_SETTING =
  'components.table-column-setting' as const;

export const COMPONENTS_PHONE_INPUT = 'components.phone-input' as const;

export const COMPONENTS_FORBIDDEN = 'components.forbidden' as const;

export const COMPONENTS_ITEM_TAGS = 'components.item-tags' as const;
