import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { postSuperAdminForgetPassword } from '@apis/auth';

interface UseForgetPasswordOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useForgetPassword = (options: UseForgetPasswordOptions = {}) => {
  const { onError, throwable } = options;

  const loading = useLoading();

  const submit = async (username: string) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        await postSuperAdminForgetPassword(username);
        return true;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useForgetPassword;
