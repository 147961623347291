import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isNil } from 'ramda';
import {
  Space,
  Button,
  Form,
  Input,
  Row,
  Col,
  List,
  Typography,
  Spin,
} from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import {
  SUPER_ADMIN_FETCH_MANAGERS_ERROR,
  SUPER_ADMIN_FETCH_ALL_GLOBAL_ACCT_ROLES_ERROR,
  SUPER_ADMIN_ADD_ORG_SUCCESS,
  SUPER_ADMIN_ADD_ORG_ERROR,
  SUPER_ADMIN_FETCH_ONE_ORG_ERROR,
  SUPER_ADMIN_EDIT_ORG_SUCCESS,
  SUPER_ADMIN_EDIT_ORG_ERROR,
} from '@constants/notifications';
import { ManagerStatus } from '@enums/managers.enum';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import EditorHeader from '@components/EditorHeader';
import ContainerSkeleton from '@components/ContainerSkeleton';
import DrawerCheckbox from '@components/DrawerCheckbox';
import InputList from '@components/InputList';
import Link from '@components/Link';
import { Container } from './styleds';
import useOneOrg from './hooks/useOneOrg';
import useManagers from './hooks/useManagers';
import useAccountRoles from './hooks/useAccountRoles';
import useAdd from './hooks/useAdd';
import useEdit from './hooks/useEdit';
import {
  FormValues,
  ManagerDataSource,
  AccountRoleDataSource,
} from './interfaces';

const { useForm, Item: FormItem } = Form;
const { Text } = Typography;
const { Item: ListItem } = List;
const { Meta: ListItemMeta } = ListItem;

const SuperAdminOrganizationEditor = () => {
  const { orgId } = useParams<{ orgId?: string }>();
  const [form] = useForm<FormValues>();
  const navigate = useEnhancedNavigate();
  const { errorNotify, successNotify } = useGlobalNotification();
  const oneOrg = useOneOrg({
    defaultLoading: !isNil(orgId),
    onError: errorNotify(SUPER_ADMIN_FETCH_ONE_ORG_ERROR),
  });
  const managers = useManagers({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANAGERS_ERROR),
  });
  const accountRoles = useAccountRoles({
    onError: errorNotify(SUPER_ADMIN_FETCH_ALL_GLOBAL_ACCT_ROLES_ERROR),
  });
  const add = useAdd({
    onSuccess: successNotify(SUPER_ADMIN_ADD_ORG_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_ADD_ORG_ERROR),
  });
  const edit = useEdit({
    onSuccess: successNotify(SUPER_ADMIN_EDIT_ORG_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_EDIT_ORG_ERROR),
  });
  const [mgrDataSource, setMgrDataSource] = useState<ManagerDataSource[]>([]);
  const originMgrDataSource = useRef<ManagerDataSource[]>([]);

  const formInitialValues = useMemo(
    () => ({
      name: oneOrg.data?.name,
      head: oneOrg.data?.head,
      email: oneOrg.data?.email,
      phone: oneOrg.data?.phone,
      taxId: oneOrg.data?.taxId,
      accountRoles: oneOrg.data?.organizationAccountRoles.map(
        (orgAcctRole) => orgAcctRole.globalAccountRoleId,
      ),
      managers: { add: [], remove: [] },
    }),
    [oneOrg.data],
  );

  const handleGoBackClick = () => {
    navigate(-1, '/super-admins/organizations');
  };

  const handleAddManagerSearch = async (text: string) => {
    if (text.length < 1) {
      managers.clear();
      return;
    }

    const formManagers = form.getFieldValue(
      'managers',
    ) as FormValues['managers'];

    await managers.fetch({
      statusIn: [
        ManagerStatus.INVITED,
        ManagerStatus.INVITATION_FAILED,
        ManagerStatus.ACTIVATED,
      ],
      idNotIn: isNil(orgId)
        ? formManagers?.add.map((manager) => manager.id)
        : undefined,
      orgIdExclude: isNil(orgId) ? undefined : [orgId],
      and: [{ or: [{ nameLike: text }, { emailLike: text }] }],
      size: text.length > 20 ? 20 : Math.ceil((1 - text.length / 20) * 100),
    });
  };

  const fetchOrg = async (orgId?: string) => {
    if (!isNil(orgId)) {
      await oneOrg.fetch(orgId).then((org) => {
        if (isNil(org)) return;
        const { managerOrganizations } = org;
        const mgrDataSource = managerOrganizations.map((mgrOrg) => ({
          ...mgrOrg.manager,
          mgrOrgId: mgrOrg.id,
          isAdmin: mgrOrg.isAdmin,
          isDefault: mgrOrg.isDefault,
          value: mgrOrg.managerId,
        }));
        originMgrDataSource.current = mgrDataSource;
        setMgrDataSource(mgrDataSource);
      });
    }
  };

  const handleSubmit = async () => {
    if (isNil(orgId)) {
      const orgId = await add.submit(form);
      if (!isNil(orgId)) {
        form.resetFields();
        navigate(`${orgId}`, { replace: true });
      }
    } else {
      const result = await edit.submit(
        orgId,
        form,
        oneOrg.data?.organizationAccountRoles,
      );
      if (result) {
        await fetchOrg(orgId);
        form.resetFields();
      }
    }
  };

  const memberOptionRender = useCallback(
    (option: ManagerDataSource) => (
      <Space direction="vertical" size={0}>
        <Text strong>{option.name}</Text>
        <Text type="secondary">{option.email}</Text>
      </Space>
    ),
    [],
  );

  const memberListItemRender = useCallback(
    (option: ManagerDataSource) => (
      <ListItemMeta
        title={
          <Space>
            <Link
              navigate={{
                to: `../managers/editor/${option.id}`,
              }}
            >
              <SelectOutlined />
            </Link>
            <Text>{option.name}</Text>
          </Space>
        }
        description={option.email}
      />
    ),
    [],
  );

  const accountRoleListItemRender = useCallback(
    (option: AccountRoleDataSource) => <ListItemMeta title={option.name} />,
    [],
  );

  useEffect(() => {
    accountRoles.fetch();
    fetchOrg(orgId);
  }, [orgId]);

  return (
    <Spin spinning={accountRoles.loading || add.loading || edit.loading}>
      <ContainerSkeleton loading={oneOrg.loading}>
        <Container direction="vertical" size="large">
          <EditorHeader
            title={orgId ? 'Edit Organization' : 'Add Organization'}
            onGoBack={handleGoBackClick}
          />
          <Row gutter={[24, 0]}>
            <Col lg={12} xs={24}>
              <Form<FormValues>
                form={form}
                layout="vertical"
                initialValues={formInitialValues}
              >
                <FormItem label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </FormItem>
                <FormItem label="Head" name="head" rules={[{ required: true }]}>
                  <Input />
                </FormItem>
                <FormItem
                  label="Email"
                  name="email"
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
                <FormItem label="Phone" name="phone">
                  <Input />
                </FormItem>
                <FormItem label="Tax ID" name="taxId">
                  <Input />
                </FormItem>
                <FormItem label="Account Roles" name="accountRoles">
                  <DrawerCheckbox<AccountRoleDataSource>
                    title="Account Roles"
                    buttonText="Select Account Roles"
                    itemRender={accountRoleListItemRender}
                    options={accountRoles.data}
                  />
                </FormItem>
              </Form>
            </Col>
            <Col lg={12} xs={24}>
              <Form form={form} layout="vertical">
                <FormItem label="Members" name="managers">
                  <InputList<ManagerDataSource>
                    buttonText="Add Member"
                    loading={managers.loading}
                    options={managers.data}
                    onSearch={handleAddManagerSearch}
                    dataSource={mgrDataSource}
                    tagKey="name"
                    optionRender={memberOptionRender}
                    itemRender={memberListItemRender}
                  />
                </FormItem>
              </Form>
            </Col>
          </Row>
          <Space>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button type="text" onClick={handleGoBackClick}>
              Cancel
            </Button>
          </Space>
        </Container>
      </ContainerSkeleton>
    </Spin>
  );
};

export default SuperAdminOrganizationEditor;
