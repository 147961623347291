import { useEffect } from 'react';

type Destructor = () => void;

export type MountedCallback = () => void | Destructor;

const useMounted = (mounted: MountedCallback) => {
  useEffect(() => {
    return mounted();
  }, []);
};

export default useMounted;
