import { Typography, Space, theme } from 'antd';
import styled from 'styled-components';
import { ShopOutlined, StarOutlined } from '@ant-design/icons';
export interface OrganizationLabelProps {
  label: string;
  isDefault?: boolean;
}

const { Text } = Typography;

const OrganizationLabel = (props: OrganizationLabelProps) => {
  const { label, isDefault } = props;

  return (
    <Space style={{ width: '100%' }}>
      {isDefault ? <StarOutlined /> : <ShopOutlined />}
      <Text>{label}</Text>
    </Space>
  );
};

export default OrganizationLabel;
