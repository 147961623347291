export enum ManagerStatus {
  INVITED = 0,
  INVITATION_FAILED = 1,
  ACTIVATED = 2,
  DEACTIVATED = 3,
  DELETED = 4,
  FLUSHED_TOKEN = 5,
}

export enum SearchScope {
  ALL = 'all',
  MANAGER = 'mgr',
  CLUSTER = 'clstr',
}

export enum ManagerType {
  BOILERPLATE = 0,
  REALITY = 1,
}

export enum SearchResultType {
  MANAGER = 'mgr',
  CLUSTER = 'clstr',
  BOILERPLATE = 'bp',
}

export enum ManagerEditMode {
  MANUAL = 'manual',
  SYNC = 'sync',
}
