import { Permissions, Utils } from '@interfaces/apis';
import { formatResponseWithMeta } from '@utils/api.util';
import { superAdminAxios } from './axios';

export const getManyPermission = async (
  query?: Utils.OptionalPagination<Permissions.FindManyPermission>,
) =>
  superAdminAxios
    .get<Utils.WeMoReplyWithMeta<Permissions.Permission>>('permissions', {
      params: query,
    })
    .then(formatResponseWithMeta);
