import { AuthIdentity } from '@enums/auth-identity.enum';
import { formatResponse } from '@utils/api.util';
import axios, { NexusAxiosInstance } from '@utils/nexus-axios';
import { tokenStore } from '@stores';
import i18n from '@i18n';
import { Utils, SuperAdmins, Managers } from '@interfaces/apis';

export const normalAxios = axios.create({
  baseURL: process.env.REACT_APP_NEXUS_DOMAIN,
});

export const superAdminAxios = NexusAxiosInstance({
  identity: AuthIdentity.SUPER_ADMIN,
  baseUrl: process.env.REACT_APP_NEXUS_DOMAIN,
  async tokenRefresh(refreshInstance) {
    return refreshInstance
      .patch<Utils.WeMoReply<SuperAdmins.SuperAdminMeToken>>(
        'super-admins/me/refresh-token',
      )
      .then(formatResponse);
  },
  getToken() {
    return tokenStore.getState().superAdmin;
  },
  setToken(accessToken, refreshToken) {
    tokenStore
      .getState()
      .setToken(AuthIdentity.SUPER_ADMIN, accessToken, refreshToken);
  },
  onForceLogout() {
    tokenStore.getState().clearToken(AuthIdentity.SUPER_ADMIN);
    const { pathname, search } = window.location;
    window.location.replace(
      `/super-admins/log-in?redirectTo=${encodeURIComponent(
        `${pathname}${search}`,
      )}`,
    );
  },
  getLanguage() {
    return i18n.language;
  },
});

export const managerAxios = NexusAxiosInstance({
  identity: AuthIdentity.MANAGER,
  baseUrl: process.env.REACT_APP_NEXUS_DOMAIN,
  async tokenRefresh(refreshInstance) {
    return refreshInstance
      .patch<Utils.WeMoReply<Managers.ManagerMeToken>>(
        'managers/me/refresh-token',
      )
      .then(formatResponse);
  },
  getToken() {
    return tokenStore.getState().manager;
  },
  setToken(accessToken, refreshToken) {
    tokenStore
      .getState()
      .setToken(AuthIdentity.MANAGER, accessToken, refreshToken);
  },
  onForceLogout() {
    tokenStore.getState().clearToken(AuthIdentity.MANAGER);
    const { pathname, search } = window.location;
    window.location.replace(
      `/log-in?redirectTo=${encodeURIComponent(`${pathname}${search}`)}`,
    );
  },
  getLanguage() {
    return i18n.language;
  },
});
