import { useState } from 'react';

import { WithValueAndLabel } from '@components/DrawerCheckbox';
import { Permissions } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getManyPermission } from '@apis/permissions';

interface UseManyPermOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useAllPerm = (options: UseManyPermOptions = {}) => {
  const { onError, throwable } = options;
  const loading = useLoading();
  const [data, setData] = useState<WithValueAndLabel<Permissions.Permission>[]>(
    [],
  );

  const fetch = async (query?: Permissions.FindManyPermission) => {
    await asyncLoadingWrapper(
      loading,
      async () => {
        const [data] = await getManyPermission({ ...query, all: true });
        const withValueData = data.map((permission) => ({
          ...permission,
          value: permission.id,
          label: permission.name,
        }));
        setData(withValueData);
        return withValueData;
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return {
    loading: loading.loading,
    fetch,
    data,
  };
};

export default useAllPerm;
