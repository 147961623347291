import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { useHistoryStore } from '@stores';
import { isNil } from 'ramda';

export interface ListItemState {
  id: string;
  name: string;
}

export interface AuthzEditorListState {
  list: ListItemState[];
}

const DEFAULT_STATE: AuthzEditorListState = {
  list: [],
};

export interface AuthzEditorListAction {
  set(next: ListItemState[]): void;
  remove(index: number): void;
  reset(): void;
}

const store = create(
  persist<
    AuthzEditorListState & AuthzEditorListAction,
    [],
    [],
    AuthzEditorListState
  >(
    (set, get) => ({
      ...DEFAULT_STATE,
      set(next: ListItemState[]) {
        set((prev) => ({ ...prev, list: [...next] }));
      },
      remove(index: number) {
        set((prev) => ({
          list: prev.list.filter((_, i) => i !== index),
        }));
      },
      reset() {
        set({ ...DEFAULT_STATE });
      },
    }),
    {
      name: 'authz-editor-list',
      partialize: (state) => ({ list: state.list }),
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

useHistoryStore.subscribe(({ pathname }) => {
  if (isNil(pathname)) return;
  if (/\/super-admins\/managers.*/.test(pathname)) return;
  store.getState().reset();
});

export const useAuthzEditorListStore = store;
