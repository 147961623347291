import { isNotNil } from 'ramda';

import { Utils, SuperAdmins, Managers } from '@interfaces/apis';
import { formatResponse } from '@utils/api.util';
import { isNotEmpty } from '@utils/ramda.util';
import { AuthIdentity } from '@enums/auth-identity.enum';
import { tokenStore } from '@stores';
import { normalAxios, superAdminAxios, managerAxios } from './axios';

export const postSuperAdminLogin = async (
  username: string,
  password: string,
) => {
  const { accessToken, refreshToken } = await normalAxios
    .post<Utils.WeMoReply<SuperAdmins.SuperAdminMeToken>>(
      'super-admins/login',
      {
        username,
        password,
      },
    )
    .then(formatResponse);
  // superAdminAxios.storeTokens(accessToken, refreshToken);
  tokenStore
    .getState()
    .setToken(AuthIdentity.SUPER_ADMIN, accessToken, refreshToken);
};

export const postManagerLogin = async (username: string, password: string) => {
  const { accessToken, refreshToken } = await normalAxios
    .post<Utils.WeMoReply<Managers.ManagerMeToken>>('managers/login', {
      username,
      password,
    })
    .then(formatResponse);

  tokenStore
    .getState()
    .setToken(AuthIdentity.MANAGER, accessToken, refreshToken);
};

export const deleteSuperAdminLogout = async () => {
  const { accessToken } = tokenStore.getState().superAdmin;
  if (isNotNil(accessToken) && isNotEmpty(accessToken)) {
    await superAdminAxios.delete('super-admins/me/logout');
  }
  tokenStore.getState().clearToken(AuthIdentity.SUPER_ADMIN);
};

export const deleteManagerLogout = async () => {
  const { accessToken } = tokenStore.getState().manager;
  if (isNotNil(accessToken) && isNotEmpty(accessToken)) {
    await managerAxios.delete('managers/me/logout');
  }
  tokenStore.getState().clearToken(AuthIdentity.MANAGER);
};

export const getSuperAdminMeIsActivated = async () => {
  await superAdminAxios.get('super-admins/me/is-activated');
};

export const postManagerActivateMe = async (
  data: Managers.ActivateManagerMe,
) => {
  const { accessToken, refreshToken } = await normalAxios
    .post<Utils.WeMoReply<Managers.ManagerMeToken>>('managers/activate', data)
    .then(formatResponse);

  tokenStore
    .getState()
    .setToken(AuthIdentity.MANAGER, accessToken, refreshToken);
};

export const postSuperAdminActivateMe = async (
  data: SuperAdmins.ActivateSuperAdminMe,
) => {
  const { accessToken, refreshToken } = await normalAxios
    .post<Utils.WeMoReply<SuperAdmins.SuperAdminMeToken>>(
      'super-admins/activate',
      data,
    )
    .then(formatResponse);

  tokenStore
    .getState()
    .setToken(AuthIdentity.SUPER_ADMIN, accessToken, refreshToken);
};

export const postSuperAdminForgetPassword = async (username: string) => {
  await normalAxios.post('super-admins/forget-password', { username });
};

export const postManagerForgetPassword = async (username: string) => {
  await normalAxios.post('managers/forget-password', { username });
};

export const postSuperAdminResetPassword = async (
  key: string,
  password: string,
) => {
  const { accessToken, refreshToken } = await normalAxios
    .post<Utils.WeMoReply<SuperAdmins.SuperAdminMeToken>>(
      'super-admins/reset-password',
      { key, password },
    )
    .then(formatResponse);

  tokenStore
    .getState()
    .setToken(AuthIdentity.SUPER_ADMIN, accessToken, refreshToken);
};

export const postManagerResetPassword = async (
  key: string,
  password: string,
) => {
  const { accessToken, refreshToken } = await normalAxios
    .post<Utils.WeMoReply<Managers.ManagerMeToken>>('managers/reset-password', {
      key,
      password,
    })
    .then(formatResponse);

  tokenStore
    .getState()
    .setToken(AuthIdentity.MANAGER, accessToken, refreshToken);
};
