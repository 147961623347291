import { useState } from 'react';

import { asyncLoadingWrapper, isAxiosCanceledError } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import useAxiosCancelable from '@hooks/useAxiosCancelable';
import { OrganizationClusters } from '@interfaces/apis';
import { ExcludeArray } from '@interfaces/utils.interface';
import { findOne_SuperAdmin } from '@apis/organization-clusters';

interface UseFindOrgClusterOptions {
  throwable?: true;
  onError?: (error: unknown) => void;
}

type Member = ExcludeArray<
  OrganizationClusters.FindOneResp_SuperAdmin['members']
>;

interface FindOneDataMember extends Member {
  value: Member['id'];
}

interface FindOneData extends OrganizationClusters.FindOneResp_SuperAdmin {
  members: FindOneDataMember[];
}

const useFindOrgCluster = (options: UseFindOrgClusterOptions = {}) => {
  const { throwable, onError } = options;
  const loading = useLoading();
  const canceledSignal = useAxiosCancelable();
  const [data, setData] = useState<FindOneData>();

  const fetch = async (id: string) => {
    return await asyncLoadingWrapper(
      loading,
      {
        async handler() {
          canceledSignal.create();
          const data = await findOne_SuperAdmin(id, canceledSignal.get());
          setData({
            ...data,
            members: data.members.map((member) => ({
              ...member,
              value: member.id,
            })),
          });
          return data;
        },
        onFinally() {
          canceledSignal.clear();
        },
      },
      (error) => {
        if (!isAxiosCanceledError(error)) {
          onError?.(error);
        }
      },
      throwable,
    );
  };

  const cancel = () => {
    canceledSignal.cancel();
  };

  return { loading: loading.loading, data, fetch, cancel };
};

export default useFindOrgCluster;
