import axios, { AxiosResponse, CancelToken } from 'axios';
import { isEmpty, isNil } from 'ramda';

import { NexusAxiosError } from '@interfaces/nexus-axios.interface';
import nextTick from '@utils/next-tick';
import {
  isTokenNeedToRefreshNexusAxiosError,
  isForceLogoutNexusAxiosError,
} from './utils';
import {
  RefreshTokenNotFound,
  RequestConfigNotFound,
  RequestRetryUrlNotFound,
  BaseUrlNotFound,
} from './exceptions';
import { NexusAxiosProxyOptions, PendingRequestJob } from './interfaces';

export type { CancelToken };
export { isNexusAxiosError } from './utils';

export function NexusAxiosInstance(options: NexusAxiosProxyOptions) {
  const {
    identity,
    baseUrl,
    tokenRefresh,
    getToken,
    setToken,
    onForceLogout,
    getLanguage,
  } = options;
  if (isNil(baseUrl)) throw new BaseUrlNotFound();

  const accessInstance = axios.create({ baseURL: baseUrl });
  const refreshInstance = axios.create({ baseURL: baseUrl });

  accessInstance.interceptors.request.use((config) => {
    const { accessToken } = getToken();
    config.headers['Authorization'] = `${identity} ${accessToken}`;
    config.headers['Accept-Language'] = getLanguage();
    return config;
  });

  refreshInstance.interceptors.request.use((config) => {
    const { refreshToken } = getToken();
    config.headers['Authorization'] = `${identity} ${refreshToken}`;
    return config;
  });

  let pendingRequestQueues: Array<PendingRequestJob> = [];
  let isRefreshing = false;
  let isForceLogOuting = false;

  const postRefreshToken = async () => {
    try {
      const { accessToken, refreshToken } = await tokenRefresh(refreshInstance);
      setToken(accessToken, refreshToken);

      for (const pendingRequest of pendingRequestQueues) {
        pendingRequest(true, accessToken);
      }
    } catch (error) {
      for (const pendingRequest of pendingRequestQueues) {
        pendingRequest(false, error);
      }
      throw error;
    } finally {
      pendingRequestQueues = [];
      isRefreshing = false;
    }
  };

  const handleTokenNeedToRefreshError = async (error: NexusAxiosError) => {
    if (!isTokenNeedToRefreshNexusAxiosError(error))
      return Promise.reject(error);

    if (isNil(error?.config))
      return Promise.reject(new RequestConfigNotFound());
    if (isNil(error.config.url))
      return Promise.reject(new RequestRetryUrlNotFound());
    if (isNil(getToken().refreshToken) || isEmpty(getToken().refreshToken))
      return Promise.reject(new RefreshTokenNotFound());

    if (!isRefreshing) {
      isRefreshing = true;
      postRefreshToken(); // IMPORTANT: 不需要 await
    }

    return new Promise((resolve, reject) => {
      pendingRequestQueues.push((isRefreshOK, result) => {
        if (isRefreshOK) {
          error.config.headers['Authorization'] = `${identity} ${result}`;
          axios.request(error.config).then(resolve).catch(reject);
        } else {
          reject(result);
        }
      });
    });
  };

  accessInstance.interceptors.response.use((response: AxiosResponse) => {
    return response;
  }, handleTokenNeedToRefreshError);

  const handleForceReturnError = async (error: NexusAxiosError) => {
    if (
      !isForceLogOuting &&
      (isForceLogoutNexusAxiosError(error) ||
        isTokenNeedToRefreshNexusAxiosError(error))
    ) {
      isForceLogOuting = true;
      nextTick(() => {
        onForceLogout();
        isForceLogOuting = false;
      });
    }
    return Promise.reject(error);
  };

  accessInstance.interceptors.response.use((response: AxiosResponse) => {
    return response;
  }, handleForceReturnError);

  return accessInstance;
}

export default axios;
