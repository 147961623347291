import { ReactNode } from 'react';

import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import { postSuperAdminActivateMe } from '@apis/auth';
import { SuperAdmins } from '@interfaces/apis';

interface UseActivateOptions {
  onSuccess?: (message: ReactNode) => void;
  onError?: (error: unknown) => void;
  throwable?: true;
}

const useActivate = (options: UseActivateOptions = {}) => {
  const { onSuccess, onError, throwable } = options;
  const navigate = useEnhancedNavigate();
  const loading = useLoading();

  const submit = async (data: SuperAdmins.ActivateSuperAdminMe) => {
    return await asyncLoadingWrapper(
      loading,
      async () => {
        await postSuperAdminActivateMe(data);
        onSuccess?.('Successfully activated.');
        navigate('/super-admins/organizations', { replace: true });
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  return { loading: loading.loading, submit };
};

export default useActivate;
