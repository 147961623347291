import z from 'zod';

import { TextPlacement } from '@enums/query.enum';

export const MetaRespSchema = z.object({
  total: z.number(),
  page: z.number(),
  size: z.number(),
});

export const OptMetaRespSchema = z.object({
  total: z.number(),
  page: z.number().optional(),
  size: z.number().optional(),
});

export const IdQuerySchema = z.union([z.string(), z.string().array()]);

export const TextQuerySchema = z.union([
  z.string(),
  z.string().array(),
  z.object({
    text: z.string(),
    placement: z.nativeEnum(TextPlacement),
  }),
]);
