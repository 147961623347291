import styled from 'styled-components';
import { Form, theme } from 'antd';

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['affixed'].includes(prop) && defaultValidatorFn(prop),
})<{ affixed?: boolean }>`
  background-color: ${theme.getDesignToken().colorWhite};
  padding-top: ${({ affixed }) => (affixed ? '16px' : '0')};
  padding-bottom: ${({ affixed }) => (affixed ? '24px' : '0')};
  border-top: ${({ affixed }) =>
    affixed
      ? `1px solid ${theme.getDesignToken().colorBorderSecondary}`
      : 'none'};
`;

// boxShadowTertiary
/* box-shadow: ${({ affixed }) =>
    affixed
      ? '0 1px -2px 0 rgba(0, 0, 0, 0.03), 0 1px -6px -1px rgba(0, 0, 0, 0.02), 0 2px -4px 0 rgba(0, 0, 0, 0.02)'
      : 'none'}; */
