import i18n from './instance';

i18n.services.formatter?.add('lowercase', (value: string) => {
  return value.toLowerCase();
});

i18n.services.formatter?.add('capitalizeFirstLetter', (value: string) => {
  const [first, ...other] = value.split(' ');
  return `${first.charAt(0).toUpperCase()}${first.slice(1)} ${other
    .join('')
    .toLowerCase()}`;
});
