import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Space, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { isNil } from 'ramda';

import { COMMON } from '@i18n/namespaces';
import TooltipButton from '@components/TooltipButton';
import { PageTitle } from './styleds';

const { Text } = Typography;

export interface EditorHeaderProps {
  title?: ReactNode;
  subTitle?: ReactNode;
  extra?: ReactNode;
  onGoBack?: () => void;
}

const EditorHeader = (props: EditorHeaderProps) => {
  const { title, subTitle, extra, onGoBack } = props;
  const { t } = useTranslation(COMMON);

  return (
    <Row justify="space-between">
      <Col>
        <Space align="center">
          <TooltipButton
            title={t('common:go-back', 'Go Back')}
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={onGoBack}
          />
          <Space align="center" size="middle">
            {['string', 'number'].includes(typeof title) ? (
              <PageTitle>{title}</PageTitle>
            ) : (
              title
            )}
            {subTitle && <Text type="secondary">{subTitle}</Text>}
          </Space>
        </Space>
      </Col>
      {!isNil(extra) && <Col>{extra}</Col>}
    </Row>
  );
};

export default EditorHeader;
export { PageTitle as EditorHeaderTitle };
