import { MenuProps } from 'antd';
import { isNil, isNotNil, omit } from 'ramda';

import { NexusRouteObject } from '@interfaces/routes.interface';
import {
  getMenuItemGroupParentPath,
  isMenuItemWithInvisibleChildren,
  isSubMenu,
  isMenuItemGroup,
  isMenuDivider,
} from '@utils/router.util';
import { Link } from './styleds';

type MenuItem = Required<MenuProps>['items'][number];

type Result = MenuItem | undefined;

export type Excluder = (item: NexusRouteObject) => boolean;

type Converter = (
  items: NexusRouteObject[],
  prefix: string,
  excluder?: Excluder,
) => MenuItem[];

type Strategy = (
  item: NexusRouteObject,
  prefix: string,
  converter: Converter,
  excluder?: Excluder,
) => MenuItem | undefined;

const excludeProps = omit([
  'id',
  'path',
  'caseSensitive',
  'loader',
  'action',
  'hasErrorBoundary',
  'shouldRevalidate',
  'handle',
  'index',
  'children',
  'element',
  'errorElement',
  'label',
  'excludeMenuItem',
  'lazy',
]);

const formatChildrenByParent = (
  parent: { path?: string },
  children: NexusRouteObject[],
) =>
  children.reduce((result, child) => {
    if (child.excludeMenuItem) return result;
    if (isNil(parent.path)) return [...result, child];
    if (!('path' in child)) return [...result, child];
    return [...result, { ...child, path: `${parent.path}/${child.path}` }];
  }, [] as NexusRouteObject[]);

class ConvertStrategies {
  private strategies: Strategy[] = [];

  register(...strategy: Strategy[]) {
    this.strategies = [...this.strategies, ...strategy];
    return this;
  }

  convert(
    item: NexusRouteObject,
    prefix: string,
    converter: Converter,
    excluder?: Excluder,
  ): Result {
    let node: Result = undefined;
    this.strategies.find((strategy) => {
      const result = strategy(item, prefix, converter, excluder);
      if (isNotNil(result)) node = result;
      return isNotNil(result);
    });
    return node;
  }
}

const itemGroupStrategy: Strategy = (item, prefix, converter, excluder) => {
  if (!isMenuItemGroup(item)) return undefined;
  const { label, children, key, excludeMenuItem, ...restProps } = item;
  if (isNil(children) || children.length === 0) return undefined;
  const path = getMenuItemGroupParentPath(key);
  const result = converter(
    formatChildrenByParent({ path }, children),
    prefix,
    excluder,
  );
  if (result.length === 0) return undefined;
  return {
    key,
    label,
    children: result,
    ...excludeProps(restProps),
  } as MenuItem;
};

const subMenuStrategy: Strategy = (item, prefix, converter, excluder) => {
  if (!isSubMenu(item)) return undefined;
  const { label, children, path, ...restProps } = item;
  const result = converter(
    formatChildrenByParent({ path }, children),
    prefix,
    excluder,
  );
  if (result.length === 0) return;
  return {
    key: path,
    label,
    children: result,
    ...excludeProps(restProps),
  } as MenuItem;
};

const menuDividerStrategy: Strategy = (item) => {
  if (!isMenuDivider(item)) return undefined;
  const { key, ...restProps } = item;
  return {
    key,
    ...excludeProps(restProps),
  } as MenuItem;
};

const menuItemStrategy: Strategy = (item, prefix) => {
  if (!isMenuItemWithInvisibleChildren(item)) return undefined;
  const { path, label, ...restProps } = item;
  const to = `/${prefix}/${path}`;
  return {
    key: path,
    label: isNotNil(path) ? <Link navigate={{ to }}>{label}</Link> : label,
    ...excludeProps(restProps),
  } as MenuItem;
};

const convertStrategies = new ConvertStrategies();
convertStrategies.register(
  menuItemStrategy,
  subMenuStrategy,
  itemGroupStrategy,
  menuDividerStrategy,
);

export const convertItems: Converter = (items, prefix, excluder) => {
  return items
    .map((item) => {
      const result = convertStrategies.convert(
        item,
        prefix,
        convertItems,
        excluder,
      );
      if (!isNil(excluder) && !isNil(result)) {
        if (excluder(item)) return undefined;
      }
      return result;
    })
    .filter<MenuItem>((node): node is MenuItem => !isNil(node));
};
