import { Fragment } from 'react';
import { Skeleton, SkeletonProps } from 'antd';

const ContainerSkeleton = (
  props: Pick<SkeletonProps, 'loading' | 'children'> = {},
) => {
  const { loading, children } = props;
  return (
    <Fragment>
      <Skeleton loading={loading} active paragraph={{ rows: 3 }} />
      <Skeleton loading={loading} active paragraph={{ rows: 3 }}>
        {children}
      </Skeleton>
    </Fragment>
  );
};

export default ContainerSkeleton;
