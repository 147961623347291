import { useEffect, DependencyList } from 'react';

import useIsMounted from './useIsMounted';

type Destructor = () => void;

export type BeforeMountedCallback = () => void | Destructor;

const useBeforeMounted = (
  beforeMounted: BeforeMountedCallback,
  deps: DependencyList,
) => {
  const isMounted = useIsMounted();

  useEffect(() => {
    const currentIsMounted = isMounted.current;
    if (!currentIsMounted) return;
    const destructor = beforeMounted();
    return () => {
      if (!currentIsMounted) return;
      'function' === typeof destructor && destructor();
    };
  }, deps);
};

export default useBeforeMounted;
