import { useState } from 'react';

import { asyncLoadingWrapper, isAxiosCanceledError } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import useAxiosCancelable from '@hooks/useAxiosCancelable';
import { ManagerClusters } from '@interfaces/apis';
import { ExcludeArray } from '@interfaces/utils.interface';
import { findOne_SuperAdmin } from '@apis/manager-clusters';

interface UseFindOneClusterOptions {
  throwable?: true;
  onError?: (error: unknown) => void;
}

type Member = ExcludeArray<ManagerClusters.FindOneResp_SuperAdmin['members']>;

type FindOneClusterDataMember = Omit<Member, 'email'>;

interface FindOneClusterData
  extends Omit<ManagerClusters.FindOneResp_SuperAdmin, 'members'> {
  members: FindOneClusterDataMember[];
}

const useFindOneCluster = (options: UseFindOneClusterOptions = {}) => {
  const { throwable, onError } = options;
  const loading = useLoading();
  const canceledSignal = useAxiosCancelable();
  const [data, setData] = useState<FindOneClusterData>();

  const fetch = async (id: string) => {
    return await asyncLoadingWrapper(
      loading,
      {
        async handler() {
          canceledSignal.create();
          const data = await findOne_SuperAdmin(id, canceledSignal.get()).then(
            (data) => ({
              ...data,
              members: data.members.map(({ email, ...member }) => ({
                ...member,
              })),
            }),
          );
          setData(data);
          return data;
        },
        onFinally() {
          canceledSignal.clear();
        },
      },
      (error) => {
        if (!isAxiosCanceledError(error)) {
          onError?.(error);
        }
      },
      throwable,
    );
  };

  const cancel = () => {
    canceledSignal.cancel();
  };

  return { loading: loading.loading, data, fetch, cancel };
};

export default useFindOneCluster;
