import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import PageVisibility, { PageVisibilityProps } from 'react-page-visibility';

import { ROUTERS_SUPER_ADMIN } from '@i18n/namespaces';
import BasicLayout from '@layouts/BasicLayout';
import useLogOut from '@hooks/useLogOut';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useMeIsActivated from '@hooks/apis/super-admins/useMeIsActivated';
import useMenu from '@hooks/useMenu';
import { createSuperAdminWorkspaceRoutes } from '@routers/super-admin.router';
import { deleteSuperAdminLogout } from '@apis/auth';
import {
  LOG_OUT_ERROR,
  CHECK_ME_IS_ACTIVATED_ERROR,
  GET_SUPER_ADMIN_ME_INFO_ERROR,
} from '@constants/notifications';
import useSuperAdminMeInfo from './hooks/useSuperAdminMeInfo';

const SuperAdminWorkspace = () => {
  const { t, i18n } = useTranslation(ROUTERS_SUPER_ADMIN);
  const { errorNotify } = useGlobalNotification();
  const logOut = useLogOut({
    api: deleteSuperAdminLogout,
    navigateTo: '/super-admins/log-in',
    onError: errorNotify(LOG_OUT_ERROR),
  });
  const meIsActivated = useMeIsActivated({
    defaultLoading: true,
    onError: errorNotify(CHECK_ME_IS_ACTIVATED_ERROR),
  });
  const meInfo = useSuperAdminMeInfo({
    onError: errorNotify(GET_SUPER_ADMIN_ME_INFO_ERROR),
  });
  const menu = useMenu({
    prefix: 'super-admins',
    routes: createSuperAdminWorkspaceRoutes(t, i18n),
    onPageChange: meIsActivated.check,
  });
  const [isInit, setIsInit] = useState(false);

  const handlePageVisibilityChange: PageVisibilityProps['onChange'] = (
    isVisible,
  ) => {
    if (isVisible) meIsActivated.check();
  };

  useEffect(() => {
    meIsActivated.check().then(() => meInfo.get().then(() => setIsInit(true)));
  }, []);

  return (
    <PageVisibility onChange={handlePageVisibilityChange}>
      <Spin
        spinning={logOut.loading || meIsActivated.loading || meInfo.loading}
        delay={300}
      >
        <BasicLayout
          display={isInit}
          onLogOut={logOut.trigger}
          name={meInfo.me?.name}
          title={menu.label}
          menuItems={menu.menuItems}
          defaultOpenKeys={menu.defaultOpenKeys}
          defaultSelectedKeys={menu.defaultSelectedKeys}
          onMenuItemClick={menu.onMenuItemClick}
        />
      </Spin>
    </PageVisibility>
  );
};

export default SuperAdminWorkspace;
