import { Fragment, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  Row,
  Col,
  Space,
  Button,
  Spin,
  Form,
  Select,
  Radio,
  Typography,
} from 'antd';
import { clone, difference, isEmpty, isNil, isNotNil, not } from 'ramda';

import {
  SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR,
  SUPER_ADMIN_UPDATE_MANY_MANAGER_AUTHZ_SUCCESS,
  SUPER_ADMIN_UPDATE_MANY_MANAGER_AUTHZ_ERROR,
  SUPER_ADMIN_FETCH_MANY_MANAGER_AUTHZS_ERROR,
} from '@constants/notifications';
import { useGlobalNotification } from '@contexts/GlobalNotificationContext';
import useEnhancedNavigate from '@hooks/useEnhancedNavigate';
import EditorHeader from '@components/EditorHeader';
import FluidSpace from '@components/FluidSpace';
import AffixFormFooter from '@components/AffixFormFooter';
import { pickState } from '@stores';
import { SearchResultType, ManagerEditMode } from '@enums/managers.enum';
import {
  useAuthzEditorListStore,
  ListItemState,
} from '../stores/authz-editor-list.store';
import AuthzSelect from '../components/AuthzSelect';
import MemberSelect from './components/MemberSelect';
import useSearchManyManager from './hooks/useSearchManyManager';
import useUpdateManyAuthz from './hooks/useUpdateManyAuthz';
import useAuthzs from './hooks/useAuthzs';
import { FormValues } from './interfaces';
import { FormDataSchema } from './schemas';

const { Item: FormItem, useForm, useWatch } = Form;
const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Text } = Typography;

const ManagerAuthzEditor = () => {
  const navigate = useEnhancedNavigate();
  const { successNotify, errorNotify } = useGlobalNotification();
  const authzEditorList = useAuthzEditorListStore(
    useShallow((state) => pickState(['list', 'set', 'reset'], state)),
  );
  const searchManagers = useSearchManyManager({
    onError: errorNotify(SUPER_ADMIN_SEARCH_MANY_MANAGER_ERROR),
  });
  const updateManyAuthz = useUpdateManyAuthz({
    onSuccess: successNotify(SUPER_ADMIN_UPDATE_MANY_MANAGER_AUTHZ_SUCCESS),
    onError: errorNotify(SUPER_ADMIN_UPDATE_MANY_MANAGER_AUTHZ_ERROR),
  });
  const authzs = useAuthzs({
    onError: errorNotify(SUPER_ADMIN_FETCH_MANY_MANAGER_AUTHZS_ERROR),
  });
  const [form] = useForm<FormValues>();
  const mode = useWatch(['authz', 'mode'], form);

  const handleGoBackClick = () => {
    navigate(-1, '/managers');
  };

  const handleSyncOnSearch = async (value: string) => {
    if (value.length < 1) {
      searchManagers.clear();
    } else {
      searchManagers.fetch(value);
    }
  };

  const handleMemberOnChange = async (members: ListItemState[]) => {
    authzEditorList.set(members);
    if (mode === ManagerEditMode.MANUAL && not(isEmpty(members))) {
      await authzs.fetch(members.map(({ id }) => id));
    } else {
      authzs.clear();
    }
  };

  const handleOnSubmit = async () => {
    form
      .validateFields()
      .then(FormDataSchema.parse)
      .then(async (data) => {
        if (data.mode === ManagerEditMode.MANUAL) {
          const addAuthzsMap = (data.addAuthzs ?? []).reduce<
            Record<string, number>
          >((acc, authz, index) => {
            const { orgId } = authz;
            acc[orgId] = index;
            return acc;
          }, {});
          const removeAuthzsMap = (data.removeAuthzs ?? []).reduce<
            Record<string, number>
          >((acc, authz, index) => {
            const { orgId } = authz;
            acc[orgId] = index;
            return acc;
          }, {});

          const addAuthzs = clone(data.addAuthzs ?? []);
          authzs.data.forEach((authz) => {
            const orgId = authz.org.id;
            const addAuthzIndex = addAuthzsMap[orgId];
            const addAuthz = addAuthzs[addAuthzsMap[orgId]];
            const removeAuthz = (data.removeAuthzs ?? [])[
              removeAuthzsMap[orgId]
            ];
            if (
              isNotNil(data.removeAuthzs) &&
              isNotNil(removeAuthz) &&
              isNil(removeAuthz.roleIds)
            )
              return;

            if (isNil(addAuthz) && isNil(removeAuthz)) {
              addAuthzs.push({
                orgId,
                roleIds: authz.roleIds,
              });
              return;
            }

            let newRoleIds = [...(authz.roleIds ?? [])];
            if (isNotNil(removeAuthz) && isNotNil(removeAuthz.roleIds)) {
              newRoleIds = difference(newRoleIds, removeAuthz.roleIds ?? []);
            }

            if (isNil(addAuthz)) {
              addAuthzs.push({
                orgId,
                roleIds: newRoleIds,
              });
            } else {
              addAuthzs[addAuthzIndex].roleIds = [
                ...new Set([...newRoleIds, ...(addAuthz.roleIds ?? [])]),
              ];
            }
          });

          data.addAuthzs = addAuthzs;
        }

        const result = await updateManyAuthz.submit(data);
        if (result) {
          authzEditorList.reset();
          handleGoBackClick();
        }
      });
  };

  useEffect(() => {
    if (mode === ManagerEditMode.MANUAL && not(isEmpty(authzEditorList.list))) {
      authzs.fetch(authzEditorList.list.map(({ id }) => id));
    } else {
      authzs.clear();
    }
  }, [mode]);

  return (
    <Spin spinning={authzs.loading}>
      <FluidSpace direction="vertical" size="large">
        <EditorHeader title="Authz Editor" onGoBack={handleGoBackClick} />
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            members: authzEditorList.list,
            authz: {
              mode: ManagerEditMode.MANUAL,
            },
          }}
          onValuesChange={(data) => {
            const { members } = data;
            if (isNotNil(members)) {
              handleMemberOnChange(members);
            }
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24} xl={12} xxl={8}>
              <FormItem
                label="Members"
                name="members"
                rules={[{ required: true }]}
              >
                <MemberSelect />
              </FormItem>
            </Col>
            <Col span={24} xxl={16}>
              <FormItem label="Mode" name={['authz', 'mode']}>
                <RadioGroup buttonStyle="solid">
                  <RadioButton value={ManagerEditMode.MANUAL}>
                    Manual
                  </RadioButton>
                  <RadioButton value={ManagerEditMode.SYNC}>Sync</RadioButton>
                </RadioGroup>
              </FormItem>
              <FormItem noStyle dependencies={[['authz', 'mode']]}>
                {(form) => {
                  const mode = form.getFieldValue(['authz', 'mode']);
                  return mode === ManagerEditMode.MANUAL ? (
                    <Fragment>
                      <FormItem
                        label="Authz"
                        name={['authz', 'items']}
                        rules={[{ required: true }]}
                      >
                        <AuthzSelect
                          isValueAdjustWithSource
                          source={authzs.data}
                        />
                      </FormItem>
                    </Fragment>
                  ) : (
                    <Row gutter={[24, 24]}>
                      <Col span={24} md={18} lg={16} xl={12} xxl={12}>
                        <FormItem
                          label="Sync"
                          name={['authz', 'syncId']}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            allowClear
                            loading={searchManagers.loading}
                            filterOption={false}
                            notFoundContent={null}
                            options={searchManagers.data.map(
                              ({ id, name, type }) => ({
                                value: id,
                                label: (
                                  <FluidSpace size="small">
                                    <Text>{name}</Text>
                                    {type === SearchResultType.BOILERPLATE && (
                                      <Text type="secondary">
                                        [Boilerplate]
                                      </Text>
                                    )}
                                  </FluidSpace>
                                ),
                              }),
                            )}
                            onSearch={handleSyncOnSearch}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  );
                }}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </FluidSpace>
      <AffixFormFooter>
        <FormItem noStyle>
          <Space>
            <Button type="primary" onClick={handleOnSubmit}>
              Submit
            </Button>
            <Button onClick={handleGoBackClick}>Cancel</Button>
          </Space>
        </FormItem>
      </AffixFormFooter>
    </Spin>
  );
};

export default ManagerAuthzEditor;
