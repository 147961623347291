import { GenericAbortSignal } from 'axios';
import { stringify } from 'qs';

import { Utils, ManagerClusters } from '@interfaces/apis';
import * as Schema from '@schemas/apis/organization-clusters.schema';
import { serializeResponse, serializeResponseWithMeta } from '@utils/api.util';
import { superAdminAxios } from './axios';

export const findMany_SuperAdmin = async (
  query: Utils.RequiredPagination<ManagerClusters.FindManyQuery_SuperAdmin>,
) =>
  superAdminAxios
    .get('organization-clusters', {
      params: query,
      paramsSerializer: (params) => stringify(params),
    })
    .then(serializeResponseWithMeta(Schema.FindManyRespSchema_SuperAdmin));

export const createOne_SuperAdmin = async (
  data: ManagerClusters.CreateOneBody_SuperAdmin,
) =>
  superAdminAxios
    .post('organization-clusters', data)
    .then(serializeResponse(Schema.CreateOneRespSchema_SuperAdmin));

export const updateOneProfile_SuperAdmin = async (
  id: string,
  data: ManagerClusters.UpdateOneProfileBody_SuperAdmin,
) => {
  await superAdminAxios.put(`organization-clusters/${id}/profile`, data);
};

export const updateOneMembers_SuperAdmin = async (
  id: string,
  data: ManagerClusters.UpdateOneMembersBody_SuperAdmin,
) => {
  await superAdminAxios.patch(`organization-clusters/${id}/members`, data);
};

export const removeOne_SuperAdmin = async (id: string) => {
  await superAdminAxios.delete(`organization-clusters/${id}`);
};

export const findOne_SuperAdmin = async (
  id: string,
  canceledSignal?: GenericAbortSignal,
) =>
  superAdminAxios
    .get(`organization-clusters/${id}`, { signal: canceledSignal })
    .then(serializeResponse(Schema.FindOneRespSchema_SuperAdmin));

export const findOneMembersRoles_SuperAdmin = async (id: string) =>
  superAdminAxios
    .get(`organization-clusters/${id}/members/roles`)
    .then(serializeResponse(Schema.FindOneMembersRolesRespSchema_SuperAdmin));
