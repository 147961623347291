import { useState } from 'react';
import { omit } from 'ramda';

import { Organizations, Utils } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import useLoading from '@hooks/useLoading';
import { getOrganizations } from '@apis/organizations';

interface UseOrgsOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const defaultMeta = {
  page: 1,
  size: 10,
  total: 0,
};

const useOrgs = (options: UseOrgsOptions = {}) => {
  const { onError, throwable } = options;
  const loading = useLoading();
  const [data, setData] = useState<Organizations.Organization[]>();
  const [pagination, setPagination] = useState<Utils.WeMoMeta>({
    ...defaultMeta,
  });
  const [query, setQuery] = useState<
    Utils.OptionalPagination<Organizations.FindManyOrgAcctRoleByOrg>
  >({});

  const fetch = async (
    query: Utils.OptionalPagination<Organizations.FindManyOrgAcctRoleByOrg> = omit(
      ['total'],
      defaultMeta,
    ),
  ) => {
    await asyncLoadingWrapper(
      loading,
      async () => {
        const [data, meta] = await getOrganizations(query);
        setData(data);
        setPagination(meta);
        setQuery(omit(['page', 'size'], query));
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const changePage = async (page: number, size: number) => {
    await fetch({ ...query, page, size });
  };

  const reload = async () => {
    await fetch({ ...query, ...omit(['total'], pagination) });
  };

  const reset = async () => {
    await fetch({ ...omit(['total'], defaultMeta) });
  };

  return {
    loading: loading.loading,
    fetch,
    changePage,
    reload,
    reset,
    data,
    query,
    pagination,
  };
};

export default useOrgs;
