import { useTranslation } from 'react-i18next';
import { Typography, Form, Input, Row, Col, Button, Spin } from 'antd';
import {
  LockOutlined,
  UserOutlined,
  LoginOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';

import { TEMPLATES_LOGIN } from '@i18n/namespaces';
import useUrlQuery from '@hooks/useUrlQuery';
import CenterLayout from '@layouts/CenterLayout';
import FluidSpace from '@components/FluidSpace';
import LocaleButton from '@components/LocaleButton';
import { querySchema } from './schemas';
import {
  Container,
  Section,
  HeadSpace,
  NexusTitle,
  NexusSubTitle,
  RtlSpace,
  ForgetPasswordLink,
} from './styleds';
import { LoginProps, FormValues } from './interfaces';

const { Text } = Typography;
const { Item: FormItem, useForm } = Form;
const { Password } = Input;

const Login = (props: LoginProps) => {
  const {
    loading,
    role,
    color = 'secondary',
    forgetPasswordLink,
    onSubmit,
  } = props;
  const { t } = useTranslation(TEMPLATES_LOGIN);
  const urlQuery = useUrlQuery(querySchema);
  const [form] = useForm<FormValues>();

  const handleOnSubmit = async (data: FormValues) => {
    typeof onSubmit === 'function' && onSubmit(data, urlQuery.get().redirectTo);
  };

  return (
    <CenterLayout>
      <Spin spinning={loading}>
        <Container direction="vertical">
          <Section>
            <Row justify="center">
              <Col>
                <HeadSpace direction="vertical">
                  <NexusTitle>WeMo Nexus</NexusTitle>
                  <RtlSpace align="baseline" size={5}>
                    <Text type="secondary">for</Text>
                    <NexusSubTitle level={4} type={color}>
                      {role}
                    </NexusSubTitle>
                  </RtlSpace>
                </HeadSpace>
              </Col>
            </Row>
            <Form form={form} onFinish={handleOnSubmit}>
              <FormItem
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('components.templates.login:required-username'),
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder={t('components.templates.login:username')}
                />
              </FormItem>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('components.templates.login:required-password'),
                  },
                ]}
              >
                <Password
                  size="large"
                  prefix={<LockOutlined />}
                  placeholder={t('components.templates.login:password')}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </FormItem>

              <FormItem noStyle>
                <FluidSpace direction="vertical" size={12}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    icon={<LoginOutlined />}
                  >
                    {t('components.templates.login:login')}
                  </Button>
                  <ForgetPasswordLink href={forgetPasswordLink}>
                    {t('components.templates.login:forget-password')} ?
                  </ForgetPasswordLink>
                </FluidSpace>
              </FormItem>
            </Form>
          </Section>
        </Container>
      </Spin>
      <LocaleButton float />
    </CenterLayout>
  );
};

export default Login;
