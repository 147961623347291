import {
  useLocation,
  useNavigate,
  To,
  RelativeRoutingType,
  Location,
} from 'react-router-dom';
import { isNotNil } from 'ramda';

import { isNotEmpty } from '@utils/ramda.util';
import { useAuthStore } from '@stores';
import useParamsOrgId from '@hooks/useParamsOrgId';

interface NavigateOptions<
  State extends Record<string, any> = Record<string, any>,
> {
  replace?: boolean;
  state?: State;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}

export interface NavigateFunction<
  State extends Record<string, any> = Record<string, any>,
> {
  (to: To, options?: NavigateOptions<State>): void;
  (delta: number, matchPrevPath?: string): void;
}

interface LocationState {
  prev?: string[];
}

const useEnhancedNavigate = () => {
  const location: Location<LocationState> = useLocation();
  const navigate = useNavigate();
  const orgId = useParamsOrgId();
  const fallback = useAuthStore((state) => state.fallback);

  const enhancedNavigate: NavigateFunction = (...args) => {
    if (typeof args[0] !== 'number') {
      const [to, options = {} as NavigateOptions] = args as [
        To,
        NavigateOptions | undefined,
      ];
      const { state, replace, ...otherOptions } = options;
      const { pathname, search } = location;
      const prev = (location.state || {}).prev || [];
      const nextState = replace
        ? state
        : { ...state, prev: [`${pathname}${search}`, ...prev] };
      navigate(to, {
        ...otherOptions,
        replace,
        state: nextState,
      });
    } else {
      const [delta, matchPrevPath] = args as [number, string | undefined];
      const state = location.state || {};
      const { prev = [] } = state;
      if (delta === -1) {
        if (isNotNil(prev) && isNotEmpty(prev)) {
          const [to, ...rest] = prev;
          if (isNotNil(matchPrevPath)) {
            const [path] = to.split('?');
            if (!new RegExp(`${matchPrevPath.replace('..', '')}$`).test(path)) {
              navigate(matchPrevPath, { state: { ...state, prev: rest } });
            } else {
              navigate(to, { state: { ...state, prev: rest } });
            }
          } else {
            navigate(to, { state: { ...state, prev: rest } });
          }
        } else if (isNotNil(document.referrer) && isNotEmpty(document.referrer))
          if (window.history.length === 1)
            window.location.href = document.referrer;
          else navigate(-1);
        else if (isNotNil(fallback)) navigate(`/org/_${orgId}/${fallback}`);
        else navigate(-1);
      } else {
        navigate(delta);
      }
    }
  };

  return enhancedNavigate;
};

export default useEnhancedNavigate;
