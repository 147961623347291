import { ReactNode } from 'react';
import { create } from 'zustand';

export interface MenuState {
  isInit: boolean;
  label: ReactNode;
  selectedKeys: string[];
  openKeys: string[];
}

export interface MenuAction {
  init(state: Partial<Omit<MenuState, 'isInit'>>): void;
  set(state: Partial<Omit<MenuState, 'isInit'>>): void;
  reset(): void;
}

const DEFAULT_STATE = {
  label: '',
  selectedKeys: [],
  openKeys: [],
};

export const useMenuStore = create<MenuState & MenuAction>((set, get) => ({
  isInit: false,
  ...DEFAULT_STATE,
  init(state) {
    if (get().isInit) return;
    set({ ...state, isInit: true });
  },
  set(state) {
    set((prev) => ({ ...prev, ...state }));
  },
  reset() {
    set((prev) => ({ ...prev, ...DEFAULT_STATE }));
  },
}));
