import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { COMPONENTS_FORBIDDEN } from '@i18n/namespaces';
import { ForbiddenProps } from './interfaces';

const Forbidden = (props: ForbiddenProps = {}) => {
  const { t } = useTranslation(COMPONENTS_FORBIDDEN);
  const {
    goBackText = t(
      'components.forbidden:back-to-home-page',
      'Back to Home Page',
    ),
    onGoBack,
  } = props;

  const handleRedirectClick = () => {
    onGoBack?.();
  };

  return (
    <Result
      status="403"
      title="403"
      subTitle={t(
        'components.forbidden:sub-title',
        'Sorry, you are not authorized to access this page.',
      )}
      extra={
        <Button type="primary" onClick={handleRedirectClick}>
          {goBackText}
        </Button>
      }
    />
  );
};

export default Forbidden;
export type { ForbiddenProps };
