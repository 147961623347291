import { isNil } from 'ramda';

import { Utils, GlobalAccountRoles } from '@interfaces/apis';
import { formatResponse, formatResponseWithMeta } from '@utils/api.util';
import { managerAxios, superAdminAxios } from './axios';

export const getAllGlobalAccountRole = async () =>
  superAdminAxios
    .get<
      Utils.WeMoReplyWithMeta<
        Omit<
          GlobalAccountRoles.GlobalAccountRole,
          'permissions' | 'organizations'
        >
      >
    >('global-account-roles/false')
    .then(
      formatResponseWithMeta<
        Omit<GlobalAccountRoles.GlobalAccountRole, 'permissions'>
      >,
    );

export const getManyGlobalAccountRole = async <
  Params extends GlobalAccountRoles.FindManyGlobalAcctRoleParams | undefined,
>(
  query?: GlobalAccountRoles.FindManyGlobalAcctRoleQuery,
  params?: Params,
) =>
  superAdminAxios
    .get<
      Utils.WeMoReplyWithMeta<
        GlobalAccountRoles.FindGlobalAcctRoleReply<Params>
      >
    >(`global-account-roles${isNil(params) ? '' : `/${params}`}`, {
      params: query,
    })
    .then(formatResponseWithMeta);

export const postOneGlobalAccountRole = async (
  data: GlobalAccountRoles.CreateOneGlobalAcctRole,
) =>
  superAdminAxios
    .post<Utils.WeMoReply<GlobalAccountRoles.GlobalAccountRole['id']>>(
      'global-account-roles',
      data,
    )
    .then(formatResponse);

export const patchOneGlobalAccountRole = async (
  globalAcctRoleId: string,
  data: GlobalAccountRoles.UpdateOneGlobalAcctRole,
) => superAdminAxios.patch(`global-account-roles/${globalAcctRoleId}`, data);

export const getOneGlobalAcctRole = async <
  Resource extends GlobalAccountRoles.FindManyGlobalAcctRoleParams | undefined,
>(
  globalAcctRoleId: string,
  {
    orgNotMatchVersion,
    resource,
  }: {
    orgNotMatchVersion?: true;
    resource?: Resource;
  },
) =>
  superAdminAxios
    .get<Utils.WeMoReply<GlobalAccountRoles.FindGlobalAcctRoleReply<Resource>>>(
      `global-account-roles/${globalAcctRoleId}${
        isNil(resource) ? '' : `/${resource}`
      }`,
      { params: { orgNotMatchVersion } },
    )
    .then(formatResponse);

export const pushManyOrgGlobalAcctRole = async (
  globalAcctRoleId: string,
  orgAcctRoleIds: string[],
) =>
  superAdminAxios.patch(
    `global-account-roles/${globalAcctRoleId}/organizations`,
    { orgAcctRoleIds },
  );

export const upgradeOneOrgGlobalAcctRoleByOrg = async (
  orgId: string,
  orgAcctRoleId: string,
  globalAcctRoleId: string,
) =>
  managerAxios.patch(
    `global-account-roles/${globalAcctRoleId}/organizations/${orgId}`,
    { orgAcctRoleId },
  );
