import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Space } from 'antd';
import { isNil } from 'ramda';

export const FluidSpace = styled(Space).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['verticalAlign'].includes(prop),
})<{
  verticalAlign?: CSSProperties['verticalAlign'];
}>`
  width: 100%;
  /* https://stackoverflow.com/a/45359783/13164657 */
  ${({ verticalAlign }) =>
    isNil(verticalAlign)
      ? ''
      : css`
          vertical-align: ${verticalAlign};
        `}
`;
