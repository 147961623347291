import { create } from 'zustand';
import { isNil } from 'ramda';
import dayjs from 'dayjs';

import * as APIs from '@interfaces/apis';
import * as Store from '@interfaces/stores';
import {
  getManagerMeOrgs,
  getManyManagerMeOrgPermission,
  getManagerMeInfo,
} from '@apis/managers';

export interface ManagerMeState {
  meInfo?: APIs.Managers.MeManager;
  meOrgs: APIs.Managers.MeOrganization[];
  mePerms: APIs.Managers.MeOrgPermission[];
  cache: number;
  exp?: number;
}

export interface ManagerMeAction {
  init: (
    query?: APIs.Managers.FindManyMeOrgPermissionQuery & {
      orgId?: APIs.Managers.MeOrganization['id'];
    },
  ) => Promise<
    [
      APIs.Managers.MeManager | undefined,
      APIs.Managers.MeOrganization[],
      APIs.Managers.MeOrgPermission[],
    ]
  >;
  fetchMeOrgs: () => Promise<APIs.Managers.MeOrganization[]>;
  fetchMePerms: (
    orgId: APIs.Managers.MeOrganization['id'],
    query?: APIs.Managers.FindManyMeOrgPermissionQuery,
  ) => Promise<APIs.Managers.MeOrgPermission[]>;
  fetchMe: () => Promise<APIs.Managers.MeManager>;
  getMeOrgSelectOptions: () => Store.Managers.MeOrgSelectOption[];
  getMeDefaultOrg: () => APIs.Managers.MeOrganization | undefined;
  getMeOrgAuthority: () => APIs.Managers.MeOrgPermission['name'][];
  clear: () => void;
}

export const useManagerMeStore = create<ManagerMeState & ManagerMeAction>(
  (set, get) => ({
    meInfo: undefined,
    meOrgs: [],
    mePerms: [],
    cache: 300,
    exp: undefined,
    async init(query) {
      const { exp, cache } = get();
      if (!isNil(exp) && exp > dayjs().valueOf())
        return [get().meInfo, get().meOrgs, get().mePerms];

      const meInfo = await get().fetchMe();
      const meOrgs = await get().fetchMeOrgs();
      const meDefaultOrg = get().getMeDefaultOrg();
      if (isNil(meDefaultOrg)) {
        set({ mePerms: [] });
        return [undefined, [], []];
      }

      const mePerms = await get().fetchMePerms(
        query?.orgId || meDefaultOrg.id,
        query,
      );
      set({ exp: dayjs().valueOf() + cache });
      return [meInfo, meOrgs, mePerms];
    },
    async fetchMeOrgs() {
      const meOrgs = await getManagerMeOrgs();
      set({ meOrgs: meOrgs });
      return meOrgs;
    },
    async fetchMePerms(orgId, query) {
      const mePerms = await getManyManagerMeOrgPermission(orgId, query);
      set({ mePerms: mePerms });
      return mePerms;
    },
    async fetchMe() {
      const manager = await getManagerMeInfo();
      set({ meInfo: manager });
      return manager;
    },
    getMeOrgSelectOptions() {
      return get().meOrgs.map((meOrg) => ({
        value: meOrg.id,
        label: meOrg.name,
        isDefault: meOrg.isDefault,
      }));
    },
    getMeDefaultOrg() {
      const meDefaultOrg = get().meOrgs.find((meOrg) => meOrg.isDefault);
      return meDefaultOrg || get().meOrgs[0];
    },
    getMeOrgAuthority() {
      return get().mePerms.map((mePerm) => mePerm.name);
    },
    clear() {
      set({ meInfo: undefined, meOrgs: [], mePerms: [], exp: undefined });
    },
  }),
);
