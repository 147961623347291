import styled from 'styled-components';
import { Space, Typography } from 'antd';

const { Title, Link } = Typography;

export const Container = styled(Space)`
  width: 100%;
  justify-content: center;
`;

export const Section = styled.div`
  width: 320px;
  padding: 12px;
`;

export const HeadSpace = styled(Space)`
  margin-bottom: 12px;
`;

export const NexusTitle = styled(Title)`
  margin-bottom: 0 !important;
`;

export const RtlSpace = styled(Space)`
  width: 100%;
  justify-content: end;
`;

export const NexusSubTitle = styled(Title)`
  line-height: 1 !important;
`;

export const ForgetPasswordLink = styled(Link)`
  user-select: none;
`;
