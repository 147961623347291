import z from 'zod';

import {
  SearchScope,
  SearchResultType,
  ManagerType,
  ManagerStatus,
  ManagerEditMode,
} from '@enums/managers.enum';

export const SearchManyQuerySchema_SuperAdmin = z.object({
  keyword: z.string().min(1),
  scope: z.nativeEnum(SearchScope).optional().default(SearchScope.ALL),
  ignoreBp: z.boolean().optional(),
  exclude: z
    .object({
      mgr: z.string().array().optional(),
      clstr: z.string().array().optional(),
    })
    .optional(),
});

export const SearchManyRespSchema_SuperAdmin = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().nullable(),
  type: z.nativeEnum(SearchResultType),
});

const CreateOneAuthzSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal(ManagerEditMode.MANUAL),
    items: z
      .object({
        orgId: z.string(),
        roleIds: z.string().array().nullable().describe('Organization Role ID'),
      })
      .array()
      .nullable(),
  }),
  z.object({
    mode: z.literal(ManagerEditMode.SYNC),
    syncId: z.string(),
  }),
]);

export const CreateOneBoilerplateSchema = z.object({
  type: z.literal(ManagerType.BOILERPLATE),
  name: z.string(),
  authz: CreateOneAuthzSchema.nullish(),
});

export const CreateOneRealitySchema = z.object({
  type: z.literal(ManagerType.REALITY),
  email: z.string().email(),
  name: z.string(),
  phone: z.string().nullish(),
  clusterIds: z.string().array().nullish(),
  authz: CreateOneAuthzSchema.nullish(),
});

export const CreateOneBodySchema_SuperAdmin = z.discriminatedUnion('type', [
  CreateOneBoilerplateSchema,
  CreateOneRealitySchema,
]);

export const CreateOneRespSchema_SuperAdmin = z.coerce
  .number()
  .transform((value) => value.toString());

const UpdateOneAuthzSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal(ManagerEditMode.MANUAL),
    items: z
      .object({
        add: z
          .object({
            orgId: z.string(),
            roleIds: z
              .string()
              .array()
              .nullable()
              .describe('Organization Role ID'),
          })
          .array()
          .nullable(),
        remove: z
          .object({
            orgId: z.string(),
            roleIds: z
              .string()
              .array()
              .nullable()
              .describe('Organization Role ID'),
          })
          .array()
          .nullable(),
      })
      .nullable(),
  }),
  z.object({
    mode: z.literal(ManagerEditMode.SYNC),
    syncId: z.string(),
  }),
]);

export const UpdateOneBoilerplateSchema = z.object({
  type: z.literal(ManagerType.BOILERPLATE),
  name: z.string().optional(),
  authz: UpdateOneAuthzSchema.nullish(),
});

export const UpdateOneRealitySchema = z.object({
  type: z.literal(ManagerType.REALITY),
  email: z.string().optional(),
  name: z.string().optional(),
  phone: z.string().nullish(),
  authz: UpdateOneAuthzSchema.nullish(),
});

export const UpdateOneBodySchema_SuperAdmin = z.discriminatedUnion('type', [
  UpdateOneBoilerplateSchema,
  UpdateOneRealitySchema,
]);

export const FindOneProfileRespSchema_SuperAdmin = z.object({
  id: z.string(),
  email: z.string(),
  name: z.string(),
  phone: z.string().nullable(),
  status: z.nativeEnum(ManagerStatus),
  type: z.nativeEnum(ManagerType),
});

export const FindOneAuthzsRespSchema_SuperAdmin = z.object({
  org: z.object({
    id: z.string(),
    name: z.string(),
  }),
  roles: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .array(),
});

export const UpdateOneStatusBodySchema_SuperAdmin = z.object({
  activated: z.boolean(),
});

export const UpdateManyAuthzBodySchema_SuperAdmin = z.object({
  ids: z.string().array(),
  clusterIds: z.string().array(),
  addAuthzs: z
    .object({
      orgId: z.string(),
      roleIds: z.string().array().nullable().describe('Organization Role ID'),
    })
    .array()
    .nullable(),
  removeAuthzs: z
    .object({
      orgId: z.string(),
      roleIds: z.string().array().nullable().describe('Organization Role ID'),
    })
    .array()
    .nullable(),
});

export const SyncManyAuthzBodySchema_SuperAdmin = z.object({
  ids: z.string().array(),
  clusterIds: z.string().array(),
  syncId: z.string(),
});

export const FindManyAuthzQuerySchema_SuperAdmin = z.object({
  ids: z.string().array(),
});

export const FindManyAuthzRespSchema_SuperAdmin = z.object({
  id: z.string(),
  authzs: z
    .object({
      org: z.object({
        id: z.string(),
        name: z.string(),
      }),
      roles: z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .array(),
    })
    .array(),
});
