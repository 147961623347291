import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useParamsOrgId = () => {
  const { orgId: paramsOrgId } = useParams<{ orgId: string }>();
  const orgId = useMemo(() => {
    const orgId = paramsOrgId?.replace('_', '');
    if (/\d+/.test(orgId || '')) return orgId;
    return undefined;
  }, [paramsOrgId]);

  return orgId;
};

export default useParamsOrgId;
