import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Managers, Utils } from '@interfaces/apis';
import { asyncLoadingWrapper } from '@utils/api.util';
import { getManagers } from '@apis/managers';

type WithValue<T> = T & {
  value: string;
};

interface UseManagerOptions {
  onError?: (error: unknown) => void;
  throwable?: true;
}

const defaultMeta = {
  page: 1,
  size: 10,
  total: 0,
};

const useManagers = (props: UseManagerOptions = {}) => {
  const { onError, throwable } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<WithValue<Managers.MeManager>[]>([]);
  const pagination = useRef<Utils.WeMoMeta>({ ...defaultMeta });

  const searchDebounced = useDebouncedCallback(
    async (
      query?: Partial<Utils.RequiredPagination<Managers.FindManyManagerQuery>>,
    ) => {
      const page = query?.page || pagination.current.page;
      const size = query?.size || pagination.current.size;
      const [data, meta] = await getManagers({
        ...query,
        orderBy: [{ column: 'managers.name' }],
        page,
        size,
      });
      const withValueData = data.map((manager) => ({
        ...manager,
        value: manager.id,
      }));
      setData(withValueData);
      pagination.current = meta;
      return withValueData;
    },
    500,
    { maxWait: 1000 },
  );

  const fetch = async (
    query?: Partial<Utils.RequiredPagination<Managers.FindManyManagerQuery>>,
  ) => {
    await asyncLoadingWrapper(
      setLoading,
      async () => {
        return searchDebounced(query);
      },
      (error) => {
        onError?.(error);
      },
      throwable,
    );
  };

  const clear = () => {
    setData([]);
    pagination.current = { ...defaultMeta };
  };

  useEffect(
    () => () => {
      searchDebounced.flush();
    },
    [searchDebounced],
  );

  return { loading, fetch, clear, data };
};

export default useManagers;
